import React from "react";
import DashboardHeader from "../../components/Host/Layout/DashboardHeader";
import DashboardSideBar from "../../components/Host/Layout/DashboardSideBar";
import Dashboard from "../../components/Host/Dashboard";
// import AllCalendars from "../../components/Host/AllCalendars";
// import { getAllPlacesHost } from "../../redux/actions/place";

const HostDashboardPage = () => {
 
  return (
    <div>
      <DashboardHeader />
      <div className="flex items-start justify-between w-full">
      <div className="w-[80px] 800px:w-[100px]">
          <DashboardSideBar active={1} />
        </div>
        <div className="w-full justify-center flex">
          <Dashboard />
        </div>
        {/* <DashboardHero /> */}
      </div>
    </div>
  );
};

export default HostDashboardPage;
