import React from "react";
import SidebarChat from "./SidebarChat";
import "../../../styles/sidebar.css"

const Sidebar = ({ teams, selectedTeam, setSelectedTeam }) => {
  console.log("Selected Team: ",selectedTeam)
  const handleSidebarChatClick = (teamId) => {
    const selectedTeamClick = teams.find((team) => team._id === teamId);
    if (selectedTeamClick) {
      setSelectedTeam(selectedTeamClick);
    }
  };

  return (
    <div className="sidebar">
      <div className="sidebar__header ">
        {"Service Teams"}
      </div>
      {teams &&
        teams.map(({ _id, name, avatar }) => (
          <SidebarChat
            key={_id}
            id={_id}
            name={name}
            avatar={avatar}
            onClick={() => handleSidebarChatClick(_id)}
            isSelected={selectedTeam && selectedTeam._id === _id}
          />
        ))}
    </div>
  );
};

export default Sidebar;