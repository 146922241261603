import React from "react";
import { useParams } from "react-router-dom";
import DashboardHeader from "../../components/Host/Layout/DashboardHeader";
import DashboardSideBar from "../../components/Host/Layout/DashboardSideBar";
import ServiceDetails from "../../components/Host/ServiceDetails";

const HostServiceDetailsPage = () => {
  const { id } = useParams();

  return (
    <div>
      <DashboardHeader />
      <div className="flex justify-between w-full">
      <div className="w-[80px] 800px:w-[100px]">
          <DashboardSideBar active={1} />
        </div>
        <div className="flex w-full justify-center">
          <ServiceDetails id={id} />
        </div>
      </div>
    </div>
  );
};

export default HostServiceDetailsPage;
