import axios from "axios";
import { server } from "../../server";

// create ical
export const createIcal =
  ({ calendarUrl, platform, placeId, hostId }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "icalCreateRequest",
      });
      const { data } = await axios.post(
        `${server}/booking/upload-calendar/${placeId}`,
        { calendarUrl, platform, placeId, hostId }
      );
      dispatch({
        type: "icalCreateSuccess",
        payload: data.bookingsInserted,
      });
    } catch (error) {
      dispatch({
        type: "icalCreateFail",
        payload: error.response.data.message,
      });
    }
  };

// Create Manual Booking
export const createManualBooking = (newForm) => async (dispatch) => {
  try {
    dispatch({
      type: "createManualBookingRequest",
    });

    const config = { headers: { "Content-Type": "multipart/form-data" } };

    const { data } = await axios.post(
      `${server}/booking/create-manual-booking`,
      newForm,
      config
    );
    dispatch({
      type: "createManualBookingSuccess",
      payload: data.manualBooking,
    });
  } catch (error) {
    dispatch({
      type: "createManualBookingFail",
      payload: error.response.data.message,
    });
  }
};

// get all bookings of a place [of a host]
export const getAllBookingsPlace = (placeId) => async (dispatch) => {
  try {
    dispatch({
      type: "getAllBookingsPlaceRequest",
    });
    const { data } = await axios.get(
      `${server}/booking/get-all-bookings-place/${placeId}`
    );
    dispatch({
      type: "getAllBookingsPlaceSuccess",
      payload: data.bookingsPlace,
    });
  } catch (error) {
    dispatch({
      type: "getAllBookingsPlaceFailed",
      payload: error.response.data.message,
    });
  } finally {
    dispatch(clearBookingsPlace());
  }
};

// delete ical of a host
export const deleteIcal = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "deleteIcalRequest",
    });

    const { data } = await axios.delete(
      `${server}/booking/delete-host-ical/${id}`,
      {
        withCredentials: true,
      }
    );

    dispatch({
      type: "deleteIcalSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "deleteIcalFailed",
      payload: error.response.data.message,
    });
  }
};

// get all Bookings of a Host
export const getAllBookingsHost = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "getAllBookingsHostRequest",
    });

    const { data } = await axios.get(
      `${server}/booking/get-all-bookings-host/${id}`
    );
    dispatch({
      type: "getAllBookingsHostSuccess",
      payload: data.bookingsHost,
    });
  } catch (error) {
    dispatch({
      type: "getAllBookingsHostFailed",
      payload: error.response.data.message,
    });
  }
};

export const getBookingHost = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "getBookingHostRequest",
    });
    console.log("Action ID: ", id);
    const { data } = await axios.get(
      `${server}/booking/get-booking-host/${id}`
    );
    dispatch({
      type: "getBookingHostSuccess",
      payload: data.bookingHost,
    });
  } catch (error) {
    dispatch({
      type: "getBookingHostFailed",
      payload: error.response.data.message,
    });
  }
};

export const clearBookingsPlace = () => ({
  type: "getAllBookingsPlace",
});
