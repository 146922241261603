import { Button } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import React, { useEffect } from "react";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { deleteEpisode, getAllEpisodesHost } from "../../redux/actions/episode";
import { DateTime } from "luxon";
import Loader from "../Layout/Loader";

const AllEpisodes = () => {
  const { episodesHost, isLoading } = useSelector((state) => state.episodes);
  const { host } = useSelector((state) => state.host);

  const dispatch = useDispatch();
  const hostId = host._id;
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getAllEpisodesHost(hostId));
      } catch (error) {
        console.log(error);
      }
    };
      fetchData();
  }, [dispatch, hostId]);

  const handleDelete = (id) => {
    dispatch(deleteEpisode(id));
    window.location.reload();
  };

  const columns = [
    //  { field: "id", headerName: "Id Episode", minWidth: 80, flex: 0.2 },
    {
      field: "name",
      headerName: "Place Name",
      minWidth: 70,
      flex: 0.25,
    },
    {
      field: "status",
      headerName: "Status",
      type: "string",
      minWidth: 50,
      flex: 0.25,
    },
    {
      field: "startDate",
      headerName: "Start Date Time",
      type: "date",
      minWidth: 80,
      flex: 0.4,
    },
    {
      field: "endDate",
      headerName: "End Date Time",
      type: "string",
      minWidth: 80,
      flex: 0.4,
    },
    {
      field: "Preview",
      flex: 0.2,
      minWidth: 40,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/dashboard-episode/${params.id}`}>
              <Button>
                <AiOutlineEye size={20} />
              </Button>
            </Link>
          </>
        );
      },
    },
    {
      field: "Delete",
      flex: 0.2,
      minWidth: 40,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Button onClick={() => handleDelete(params.id)}>
              <AiOutlineDelete size={20} />
            </Button>
          </>
        );
      },
    },
  ];

  const row = [];
  if (episodesHost && episodesHost.length > 0) {
    console.log("Episode Host Component: ",episodesHost);
    episodesHost.forEach((item) => {
      const formattedStartDate = DateTime.fromISO(item.startDate).toFormat(
        "MMM-dd-yyyy hh:mm a"
      );
      const formattedEndDate = DateTime.fromISO(item.endDate).toFormat(
        "LLL-dd-yyyy hh:mm a"
      );
      row.push({
        id: item._id,
        name: item.place.name,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        status: item.status,
      });
    });
  };
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="w-full mx-8 pt-1 mt-10 mr-10 bg-white">
          <DataGrid
            rows={row}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
          />
        </div>
      )}
    </>
  );
};

export default AllEpisodes;
