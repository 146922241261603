import React from "react";
import styles from "../../styles/styles";
import HostInfo from "../../components/Host/HostInfo";
import HostProfileData from "../../components/Host/HostProfileData";

const HostHomePage = () => {
  return (
    <div className={`${styles.section} bg-[#f5f5f5]`}>
      <div className="w-full flex py-10 justify-between">
      <div className="px-4 w-[25%] bg-[#fff] rounded-[4px] shadow-sm overflow-y-scroll h-[90vh] sticky top-10 left-0 z-10">
          <h3 className="text-center py-2 text-[20px]">Profile</h3>
          <div>
            <HostInfo />
          </div>
        </div>
        <div className="w-[72%] rounded-[4px]">
          <HostProfileData />
        </div>
      </div>
    </div>
  );
};

export default HostHomePage;
