import React, { useState } from "react";
import Header from "../components/Layout/Header";
import styles from "../styles/styles";
import Footer from "../components/Layout/Footer";
import { useTranslation } from "react-i18next";

const FAQPage = () => {
  return (
    <div>
      <Header activeHeading={5} />
      <Faq />
      {/* Full static component */}
      <Footer />
    </div>
  );
};

const Faq = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { t } = useTranslation();
  const toggleTab = (tab) => {
    if (activeTab === tab) {
      setActiveTab(0);
    } else {
      setActiveTab(tab);
    }
  };

  return (
    <div className={`${styles.section} my-8`}>
      <h2 className="text-3xl font-bold text-gray-900 mb-8">{t("FAQ-0")}</h2>
      <div className="mx-auto space-y-4">
        {/* single Faq */}

        {/* FAQ-0 */}
        <div className="border-b border-gray-200 pb-4">
          <button
            className="flex items-center justify-between w-full"
            onClick={() => toggleTab(2)}
          >
            <span className="text-lg font-medium text-gray-900">
              {t("FAQ-Q-0")}
            </span>
            {activeTab === 2 ? (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            )}
          </button>
          {activeTab === 2 && (
            <div className="mt-4">
              <p className="text-base text-gray-500">{t("FAQ-R-0")}</p>
            </div>
          )}
        </div>

        {/* FAQ-1 */}
        <div className="border-b border-gray-200 pb-4">
          <button
            className="flex items-center justify-between w-full"
            onClick={() => toggleTab(3)}
          >
            <span className="text-lg font-medium text-gray-900">
              {t("FAQ-Q-1")}
            </span>
            {activeTab === 3 ? (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            )}
          </button>
          {activeTab === 3 && (
            <div className="mt-4">
              <p className="text-base text-gray-500">{t("FAQ-R-1A")}</p>
              <div className="flex justify-center items-center h-screen">
                <div className="relative overflow-hidden w-full max-w-[800px]">
                  <div className="relative overflow-hidden w-full pt-[70%]">
                    <iframe
                      className="absolute top-0 left-0 w-full h-full border-0"
                      src="https://www.youtube.com/embed/7R0ylgWRmrg?rel=0&modestbranding=1&controls=1&showinfo=0&iv_load_policy=3"
                      title="YouTube video player"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                    <div className="absolute top-0 left-0 w-full h-full pointer-events-none">
                      <div className="absolute bottom-0 right-0 w-[120px] h-[40px] bg-transparent pointer-events-auto"></div>
                      <div className="absolute top-0 left-0 w-full h-[60px] bg-transparent pointer-events-auto"></div>
                      <div className="absolute top-0 left-0 w-[100px] h-[60px] bg-transparent pointer-events-auto"></div>
                      <div className="absolute bottom-0 right-0 w-[50px] h-[50px] bg-transparent pointer-events-auto"></div>
                      <div className="absolute bottom-0 left-0 w-[200px] h-[40px] bg-transparent pointer-events-auto"></div>
                    </div>
                  </div>
                </div>
              </div>
              <p className="mt-4 text-base text-gray-500">{t("FAQ-R-1B")}</p>
              <div className="flex justify-center items-center h-screen">
                <div className="relative overflow-hidden w-full max-w-[800px]">
                  <div className="relative overflow-hidden w-full pt-[70%]">
                    <iframe
                      className="absolute top-0 left-0 w-full h-full border-0"
                      src="https://www.youtube.com/embed/YyWSQG12CKE?rel=0&modestbranding=0&controls=1&showinfo=0&iv_load_policy=3"
                      title="YouTube video player"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                    <div className="absolute top-0 left-0 w-full h-full pointer-events-none">
                      <div className="absolute bottom-0 right-0 w-[120px] h-[40px] bg-transparent pointer-events-auto"></div>
                      <div className="absolute top-0 left-0 w-full h-[60px] bg-transparent pointer-events-auto"></div>
                      <div className="absolute top-0 left-0 w-[100px] h-[60px] bg-transparent pointer-events-auto"></div>
                      <div className="absolute bottom-0 right-0 w-[50px] h-[50px] bg-transparent pointer-events-auto"></div>
                      <div className="absolute bottom-0 left-0 w-[200px] h-[40px] bg-transparent pointer-events-auto"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* FAQ-2 */}
        <div className="border-b border-gray-200 pb-4">
          <button
            className="flex items-center justify-between w-full"
            onClick={() => toggleTab(4)}
          >
            <span className="text-lg font-medium text-gray-900">
              {t("FAQ-Q-2")}
            </span>
            {activeTab === 4 ? (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            )}
          </button>
          {activeTab === 4 && (
            <div className="mt-4">
              <p className="text-base text-gray-500">{t("FAQ-R-2")}</p>
              <div className="flex justify-center items-center h-screen">
                <div className="relative overflow-hidden w-full max-w-[800px]">
                  <div className="relative overflow-hidden w-full pt-[70%]">
                    <iframe
                      className="absolute top-0 left-0 w-full h-full border-0"
                      src="https://www.youtube.com/embed/MCxXy8L8yts?rel=0&modestbranding=1&controls=1&showinfo=0&iv_load_policy=3"
                      title="YouTube video player"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                    <div className="absolute top-0 left-0 w-full h-full pointer-events-none">
                      <div className="absolute bottom-0 right-0 w-[120px] h-[40px] bg-transparent pointer-events-auto"></div>
                      <div className="absolute top-0 left-0 w-full h-[60px] bg-transparent pointer-events-auto"></div>
                      <div className="absolute top-0 left-0 w-[100px] h-[60px] bg-transparent pointer-events-auto"></div>
                      <div className="absolute bottom-0 right-0 w-[50px] h-[50px] bg-transparent pointer-events-auto"></div>
                      <div className="absolute bottom-0 left-0 w-[200px] h-[40px] bg-transparent pointer-events-auto"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* FAQ-3 */}
        <div className="border-b border-gray-200 pb-4">
          <button
            className="flex items-center justify-between w-full"
            onClick={() => toggleTab(5)}
          >
            <span className="text-lg font-medium text-gray-900">
              {t("FAQ-Q-3")}
            </span>
            {activeTab === 5 ? (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            )}
          </button>
          {activeTab === 5 && (
            <div className="mt-4">
              <p className="text-base text-gray-500">{t("FAQ-R-3")}</p>
              <div className="flex justify-center items-center h-screen">
                <div className="relative overflow-hidden w-full max-w-[800px]">
                  <div className="relative overflow-hidden w-full pt-[70%]">
                    <iframe
                      className="absolute top-0 left-0 w-full h-full border-0"
                      src="https://www.youtube.com/embed/64CDkD71FPk?rel=0&modestbranding=1&controls=1&showinfo=0&iv_load_policy=3"
                      title="YouTube video player"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                    <div className="absolute top-0 left-0 w-full h-full pointer-events-none">
                      <div className="absolute bottom-0 right-0 w-[120px] h-[40px] bg-transparent pointer-events-auto"></div>
                      <div className="absolute top-0 left-0 w-full h-[60px] bg-transparent pointer-events-auto"></div>
                      <div className="absolute top-0 left-0 w-[100px] h-[60px] bg-transparent pointer-events-auto"></div>
                      <div className="absolute bottom-0 right-0 w-[50px] h-[50px] bg-transparent pointer-events-auto"></div>
                      <div className="absolute bottom-0 left-0 w-[200px] h-[40px] bg-transparent pointer-events-auto"></div>
                    </div>
                  </div>
                </div>
              </div>
            
            
            </div>
          )}
        </div>

        {/* FAQ-4 */}
        <div className="border-b border-gray-200 pb-4">
          <button
            className="flex items-center justify-between w-full"
            onClick={() => toggleTab(6)}
          >
            <span className="text-lg font-medium text-gray-900">
              {t("FAQ-Q-4")}
            </span>
            {activeTab === 6 ? (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            )}
          </button>
          {activeTab === 6 && (
            <div className="mt-4">
              <p className="text-base text-gray-500">{t("FAQ-R-4")}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FAQPage;
