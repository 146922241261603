import React from 'react'
import Header from "../components/Layout/Header.jsx"
import Hero from "../components/Route/Hero/Hero"
import Sponsored from "../components/Route/Sponsored"
import Footer from "../components/Layout/Footer"


const HomePage = () => {
  return (
    <div>
        <Header activeHeading ={1}/>
        <Hero/>
        {/* <Calendar/> */}
        <Sponsored/>
        <Footer/>
    </div>
  )
}

export default HomePage