import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  team: null,
  teams: null,
  teamsHost: null,
  isLoading: false,
  success: false,
  error: null,
};

export const teamReducer = createReducer(initialState, {
    //createTeam
  teamCreateRequest: (state) => {
    state.isLoading = true;
    state.error = null;
    state.success = false;
  },
  teamCreateSuccess: (state, action) => {
    state.isLoading = false;
    state.team = action.payload;
    state.success = true;
  },
  teamCreateFail: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.success = false;
  },

// get all teams of host
getAllTeamsHostRequest: (state) => {
  state.isLoading = true;
  state.error = null;
},
getAllTeamsHostSuccess: (state, action) => {
  state.isLoading = false;
  state.teamsHost = action.payload;
},
getAllTeamsHostFailed: (state, action) => {
  state.isLoading = false;
  state.error = action.payload;
},

// delete team of a host
deleteTeamRequest: (state) => {
  state.isLoading = true;
},
deleteTeamSuccess: (state, action) => {
  state.isLoading = false;
  state.message = action.payload;
},
deleteTeamFailed: (state, action) => {
  state.isLoading = false;
  state.error = action.payload;
},



  clearErrors: (state) => {
    state.error = null;
  },
});