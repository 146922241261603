import React from "react";
import styles from "../../styles/styles";

const Career = () => {
  return (
    <>
      <div
        id="3"
        className={`relative min-h-[70vh] 800px:min-h-[80vh] w-full bg-no-repeat ${styles.normalFlex}`}
        style={{
          backgroundImage: "url(https://i.imgur.com/mQPPeT7.jpg)",
        }}
      >
        <div className={`${styles.section} w-[90%] 800px:w-[60%]`}>
          <h1
            className={`text-[35px] leading-[1.2] 800px:text-[50px] text-[#ffffff] font-[600] capitalize`}
          >
            <br />
            <br />
            <br />
            <br />
            Careers
          </h1>
        </div>
      </div>
      {/* second section */}
      <div
        className={`relative min-h-[70vh] 800px:min-h-[80vh] w-full bg-no-repeat ${styles.normalFlex} bg-black`}
      >
        <div className={`${styles.section} w-full 800px:w-[60%]`}>
          <h2
            className={`text-[35px] leading-[1.2] 800px:text-[60px] text-[#ffffff] font-[600] capitalize`}
          >
            Come{" "}
            <span
              className={`{mt-6 text-center text-6xl font-bold text-cyan-500 whitespace-no-wrap}`}
            >
              Work With Us
            </span>
          </h2>
          <p className="text-[25px] font-[Poppins] font-[400] text-[#e2dada]">
            Checknclean's doors are open to boost your career. We promote
            professional development. We take care of their learning path. We
            respect experience and wisdom. We eliminate any social, cultural or
            political barriers and we prioritize worklife balance to achieve a
            fully fulfilled life. Join us and thrive.
            <br />
          </p>
        </div>
      </div>
    </>
  );
};

export default Career;
