import React, { useEffect, useRef } from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import About from "../components/Company/About";
import Offering from "../components/Company/Offering";
import Career from "../components/Company/Career";
import AI from "../components/Company/AI";

const CompanyPage = () => {
  const aboutRef = useRef(null);

  useEffect(() => {
    if (aboutRef.current) {
      aboutRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <div className="bg-[#2b2929]">
      <Header activeHeading={1} />
      <About ref={aboutRef} />
      <Offering />
      <Career />
      <AI />
      <Footer aboutRef={aboutRef} />
    </div>
  );
};

export default CompanyPage;
