import React from "react";
import { DateTime } from "luxon";

const Message = ({ sender, message, teamName, time, isReceived }) => {

  const formatDate = (dateString) => {
    const formattedDate = DateTime.fromISO(dateString, { zone: "utc" })
      .setZone("local")
      .toFormat("MMM-dd-yyyy hh:mm a");
    return formattedDate;
  };

  return (
    <div
      className={`chat__messageBox ${
        isReceived && "chat__messageBox__received"
      }`}
    >
      {!isReceived && <h5>{teamName}</h5>}
      <p>{message}</p>
      <b className="chat__messageBox__timestamp">{formatDate(time)}</b>
    </div>
  );
 };

export default Message;
