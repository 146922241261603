import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  episode: null,
  service: null,
  episodes: null,
  episodeHost: null,
  episodesHost: null,
  isLoading: false,
  error: null,
  success: false,
};

export const episodeReducer = createReducer(initialState, {
  //create episode
  createEpisodeRequest: (state) => {
    state.isLoading = true;
  },
  createEpisodeSuccess: (state, action) => {
    state.isLoading = false;
    state.episode = action.payload;
    state.success = true;
  },
  createEpisodeFail: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.success = false;
  },

  //create Service
  createServiceRequest: (state) => {
    state.isLoading = true;
    state.success = false;
    state.error = null;
  },
  createServiceSuccess: (state, action) => {
    state.isLoading = false;
    state.service = action.payload;
    state.success = true;
  },
  createServiceFail: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.success = false;
  },

  // get all episodes of host
  getAllEpisodesHostRequest: (state) => {
    state.isLoading = true;
    state.error = null;
  },
  getAllEpisodesHostSuccess: (state, action) => {
    state.isLoading = false;
    state.episodesHost = action.payload;
  },
  getAllEpisodesHostFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  // delete episode of a host
  deleteEpisodeRequest: (state) => {
    state.isLoading = true;
  },
  deleteEpisodeSuccess: (state, action) => {
    state.isLoading = false;
    state.message = action.payload;
  },
  deleteEpisodeFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

    // Get a episode of host
  getEpisodeHostRequest: (state) => {
    state.isLoading = true;
    state.error = null;
  },
  getEpisodeHostSuccess: (state, action) => {
    state.isLoading = false;
    state.episodeHost = action.payload;
  },
  getEpisodeHostFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  clearErrors: (state) => {
    state.error = null;
  },
});
