import React, { useEffect, useState } from "react";

import { TbCalendarPlus } from "react-icons/tb";
import {
  MdGroups,
  MdGroupAdd,
  MdCalendarMonth,
  MdNoteAdd,
  MdHolidayVillage,
  MdAddHome,
  MdContentCopy,
  MdOutlineSettings,
  MdDashboard,
} from "react-icons/md";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getTranslation } from "../../../redux/actions/translation";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";

const DashboardSideBar = ({ active }) => {
  const { host } = useSelector((state) => state.host);
  const { translation } = useSelector((state) => state.translations);
  const dispatch = useDispatch();
  const { t } = useTranslation(host ? "private" : "public");
  const [translationsLoaded, setTranslationsLoaded] = useState(false);
  const [translationsFetched, setTranslationsFetched] = useState(false); // Nuevo estado

  useEffect(() => {
    if (!translationsFetched) {
      const fetchTranslations = async () => {
        const language = i18n.language;
        await dispatch(getTranslation(language));
        setTranslationsFetched(true);
      };
      fetchTranslations();
    }
  }, [dispatch, i18n.language]);

  useEffect(() => {
    const loadTranslations = () => {
      const language = i18n.language;
      if (
        host &&
        translationsFetched && // Asegurarse de que las traducciones han sido obtenidas
        !translationsLoaded &&
        translation &&
        !i18n.hasResourceBundle(language, "private")
      ) {
        i18n.addResourceBundle(language, "private", translation, true, true);
        i18n.loadNamespaces(["private"], () => {
          setTranslationsLoaded(true);
        });
      } 
    };

    loadTranslations();
  }, [
    host,
    translation,
    translationsLoaded,
    translationsFetched,
    i18n.language,
  ]);


  return (
    <div className="w-full h-[90vh] bg-white shadow-sm overflow-y-scroll sticky top-0 left-0 z-10">
      {/* single item */}
      <div className="w-full flex flex-col items-center p-2">
        <Link to="/dashboard" className="w-full flex flex-col items-center">
          <MdDashboard
            size={30}
            color={`${active === 1 ? "crimson" : "#222"}`}
          />
          <h5
            className={`hidden 800px:block text-[10px] font-[500] ${
              active === 1 ? "text-red-800" : "text-cyan-800"
            }`}
          >
            {t("SideBar-Dashboard-0")}
          </h5>
        </Link>
      </div>
      <div className="w-full flex flex-col items-center p-2">
        <Link
          to="/dashboard-calendars"
          className="w-full flex flex-col items-center"
        >
          <MdCalendarMonth
            size={30}
            color={`${active === 2 ? "crimson" : "#333"}`}
          />
          <h5
            className={`hidden 800px:block text-[10px] font-[500] ${
              active === 2 ? "text-red-800" : "text-cyan-800"
            }`}
          >
            {t("SideBar-Calendars-0")}
          </h5>
        </Link>
      </div>
      <div className="w-full flex flex-col items-center p-2">
        <Link
          to="/dashboard-places"
          className="w-full flex flex-col items-center"
        >
          <MdHolidayVillage
            size={30}
            color={`${active === 3 ? "crimson" : "#444"}`}
          />
          <h5
            className={`hidden 800px:block text-[10px] font-[500] ${
              active === 3 ? "text-red-800" : "text-cyan-800"
            }`}
          >
            {t("SideBar-Places-0")}
          </h5>
        </Link>
      </div>
      <div className="w-full flex flex-col items-center p-2">
        <Link
          to="/dashboard-teams"
          className="w-full flex flex-col items-center"
        >
          <MdGroups size={30} color={`${active === 4 ? "crimson" : "#555"}`} />
          <h5
            className={`hidden 800px:block text-[10px] font-[500] ${
              active === 4 ? "text-red-800" : "text-cyan-800"
            }`}
          >
            {t("SideBar-Teams-0")}
          </h5>
        </Link>
      </div>
      <div className="w-full flex flex-col items-center p-2">
        <Link
          to="/dashboard-create-place"
          className="w-full flex flex-col items-center"
        >
          <MdAddHome size={30} color={`${active === 5 ? "crimson" : "#444"}`} />
          <h5
            className={`hidden 800px:block text-[10px] font-[500] ${
              active === 5 ? "text-red-800" : "text-cyan-800"
            }`}
          >
            {t("SideBar-AddPlace-0")}
          </h5>
        </Link>
      </div>
      <div className="w-full flex flex-col items-center p-2">
        <Link
          to="/dashboard-create-manual-booking"
          className="w-full flex flex-col items-center"
        >
          <TbCalendarPlus
            size={30}
            color={`${active === 6 ? "crimson" : "#333"}`}
          />
          <h5
            className={`hidden 800px:block text-[10px] font-[500] ${
              active === 6 ? "text-red-800" : "text-cyan-800"
            }`}
          >
            {t("SideBar-AddBooking-0")}
          </h5>
        </Link>
      </div>
      <div className="w-full flex flex-col items-center p-2">
        <Link
          to="/dashboard-create-team"
          className="w-full flex flex-col items-center"
        >
          <MdGroupAdd
            size={30}
            color={`${active === 7 ? "crimson" : "#222"}`}
          />
          <h5
            className={`hidden 800px:block text-[10px] font-[500] ${
              active === 7 ? "text-red-800" : "text-cyan-800"
            }`}
          >
            {t("SideBar-AddTeam-0")}
          </h5>
        </Link>
      </div>
    </div>
  );
};

export default DashboardSideBar;
