import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {loadHostAuth} from "../../redux/actions/host"
import { toast } from "react-toastify";
import { createTeam } from "../../redux/actions/team";
import Loader from "../Layout/Loader";
import { RxAvatar } from "react-icons/rx";

const CreateTeam = () => {
  const { host, isLoading } = useSelector((state) => state.host);
  const { success, error } = useSelector((state) => state.teams);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [byWA, setByWA] = useState(true);
  const [bySMS, setBySMS] = useState(false);
  const [byEmail, setByEmail] = useState(false);
  const [avatar, setAvatar] = useState();

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    if (success) {
      toast.success("Team created successfully!");
      navigate("/dashboard");
      dispatch(loadHostAuth());
      window.location.reload();
    }
  }, [dispatch, error, success, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newForm = new FormData();
    newForm.append("name", name);
    newForm.append("phoneNumber", phoneNumber);
    newForm.append("email", email);
    newForm.append("byWA", byWA);
    newForm.append("bySMS", bySMS);
    newForm.append("byEmail", byEmail);
    newForm.append("file", avatar);
    newForm.append("hostId", host._id);
    dispatch(createTeam(newForm));
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    setAvatar(file);
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="w-[90%] 800px:w-[50%] bg-white  shadow h-[80vh] rounded-[4px] p-3 overflow-y-scroll">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            {/* <h1 className={`{${styles.logoText}`}>ChecK 'N' CleaN</h1> */}
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Create Team
            </h2>
          </div>
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div className="mt-2">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Name
              </label>
              <div className="mt-1">
                <input
                  type="name"
                  name="name"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Phone
              </label>
              <div className="mt-1">
                <input
                  type="number"
                  name="phone-number"
                  required
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <div className="mt-1">
                <input
                  type="email"
                  name="email"
                  autoComplete="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="byEmail-checkbox"
                className="block text-sm font-medium text-gray-700"
              >
                Reach out by
              </label>
              <div className="appearance-none flex w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                <div className="mr-5">
                  <label
                    htmlFor="byWA"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Whatsapp
                  </label>
                  <input
                    type="checkbox"
                    id="byWA"
                    name="byWA"
                    checked={byWA}
                    onChange={(e) => setByWA(e.target.checked)}
                    className="appearance-none w-6 h-6 border border-gray-300 rounded-md checked:bg-blue-500 checked:border-transparent focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
                <div className="mr-5">
                  <label
                    htmlFor="bySMS-checkbox"
                    className="block text-sm font-medium text-gray-700"
                  >
                    SMS
                  </label>
                  <input
                    type="checkbox"
                    id="bySMS-checkbox"
                    name="bySMS"
                    checked={bySMS}
                    onChange={(e) => setBySMS(e.target.checked)}
                    className="appearance-none w-6 h-6 border border-gray-300 rounded-md checked:bg-blue-500 checked:border-transparent focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
                <div className="mr-5">
                  <label
                    htmlFor="byEmail-checkbox"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email
                  </label>
                  <input
                    type="checkbox"
                    id="byEmail-checkbox"
                    name="byEmail"
                    checked={byEmail}
                    onChange={(e) => setByEmail(e.target.checked)}
                    className="appearance-none w-6 h-6 border border-gray-300 rounded-md checked:bg-blue-500 checked:border-transparent focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
              </div>
            </div>
            <div>
              <label
                htmlFor="avatar"
                className="block text-sm font-medium text-gray-700"
              ></label>
              <div className="mt-2 flex items-center">
                <span className="inline-block h-8 w-8 rounded-full overflow-hidden">
                  {avatar ? (
                    <img
                      src={URL.createObjectURL(avatar)}
                      alt="avatar"
                      className="h-full w-full object-cover rounded-full"
                    />
                  ) : (
                    <RxAvatar className="h-8 w-8" />
                  )}
                </span>
                <label
                  htmlFor="file-input"
                  className="ml-5 flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                >
                  <span>Upload a file</span>
                  <input
                    type="file"
                    name="avatar"
                    id="file-input"
                    onChange={handleFileInputChange}
                    className="sr-only"
                  />
                </label>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
              >
                Create
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default CreateTeam;
