import React from 'react'
import Header from "../components/Layout/Header.jsx"
import Footer from "../components/Layout/Footer"

const ContactPage = () => {

  return (
    <div>
      <Header activeHeading={1} />
      <Contact />
      <Footer />
    </div>
  );
};

const Contact = () => {
  return (
    <div>ContactPage</div>
  )
}

export default ContactPage