import { useSelector } from "react-redux";
import { useState } from "react";
import { Navigate } from "react-router-dom";
import Loader from "../components/Layout/Loader";

const HostProtectedRoute = ({ children }) => {
  const { isLoading, isHost, isAuth, host } = useSelector(
    (state) => state.host
  );
  const [startTime] = useState(Date.now());
  if (isLoading || (!isHost && !isAuth)) {
    // return <Loader />;

    //agregado
    const currentTime = Date.now();
    if (currentTime - startTime > 13000) {
      <Navigate to="/host-login" replace />;
      return null;
    }
    return <Loader />;
  } //fin agregado

  if (isHost || isAuth) {
    if (!host.termsAccepted) {
      return <Navigate to="/host-terms-accept" replace />;
    }
  }

  return isHost || isAuth ? children : <Navigate to="/host-login" replace />;
};

export default HostProtectedRoute;
