import axios from "axios";
import { server } from "../../server";

// create place
export const createPlace = (newForm) => async (dispatch) => {
  try {
    dispatch({
      type: "placeCreateRequest",
    });

    const config = { headers: { "Content-Type": "multipart/form-data" } };

    const { data } = await axios.post(
      `${server}/place/create-place`,
      newForm,
      config,
      {
        withCredentials: true,
      }
    );
    dispatch({
      type: "placeCreateSuccess",
      payload: data.place,
    });
  } catch (error) {
    dispatch({
      type: "placeCreateFail",
      payload: error.response.data.message,
    });
  }
};

//get a Place by Id
export const getPlace = (placeId) => async (dispatch) => {
  try {
    dispatch({
      type: "getPlaceRequest",
    });
    const { data } = await axios.get(`${server}/place/get-place/${placeId}`);
    dispatch({
      type: "getPlaceSuccess",
      payload: data.place,
    });
  } catch (error) {
    dispatch({
      type: "getPlaceFailed",
      payload: error.response.data.message,
    });
  }
};

// get All Places of a host
export const getAllPlacesHost = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "getAllPlacesHostRequest",
    });

    const { data } = await axios.get(
      `${server}/place/get-all-places-host/${id}`
    );
    dispatch({
      type: "getAllPlacesHostSuccess",
      payload: data.placesHost,
    });
  } catch (error) {
    dispatch({
      type: "getAllPlacesHostFailed",
      payload: error.response.data.message,
    });
  }
};

// delete place of a host
export const deletePlace = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "deletePlaceRequest",
    });

    const { data } = await axios.delete(
      `${server}/place/delete-host-place/${id}`,
      {
        withCredentials: true,
      }
    );

    dispatch({
      type: "deletePlaceSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "deletePlaceFailed",
      payload: error.response.data.message,
    });
  }
};


