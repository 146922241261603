import React from "react";
import { backend_url } from "../../../server";

const SidebarChat = ({ _id, name, avatar, onClick, isSelected }) => {
  return (
    <div
      className={`sidebarchat__container ${
        isSelected ? "sidebarchat__containerSelected" : ""
      }`}
      onClick={onClick}
    >
      {avatar && (
        <img
          src={`${backend_url}/${avatar}`}
          alt="Team Avatar"
          className="w-[35px] h-[35px] rounded-full object-cover"
        />
      )}
      <div className="sidebarchat__text">
        <div className="sidebarchat__info">
          <h4> {name} </h4>
        </div>
      </div>
    </div>
  );
};
export default SidebarChat;
