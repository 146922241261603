import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(HttpApi) // middleware para cargar archivos de traducción
  .use(LanguageDetector) // detecta el idioma del navegador
  .use(initReactI18next) // pasar i18n a react-i18next
  .init({
    fallbackLng: "EN",
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: '/locals/{{lng}}/{{ns}}.json',
    },
    ns: ['public', 'private'],
    defaultNS: 'public',
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
      caches: ['localStorage', 'cookie'],
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
