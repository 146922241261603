import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import HostCreate from "../components/Host/HostCreate.jsx";

const HostCreatePage = () => {
  const navigate = useNavigate();
  const { isHost, host} = useSelector((state) => state.host);

  useEffect(() => {
    if(isHost === true){
      navigate(`/host/${host.id}`);    }
  }, [])

  return (
    <div>
      <HostCreate />
    </div>
  );
};

export default HostCreatePage;
