import axios from "axios";
import { server } from "../../server";

// get all messages of a host
export const getTranslation = (language) => async (dispatch) => {
  try {
    dispatch({
      type: "getTranslationRequest",
    });
    const { data } = await axios.get(
      `${server}/translation/get-translation/${language}`,
      {
        withCredentials: true,
      }
    );
    dispatch({
      type: "getTranslationSuccess",
      payload: data.translation,
    });
  } catch (error) {
    dispatch({
      type: "getTranslationFailed",
      payload: error.response.data.message,
    });
  }
};