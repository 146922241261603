import React, { useEffect, useState } from "react";
import { AiOutlineDelete, AiOutlinePlusCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createPlace } from "../../redux/actions/place";
import { propertyTypeData } from "../../static/data";
import { toast } from "react-toastify";
import { DataGrid } from "@material-ui/data-grid";
import { Button } from "@material-ui/core";

const CreatePlace = () => {
  const { host } = useSelector((state) => state.host);
  const { success, error } = useSelector((state) => state.places);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [calendars, setCalendars] = useState([]);
  const [calendarUrl, setCalendarUrl] = useState("");
  const [images, setImages] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [propertyType, setPropertyType] = useState("");
  const [bedsNumber, setBedsNumber] = useState();
  const [bathsNumber, setBathsNumber] = useState();
  const [size, setSize] = useState();
  const [unitSize, setUnitSize] = useState();

  const [streetAddress, setStreetAddress] = useState("");
  const [cityTown, setCityTown] = useState("");
  const [stateProvince, setStateProvince] = useState("");
  const [country, setCountry] = useState("");
  const [ZIPCode, setZIPCode] = useState("");
  const [floor, setFloor] = useState("");
  const [doorNumber, setDoorNumber] = useState("");
  const [accessInstructions, setAccessInstructions] = useState("");
  //Default Values
  const [defaultCheckIn, setDefaultCheckIn] = useState();
  const [defaultCheckOut, setDefaultCheckOut] = useState();
  const [defaultDurationCheckIn, setDefaultDurationCheckIn] = useState();
  const [defaultDurationCheckOut, setDefaultDurationCheckOut] = useState();
  const [defaultDurationCleaning, setDefaultDurationCleaning] = useState();

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    if (success) {
      toast.success("Place created successfully!");
      navigate("/dashboard");
      window.location.reload();
    }
  }, [dispatch, navigate, error, success]);

  // Handlers - Calendar
  const isValidUrl = (url) => {
    const urlPattern = new RegExp(/^(ftp|http|https):\/\/[^ "]+$/, "i"); // That it is a valid link
    const isUnique = !calendars.some(
      (calendar) => calendar.calendarUrl === url
    ); // Check that it is not previously loaded
    return urlPattern.test(url) && isUnique;
  };
  const extractPlatform = (url) => {
    // Extract the platform
    const match = url.match(/\/\/(?:www\.)?([^/]+?)(?:\.com)?(?:\/|$)/i) ?? [];
    const platform = match[1]
      ? match[1][0].toUpperCase() + match[1].slice(1)
      : null;
    return platform;
  };
  const handleCalendarUrlChange = () => {
    if (isValidUrl(calendarUrl.trim())) {
      setCalendars((prevCalendars) => [
        ...prevCalendars,
        {
          id: new Date().toISOString(),
          platform: extractPlatform(calendarUrl.trim()),
          calendarUrl: calendarUrl.trim(),
        },
      ]);
      setCalendarUrl("");
    } else {
      console.error("URL no válida");
    }
  };
  const handleDelete = (id) => {
    setCalendars((prevCalendars) => {
      return prevCalendars.filter((calendar) => calendar.id !== id);
    });
  };
  const columnsCalendars = [
    {
      field: "platform",
      headerName: "Platform",
      minWidth: 45,
      flex: 0.45,
    },
    {
      field: "calendarUrl",
      headerName: "Platform Calendar Url",
      minWidth: 120,
      flex: 1,
      renderCell: (params) => (
        <a href={params.value} target="_blank" rel="noopener noreferrer">
          {params.value}
        </a>
      ),
    },
    {
      field: "Delete",
      minWidth: 45,
      flex: 0.45,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => (
        <Button onClick={() => handleDelete(params.row.id)}>
          <AiOutlineDelete size={25} />
        </Button>
      ),
    },
  ];

  const handleImageChange = (e) => {
    e.preventDefault();
    let files = Array.from(e.target.files);
    setImages((prevImages) => [...prevImages, ...files]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newForm = new FormData();
    //Fields form
    newForm.append("name", name);
    newForm.append("description", description);
    newForm.append("propertyType", propertyType);
    newForm.append("bedsNumber", bedsNumber);
    newForm.append("bathsNumber", bathsNumber);
    newForm.append("defaultCheckIn", defaultCheckIn);
    newForm.append("defaultCheckOut", defaultCheckOut);
    newForm.append("defaultDurationCleaning", defaultDurationCleaning);
    newForm.append("defaultDurationCheckOut", defaultDurationCheckOut);
    newForm.append("defaultDurationCheckIn", defaultDurationCheckIn);
    newForm.append("size", size);
    newForm.append("unitSize", unitSize);
    calendars.forEach((calendar, index) => {
      newForm.append(`calendars[${index}][id]`, calendar.id);
      newForm.append(`calendars[${index}][platform]`, calendar.platform);
      newForm.append(`calendars[${index}][calendarUrl]`, calendar.calendarUrl);
    });
    newForm.append("streetAddress", streetAddress);
    newForm.append("cityTown", cityTown);
    newForm.append("stateProvince", stateProvince);
    newForm.append("country", country);
    newForm.append("ZIPCode", ZIPCode);
    newForm.append("floor", floor);
    newForm.append("doorNumber", doorNumber);
    newForm.append("accessInstructions", accessInstructions);
    images.forEach((image) => {
      newForm.append("images", image);
    });
    newForm.append("hostId", host._id);
    dispatch(createPlace(newForm));
  };

  //Esto es el orden de prioridades primero check-out luego check-in
  const handleDefaultCheckOutChange = (value) => {
    setDefaultCheckOut(value);
    if (
      value &&
      defaultCheckIn &&
      timeOptions.indexOf(value) >= timeOptions.indexOf(defaultCheckIn)
    ) {
      alert("Please enter Default Check Out time prior to Check In time");
    }
  };
  const handleDefaultCheckInChange = (value) => {
    setDefaultCheckIn(value);
    if (
      value &&
      defaultCheckOut &&
      timeOptions.indexOf(value) <= timeOptions.indexOf(defaultCheckOut)
    ) {
      alert("Please enter Default Check In time after Check Out time");
    }
  };

  // const handleDefaultDurationCleaningChange = (value) => {
  //   setDefaultDurationCleaning(value);
  // };

  // const handleDefaultDurationCheckOutChange = (value) => {
  //   setDefaultDurationCheckOut(value);
  // };
  // const handleDefaultDurationCheckInChange = (value) => {
  //   setDefaultDurationCheckIn(value);
  // };

  const timeOptions = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      const formattedHour = (hour % 12 === 0 ? 12 : hour % 12)
        .toString()
        .padStart(2, "0");
      const formattedMinute = minute.toString().padStart(2, "0");
      const period = hour < 12 ? "AM" : "PM";
      const timeString = `${formattedHour}:${formattedMinute} ${period}`;
      timeOptions.push(timeString);
    }
  }

  return (
    <div className="w-[90%] 800px:w-[50%] bg-white  shadow h-[80vh] rounded-[4px] p-3 overflow-y-scroll">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        {/* <h1 className={`{${styles.logoText}`}>ChecK 'N' CleaN</h1> */}
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Create Place
        </h2>
      </div>
      {/* create place form */}
      <form onSubmit={handleSubmit}>
        <br />
        <div>
          <label className="pb-2">
            Place Name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="name"
            value={name}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter your place name..."
          />
        </div>
        <br />
        <div>
          <label className="pb-2">
            Calendars <span className="text-red-500">*</span>
          </label>
          <div className="mt-3 rounded-lg border border-gray-300 p-4">
            <div className="flex">
              <input
                type="text"
                name="calendarUrl"
                value={calendarUrl}
                className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                onChange={(e) => setCalendarUrl(e.target.value)}
                placeholder="Paste a Platform Calendar Url"
              />
              <label>
                <AiOutlinePlusCircle
                  size={35}
                  className="ml-1 mt-2"
                  color="#555"
                  onClick={() => handleCalendarUrlChange()}
                />
              </label>
            </div>

            <div className="mt-3 w-[100%] bg-white">
              <DataGrid
                rows={calendars}
                columns={columnsCalendars}
                pageSize={10}
                disableSelectionOnClick
                autoHeight
              />
            </div>
          </div>
        </div>
        <br />
        <label className="pb-2">
          Features <span className="text-red-500">*</span>
        </label>
        <div className="mt-3 rounded-lg border border-gray-300 p-4">
          <div>
            <label className="pb-2">
              Property Type <span className="text-red-500">*</span>
            </label>
            <select
              className="w-full mt-2 border h-[35px] rounded-[5px]"
              value={propertyType}
              onChange={(e) => setPropertyType(e.target.value)}
            >
              <option value="Choose a Property Type">
                Choose a Property Type
              </option>
              {propertyTypeData &&
                propertyTypeData.map((i) => (
                  <option value={i.title} key={i.title}>
                    {i.title}
                  </option>
                ))}
            </select>
          </div>
          <div className="flex">
            <div className="mt-3 mr-5">
              <label className="pb-2">Beds</label>
              <input
                type="number"
                name="bedsNumber"
                value={bedsNumber}
                min="0"
                className={`mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm${
                  bedsNumber ? "font-semibold" : ""
                }`}
                onChange={(e) => setBedsNumber(e.target.value)}
                placeholder="Beds Number..."
              />
            </div>
            <div className="mt-3 mr-5">
              <label className="pb-2">
                Baths <span className="text-red-500"></span>
              </label>
              <input
                type="number"
                name="bathsNumber"
                value={bathsNumber}
                min="0"
                className={`mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm${
                  bathsNumber ? "font-semibold" : ""
                }`}
                onChange={(e) => setBathsNumber(e.target.value)}
                placeholder="Baths Number"
              />
            </div>
            <div className="mt-3">
              <label className="pb-2">
                Size Place<span className="text-red-500"></span>
              </label>
              <div className="flex w-[120px]">
                <input
                  type="number"
                  name="size"
                  value={size}
                  min="0"
                  className={`mt-2 mr-1 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm ${
                    size ? "font-semibold" : ""
                  }`}
                  onChange={(e) => setSize(e.target.value)}
                  placeholder="Size"
                />
                <select
                  type="text"
                  name="unitSize"
                  value={unitSize}
                  className={`mt-2 w-[50px] appearance-none block px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm ${
                    unitSize && unitSize !== "notSelected" ? "font-semibold" : ""
                  }`}
                  onChange={(e) => setUnitSize(e.target.value)}
                  placeholder="Unit"
                >
                  <option value="notSelected">Unit</option>
                  <option value="sqft">sqft</option>
                  <option value="m²">m²</option>
                </select>
              </div>
            </div>
          </div>
          <div className="mt-3">
            <label className="pb-2">
              Description <span className="text-red-500"></span>
            </label>
            <textarea
              cols="30"
              required
              rows="5"
              type="text"
              name="description"
              value={description}
              className="mt-2 appearance-none block w-full pt-2 px-3 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter your place description..."
            ></textarea>
          </div>
        </div>

        <br />
        <label className="pb-2">
          Location <span className="text-red-500">*</span>
        </label>
        <div className="mt-3 rounded-lg border border-gray-300 p-4">
          <div className="">
            <label className="pb-2">
              Street Address <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="streetAddress"
              value={streetAddress}
              className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              onChange={(e) => setStreetAddress(e.target.value)}
              placeholder="Enter Street Address..."
            />
          </div>
          <div className="mt-3">
            <label className="pb-2">
              City/Town <span className="text-red-500"></span>
            </label>
            <input
              type="text"
              name="cityTown"
              value={cityTown}
              className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              onChange={(e) => setCityTown(e.target.value)}
              placeholder="Enter City/Town..."
            />
          </div>
          <div className="mt-3">
            <label className="pb-2">
              State/Province <span className="text-red-500"></span>
            </label>
            <input
              type="text"
              name="stateProvince"
              value={stateProvince}
              className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              onChange={(e) => setStateProvince(e.target.value)}
              placeholder="Enter State/Province..."
            />
          </div>
          <div className="mt-3">
            <label className="pb-2">
              Country completar con timezone-support
              <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="country"
              value={country}
              className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              onChange={(e) => setCountry(e.target.value)}
              placeholder="Enter Country..."
            />
          </div>
          <div className="mt-3">
            <label className="pb-2">
              ZIP Code <span className="text-red-500"></span>
            </label>
            <input
              type="text"
              name="ZIPCode"
              value={ZIPCode}
              className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              onChange={(e) => setZIPCode(e.target.value)}
              placeholder="Enter ZIP Code..."
            />
          </div>
          <div className="mt-3">
            <label className="pb-2">
              Floor <span className="text-red-500"></span>
            </label>
            <input
              type="text"
              name="floor"
              value={floor}
              className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              onChange={(e) => setFloor(e.target.value)}
              placeholder="Enter Floor..."
            />
          </div>
          <div className="mt-3">
            <label className="pb-2">
              Door Number <span className="text-red-500"></span>
            </label>
            <input
              type="text"
              name="doorNumber"
              value={doorNumber}
              className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              onChange={(e) => setDoorNumber(e.target.value)}
              placeholder="Enter Door Number..."
            />
          </div>
          <div className="mt-3">
            <label className="pb-2">
              Access Instructions <span className="text-red-500"></span>
            </label>
            <textarea
              cols="30"
              // required
              rows="3"
              type="text"
              name="accessInstructions"
              value={accessInstructions}
              className="mt-2 appearance-none block w-full pt-2 px-3 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              onChange={(e) => setAccessInstructions(e.target.value)}
              placeholder="Enter your access instructions..."
            ></textarea>
          </div>
        </div>
        <br />
        <label className="pb-2">
          Default Values for Guest <span className="text-red-500">*</span>
        </label>
        <div className="mt-3 rounded-lg border border-gray-300 p-4 flex justify-center">
          <div className="mr-5">
            <label className="pb-2">
              Check Out Time <span className="text-red-500">*</span>
            </label>
            <select
              name="defaultCheckOut"
              value={defaultCheckOut}
              onChange={(e) => handleDefaultCheckOutChange(e.target.value)}
              className={`mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm ${
                defaultCheckOut ? "font-semibold" : ""
              }`}
            >
              <option value="">Select Check Out Time</option>
              {timeOptions.map((time, index) => (
                <option key={index} value={time}>
                  {time}
                </option>
              ))}
            </select>
          </div>
          <div className="">
            <label className="pb-2">
              Check In Time <span className="text-red-500">*</span>
            </label>
            <select
              name="defaultCheckIn"
              value={defaultCheckIn}
              onChange={(e) => handleDefaultCheckInChange(e.target.value)}
              className={`mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm ${
                defaultCheckIn ? "font-semibold" : ""
              }`}
            >
              <option value="">Select Check In Time</option>
              {timeOptions.map((time, index) => (
                <option key={index} value={time}>
                  {time}
                </option>
              ))}
            </select>
          </div>
        </div>
        <br />
        <label className="pb-2">
          Default Values for Services <span className="text-red-500">*</span>
        </label>
        <div className="mt-3 rounded-lg border border-gray-300 p-4 flex justify-center">
          <div className="mr-5">
            <label className="pb-2 ">
              Cleaning Duration <span className="text-red-500">*</span>
            </label>
            <div className="flex items-center">
              <input
                type="number"
                name="defaultDurationCleaning"
                value={defaultDurationCleaning}
                min="0"
                default={0}
                className={`mt-2 appearance-none block w-full px-2 h-[30px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm ${
                  defaultDurationCleaning ? "font-semibold" : ""
                }`}
                onChange={(e) => setDefaultDurationCleaning(e.target.value)}
                placeholder="Cleaning"
              />
              <label
                className={`mt-3 appearance-none block w-full px-2 h-[30px] ${
                  defaultDurationCleaning ? "font-semibold" : ""
                }`}
              >
                min
              </label>
            </div>
            <div className="items-center">
              {defaultDurationCleaning > 60 && (
                <label
                  className={`block w-full px-2  ${
                    defaultDurationCleaning ? "italic small" : ""
                  }`}
                >
                  {`${Math.floor(defaultDurationCleaning / 60)} h ${
                    defaultDurationCleaning % 60
                  } m`}
                </label>
              )}
            </div>
          </div>
          <div className="mr-5">
            <label className="pb-2">
              Check Out Duration <span className="text-red-500">*</span>
            </label>
            <div className="flex items-center">
              <input
                type="number"
                name="defaultDurationCheckOut"
                value={defaultDurationCheckOut}
                min="0"
                default={0}
                className={`mt-2 appearance-none block w-full px-2 h-[30px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm ${
                  defaultDurationCheckOut ? "font-semibold" : ""
                }`}
                onChange={(e) => setDefaultDurationCheckOut(e.target.value)}
                placeholder="CheckOut"
              />
              <label
                className={`mt-3 appearance-none block w-full px-2 h-[30px] ${
                  defaultDurationCheckOut ? "font-semibold" : ""
                }`}
              >
                min
              </label>
            </div>
            <div className="items-center">
              {defaultDurationCheckOut > 60 && (
                <label
                  className={`block w-full px-2  ${
                    defaultDurationCheckOut ? "italic small" : ""
                  }`}
                >
                  {`${Math.floor(defaultDurationCheckOut / 60)} h ${
                    defaultDurationCheckOut % 60
                  } m`}
                </label>
              )}
            </div>
          </div>
          <div className="">
            <label className="pb-2">
              Check In Duration <span className="text-red-500">*</span>
            </label>
            <div className="flex items-center">
              <input
                type="number"
                name="defaultDurationCheckIn"
                value={defaultDurationCheckIn}
                min="0"
                default={0}
                className={`mt-2 appearance-none block w-full px-2 h-[30px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm ${
                  defaultDurationCheckIn ? "font-semibold" : ""
                }`}
                onChange={(e) => setDefaultDurationCheckIn(e.target.value)}
                placeholder="CheckIn"
              />
              <label
                className={`mt-3 appearance-none block w-full px-2 h-[30px] ${
                  defaultDurationCheckIn ? "font-semibold" : ""
                }`}
              >
                min
              </label>
            </div>
            <div className="items-center">
              {defaultDurationCheckIn > 60 && (
                <label
                  className={`block w-full px-2  ${
                    defaultDurationCheckIn ? "italic small" : ""
                  }`}
                >
                  {`${Math.floor(defaultDurationCheckIn / 60)} h ${
                    defaultDurationCheckIn % 60
                  } m`}
                </label>
              )}
            </div>
          </div>
        </div>
        <br />
        <div>
          <label className="pb-2">
            Upload Images <span className="text-red-500"></span>
          </label>
          <input
            type="file"
            name=""
            id="upload"
            className="hidden"
            multiple
            onChange={handleImageChange}
          />
          <div className="w-full flex items-center flex-wrap">
            <label htmlFor="upload">
              <AiOutlinePlusCircle size={30} className="mt-3" color="#555" />
            </label>
            {images &&
              images.map((i) => (
                <img
                  src={URL.createObjectURL(i)}
                  key={i}
                  alt=""
                  className="h-[120px] w-[120px] object-cover m-2"
                />
              ))}
          </div>
          <br />
        </div>
        <div className="px-2 mt-2">
          <button
            type="submit"
            className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
          >
            Create
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreatePlace;
