import React, { useRef, useEffect } from "react";
import ChatHeader from "./ChatHeader";
import ChatFooter from "./ChatFooter";
import Message from "./Message";

import "../../../../styles/chat.css";

const Chat = ({ messages, team }) => {
  const host = "Host";

  const chatEndRef = useRef(null);
  const chatContainerRef = useRef(null);
  useEffect(() => {
    scrollToBottom();
  }, [messages]);
  const scrollToBottom = () => {
    if (chatContainerRef.current && chatEndRef.current) {
      const chatContainer = chatContainerRef.current;
      const chatEnd = chatEndRef.current;
      chatContainer.scrollTop = chatEnd.offsetTop - chatContainer.clientHeight;
    }
  };

  const handleScroll = (e) => {
    const element = e.target;
    const atBottom = element.scrollHeight - element.scrollTop === element.clientHeight;

    if (atBottom) {
      e.stopPropagation();
    }
  };

  let lastSeen = null;

  return (
    <div className="chat__container" >
      <ChatHeader team={team} lastSeen={lastSeen} />
      <div className="chat__main" >
        <div className="chat__chats" ref={chatContainerRef} onScroll={handleScroll}>
          {messages &&
            messages.map(({ _id, message, createdAt, sender, team }) => (
              <Message
                key={_id}
                sender={sender}
                message={message}
                teamName={team.name}
                time={createdAt}
                isReceived={host === sender}
              />
            ))}
          <div ref={chatEndRef} />
          <div />
        </div>
      </div>
      <ChatFooter teamId={team._id} host={host} />
    </div>
  );
};

export default Chat;
