import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const HostTermsModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-500 bg-opacity-75">
      <div className="relative bg-white p-6 rounded-lg shadow-lg max-w-lg w-full">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-800"
        >
          <svg
            className="w-6 h-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <h2 className="text-xl font-bold mb-4">{t("T&C-0")}</h2>
        <div className="max-h-80 text-xs overflow-y-auto">
          <p>{t("T&C-1")}</p>
          <p>{t("T&C-2")}</p>
          <p>{t("T&C-3")}</p>
          <p>{t("T&C-4")}</p>
          <p>{t("T&C-5")}</p>
          <p>{t("T&C-6")}</p>
          <p>{t("T&C-7")}</p>
          <p>{t("T&C-7-A")}</p>
          <p>{t("T&C-7-B")}</p>
          <p>{t("T&C-7-C")}</p>
          <p>{t("T&C-8")}</p>
          <p>{t("T&C-9")}</p>
          <p>{t("T&C-10")}</p>
          <p>{t("T&C-11")}</p>
          <p>{t("T&C-12")}</p>
        </div>
      </div>
    </div>
  );
};

export default HostTermsModal;
