import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "./Chat/Sidebar";
import Chat from "./Chat/Chat/Chat";
import { getAllTeamsHost } from "../../redux/actions/team";
import { getAllMessagesHost } from "../../redux/actions/message";

import "../../styles/App.css";

const AllMessages = () => {
  const { messagesHost } = useSelector((state) => state.messages);
  const { teamsHost } = useSelector((state) => state.teams);
  const { host } = useSelector((state) => state.host);
  const dispatch = useDispatch();
  const hostId = host._id;
  const [teams] = useState([]);
  let lastMessage = null;
  let [selectedTeam, setSelectedTeam] = useState([]);

  const processedTeam = new Set();

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getAllMessagesHost(hostId));
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    if (messagesHost) {
      messagesHost.forEach((message) => {
        if (message.status === 3 || message.status === 4) {
          if (!lastMessage || message.createdAt > lastMessage.createdAt) {
            lastMessage = message;
          }
          if (lastMessage) {
            setSelectedTeam(lastMessage.team);
          }
        //   if (selectedTeam) {
        //     teamMessages = messagesHost.filter(
        //       (message) => message.team._id === selectedTeam._id
        //     );
        // }
          if (!processedTeam.has(message.team._id)) {
            teams.push(message.team);
            processedTeam.add(message.team._id);
          }
        }
      });
    }

    const intervalId = setInterval(() => {
        fetchData();
      }, 60000); // 60k ms
      return () => clearInterval(intervalId);
  }, [dispatch, hostId]);

  useEffect(() => {
    const fetchData2 = async () => {
      try {
        await dispatch(getAllTeamsHost(hostId));
      } catch (error) {
        console.log(error);
      }
    };
    fetchData2();
  }, [dispatch, hostId]);


  return (
    <div className="app">
      <div className="background">
        <div className="backgroundTop"> </div>
      </div>
      <div className="app__container">
        {!messagesHost ? (
          <div className="app__containerLoader">
            <div className="loader_info">
              <div className="loader"></div>
            </div>
          </div>
        ) : (
          <>
            <Sidebar
              teams={teamsHost}
              selectedTeam={selectedTeam}
              setSelectedTeam={setSelectedTeam}
            />
            <Chat messages={messagesHost.filter(
              (message) => message.team._id === selectedTeam._id
            )} team={selectedTeam} />
          </>
        )}
      </div>
    </div>
  );
};

export default AllMessages;
