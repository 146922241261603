import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  message: null,
  messagesHost: null,
  isLoading: false,
  success: false,
  error: null,
};

export const messageReducer = createReducer(initialState, {
  messageCreateRequest: (state) => {
    state.isLoading = true;
  },
  messageCreateSuccess: (state, action) => {
    state.isLoading = false;
    state.message = action.payload;
    state.success = true;
  },
  messageCreateFail: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.success = false;
  },

  // get all messages of host
  getAllMessagesHostRequest: (state) => {
    state.isLoading = true;
    state.error = null;
  },
  getAllMessagesHostSuccess: (state, action) => {
    state.isLoading = false;
    state.messagesHost = action.payload;
  },
  getAllMessagesHostFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

    // get all messages of service
    getAllMessagesServiceRequest: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    getAllMessagesServiceSuccess: (state, action) => {
      state.isLoading = false;
      state.messagesService = action.payload;
    },
    getAllMessagesServiceFailed: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

//   // delete message of a host
//   deleteMessageRequest: (state) => {
//     state.isLoading = true;
//   },
//   deleteMessageSuccess: (state, action) => {
//     state.isLoading = false;
//     state.message = action.payload;
//   },
//   deleteMessageFailed: (state, action) => {
//     state.isLoading = false;
//     state.error = action.payload;
//   },

  clearErrors: (state) => {
    state.error = null;
  },
});
