import React, { useEffect } from "react";
import HostLogin from "../components/Host/HostLogin";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const HostLoginPage = () => {

  return (
    <div>
      <HostLogin />
    </div>
  );
};

export default HostLoginPage;
