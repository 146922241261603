import React, { useEffect, useRef, forwardRef } from "react";
import styles from "../styles/styles";
import Header from "../components/Layout/Header.jsx";
import Footer from "../components/Layout/Footer";
import { useTranslation } from "react-i18next";

const ManagementPage = () => {
  const managementRef = useRef(null);

  useEffect(() => {
    if (managementRef.current) {
      managementRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <div>
      <Header activeHeading={4} />
      <Management ref={managementRef} />
      <Footer ref={managementRef} />
    </div>
  );
};

const Management = forwardRef((props, ref) => {
  const { t } = useTranslation();
  return (
    <div>
      {" "}
      <>
        <div
          ref={ref}
          id="3"
          className={`relative min-h-[70vh] 800px:min-h-[80vh] w-full bg-no-repeat ${styles.normalFlex}`}
          style={{
            backgroundImage: "url(https://i.imgur.com/VIE2qF8.jpg)", //1920x762
          }}
        >              
          <div className={`${styles.section} w-[90%] 800px:w-[60%]`}>
            <h1
              className={`text-[35px] leading-[1.2] 800px:text-[50px] text-[#ffffff] text-center font-[600] capitalize`}
            >
              {t("Management-0")}
              <br />
              <br />
              <br />
              <br />
              <br />
              
            </h1>
          </div>
        </div>
        {/* second section */}
        <div
          className={`relative min-h-[70vh] 800px:min-h-[80vh] w-full bg-no-repeat ${styles.normalFlex} bg-black`}
        >
          <div className={`${styles.section} w-full 800px:w-[60%]`}>
            <h2
              className={`text-[35px] leading-[1.2] 800px:text-[60px] text-[#ffffff] font-[600] capitalize`}
            >
              {t("Management-1")}{" "}
              <span
                className={`{mt-6 text-center text-6xl font-bold text-cyan-500 whitespace-no-wrap}`}
              >
              {t("Management-2")}
              </span>
            </h2>
            <p className="text-[25px] font-[Poppins] font-[400] text-[#e2dada]">
              {t("Management-3")}
              {t("Management-4")}
              {t("Management-5")}
              <br />
            </p>
          </div>
        </div>
      </>
    </div>
  );
});

export default ManagementPage;
