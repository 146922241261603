import React, { useEffect } from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import LanguageSelector from "../src/components/Layout/LanguageSelector";
import {
  LoginPage,
  SignupPage,
  ActivationPage,
  HomePage,
  CalendarsPage,
  BookingsPage,
  ManagementPage,
  FAQPage,
  CompanyPage,
  ServicesPage,
  ContactPage,
  ProfilePage,
  CheckoutPage,
  HostCreatePage,
  HostTermsAcceptPage,
  HostActivationPage,
  HostLoginPage,
} from "./routes/Routes.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Store from "./redux/store";
import { loadUser } from "./redux/actions/user";
import { loadHost } from "./redux/actions/host";
import { loadHostAuth } from "./redux/actions/host";
import { useDispatch } from "react-redux";

import {
  HostHomePage,
  HostDashboardPage,
  HostCreatePlacePage,
  HostAllPlacesPage,
  HostCreateEpisodePage,
  HostAllEpisodesPage,
  HostEpisodeDetailsPage,
  HostServiceDetailsPage,
  HostCalendarDetailsPage,
  HostAllCalendarsPage,
  HostCreateTeamPage,
  HostAllTeamsPage,
  HostCreateBookingPage,
  HostAllMessagesPage,
} from "./routes/HostRoutes.js";
import HostProtectedRoute from "./routes/HostProtectedRoute.js";
import ProtectedRoute from "./routes/ProtectedRoute.js";

const App = () => {
  const dispatch = useDispatch();
  
  useEffect(() => {
    // Store.dispatch(loadUser());
    Store.dispatch(loadHost());
    Store.dispatch(loadHostAuth());
  }, [dispatch]);

  const { t } = useTranslation();

  return (
    <div>
      {/* <LanguageSelector /> */}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/sign-up" element={<SignupPage />} />
          <Route path="/calendars" element={<CalendarsPage />} />
          <Route path="/bookings" element={<BookingsPage />} />
          <Route path="/management" element={<ManagementPage />} />
          <Route path="/faq" element={<FAQPage />} />
          <Route path="/company" element={<CompanyPage />} />

          <Route path="/services" element={<ServicesPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route
            path="/activation/:activation_token"
            element={<ActivationPage />}
          />
          <Route
            path="/host/activation/:activation_token"
            element={<HostActivationPage />}
          />
          <Route
            path="/checkout"
            element={
              <ProtectedRoute>
                <CheckoutPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <ProfilePage />
              </ProtectedRoute>
            }
          />
          {/* Host Routes */}
          <Route path="/host-create" element={<HostCreatePage />} />
          <Route path="/host-terms-accept" element={<HostTermsAcceptPage />} />
          <Route path="/host-login" element={<HostLoginPage />} />
          <Route
            path="/host/:id"
            element={
              <HostProtectedRoute>
                <HostHomePage />
              </HostProtectedRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <HostProtectedRoute>
                <HostDashboardPage />
              </HostProtectedRoute>
            }
          />
          <Route
            path="/dashboard-create-place"
            element={
              <HostProtectedRoute>
                <HostCreatePlacePage />
              </HostProtectedRoute>
            }
          />
          <Route
            path="/dashboard-places"
            element={
              <HostProtectedRoute>
                <HostAllPlacesPage />
              </HostProtectedRoute>
            }
          />
          <Route
            path="/dashboard-create-episode" //Falta crear se llamaría HostCreatePlaceEpisodePage dado que si es manual habría que determinar el place (además de start, end, etc)
            element={
              <HostProtectedRoute>
                <HostCreateEpisodePage />
              </HostProtectedRoute>
            }
          />
          <Route
            path="/dashboard-create-episode/:id"
            element={
              <HostProtectedRoute>
                <HostCreateEpisodePage />
              </HostProtectedRoute>
            }
          />
          <Route
            path="/dashboard-episodes"
            element={
              <HostProtectedRoute>
                <HostAllEpisodesPage />
              </HostProtectedRoute>
            }
          />
          <Route
            path="/dashboard-episode/:id"
            element={
              <HostProtectedRoute>
                <HostEpisodeDetailsPage />
              </HostProtectedRoute>
            }
          />
          <Route
            path="/dashboard-service/:id"
            element={
              <HostProtectedRoute>
                <HostServiceDetailsPage />
              </HostProtectedRoute>
            }
          />
          <Route
            path="/dashboard-calendars"
            element={
              <HostProtectedRoute>
                <HostAllCalendarsPage />
              </HostProtectedRoute>
            }
          />
          <Route
            path="/dashboard-calendar/:id"
            element={
              <HostProtectedRoute>
                <HostCalendarDetailsPage />
              </HostProtectedRoute>
            }
          />
          <Route
            path="/dashboard-create-team"
            element={
              <HostProtectedRoute>
                <HostCreateTeamPage />
              </HostProtectedRoute>
            }
          />
          <Route
            path="/dashboard-teams"
            element={
              <HostProtectedRoute>
                <HostAllTeamsPage />
              </HostProtectedRoute>
            }
          />
          <Route
            path="/dashboard-messages"
            element={
              <HostProtectedRoute>
                <HostAllMessagesPage />
              </HostProtectedRoute>
            }
          />
          <Route
            path="/dashboard-create-manual-booking"
            element={
              <HostProtectedRoute>
                <HostCreateBookingPage />
              </HostProtectedRoute>
            }
          />
        </Routes>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
    </div>
  );
};

export default App;