import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  bookingHost: null,
  bookingsHost: null,
  bookingsPlace: null,
  isLoading: false,
  bookingsInserted: null,
  manualBooking: null,
  error: null,
  success: false,
};

export const icalReducer = createReducer(initialState, {
  icalCreateRequest: (state) => {
    state.isLoading = true;
  },
  icalCreateSuccess: (state, action) => {
    state.isLoading = false;
    state.bookingsInserted = action.payload;
    state.success = true;
    
  },
  icalCreateFail: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.success = false;
  },
  

  // Create Manual Booking
  createManualBookingRequest: (state) => {
    state.isLoading = true;
  },
  createManualBookingSuccess: (state, action) => {
    state.isLoading = false;
    state.manualBooking = action.payload;
    state.success = true;
  },
  createManualBookingFail: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.success = false;
  },

  // get all bookings of host
  getAllBookingsHostRequest: (state) => {
    state.isLoading = true;
    state.error = null;
  },
  getAllBookingsHostSuccess: (state, action) => {
    state.isLoading = false;
    state.bookingsHost = action.payload;
  },
  getAllBookingsHostFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  // get all bookings of a place (of a Host)
  getAllBookingsPlaceRequest: (state) => {
    state.isLoading = true;
  },
  getAllBookingsPlaceSuccess: (state, action) => {
    state.isLoading = false;
    state.bookingsPlace = action.payload;
  },
  getAllBookingsPlaceFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },
  clearBookingsPlace: (state, action) => {
    state.bookingsPlace = null;
    state.isLoading = false;
    state.error = null;
  },

  // delete ical of a host
  deleteIcalRequest: (state) => {
    state.isLoading = true;
  },
  deleteIcalSuccess: (state, action) => {
    state.isLoading = false;
    state.message = action.payload;
  },
  deleteIcalFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  // get all bookings
  getAllBookingsRequest: (state) => {
    state.isLoading = true;
    state.bookings = null;
  },
  getAllBookingsSuccess: (state, action) => {
    state.isLoading = false;
    state.bookings = action.payload;
    state.error = null;
  },
  getAllBookingsFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  // get Booking of a Host
  getBookingHostRequest: (state) => {
    state.isLoading = true;
    state.error = null;
  },
  getBookingHostSuccess: (state, action) => {
    state.isLoading = false;
    state.bookingHost = action.payload;
    state.error = null;
  },
  getBookingHostFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  clearErrors: (state) => {
    state.error = null;
  },
});
