import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../styles/styles";
import {
  MdHome,
  MdCalendarMonth,
  MdMenuBook,
  MdManageAccounts,
  MdQuestionAnswer,
} from "react-icons/md";
import { useTranslation } from "react-i18next";

const DropDown = ({ navItems, setDropDown }) => {
  const navigate = useNavigate();
  const submitHandle = (i) => {
    navigate(`${i.url}`);
    setDropDown(false);
    window.location.reload();
  };

  const iconMap = {
    MdHome,
    MdCalendarMonth,
    MdMenuBook,
    MdManageAccounts,
    MdQuestionAnswer,
  };
  const { t } = useTranslation();

  return (
    <div className="pb-4 w-[256px] bg-[#fff] absolute z-30 rounded-b-md shadow-sm">
      {navItems &&
        navItems.map((i, index) => {
          const IconComponent = iconMap[i.icon];
          return (
            <div
              key={index}
              className={`${styles.normalFlex}`}
              onClick={() => submitHandle(i)}
            >
              {IconComponent && (
                <IconComponent
                  size={20}
                  className="m-3 cursor-pointer select-none"
                />
              )}
              <h3 className="m-3 cursor-pointer select-none">{t(i.title)}</h3>
            </div>
          );
        })}
    </div>
  );
};

export default DropDown;
