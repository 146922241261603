import React, { useEffect } from "react";
import HostTerms from "../components/Host/HostTerms";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const HostLoginPage = () => {
  const navigate = useNavigate();
  const { isHost, isAuth, isLoading } = useSelector((state) => state.host);

  // useEffect(() => {
  //   if (isHost === true || isAuth === true) {
  //     navigate(`/dashboard`);
  //   }
  // }, [navigate, isHost, isAuth]);

  return (
    <div>
      <HostTerms />
    </div>
  );
};

export default HostLoginPage;
