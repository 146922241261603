// navigation Data
export const navItems = [
  {
    title: "Navbar-Home",
    icon: "MdHome",
    url: "/",
  },
  {
    title: "Navbar-MultiCalendar",
    icon: "MdCalendarMonth",
    url: "/calendars",
  },
  {
    title: "Navbar-Bookings",
    icon: "MdMenuBook",
    url: "/bookings",
  },
  {
    title: "Navbar-Management",
    icon: "MdManageAccounts",
    url: "/management",
  },
    {
    title: "Navbar-FAQ",
    icon: "MdQuestionAnswer",
    url: "/faq",
  },
];

// doings data (ex categories data)
export const doingsData = [
  {
    id: 0,
    title: "Choose a Doing",
    reactIcon: "",
    image_Url: "",
  },
  {
    id: 1,
    title: "Check In",
    reactIcon: "GiExitDoor",
    image_Url: "https://thumbs2.imgbox.com/6c/8d/1iCEThkW_t.jpg",
  },
  {
    id: 2,
    title: "Check Out",
    reactIcon: "GiEntryDoor",
    image_Url: "https://thumbs2.imgbox.com/02/25/SGF3thfN_t.jpg",
  },
  {
    id: 3,
    title: "Cleaning",
    reactIcon: "GiVacuumCleaner",
    image_Url: "https://thumbs2.imgbox.com/ec/e8/m8AwkQJI_t.jpg",
  },
  {
    id: 4,
    title: "Carry",
    reactIcon: "LiaPeopleCarrySolid",
    image_Url: "https://thumbs2.imgbox.com/9a/5e/F5wvKW0J_t.jpg",
  },
  {
    id: 5,
    title: "Maintenance",
    reactIcon: "LiaWrenchSolid",
    image_Url: "https://thumbs2.imgbox.com/1e/97/XBb09lqz_t.jpg",
  },
  {
    id: 6,
    title: "Other",
    reactIcon: "GiDiplodocus",
    image_Url: "https://thumbs2.imgbox.com/ce/3c/wj3narx7_t.jpg",
  },
  // {
  //   id: 6,
  //   title: "Projects",
  //   subTitle: "",
  //   image_Url: "https://thumbs2.imgbox.com/ce/3c/wj3narx7_t.jpg",
  // },
  // {
  //   id: 7,
  //   title: "Comunication",
  //   subTitle: "",
  //   image_Url: "https://thumbs2.imgbox.com/61/68/RgagaUQZ_t.jpg",
  // },
  // {
  //   id: 8,
  //   title: "Quality",
  //   subTitle: "",
  //   image_Url: "https://thumbs2.imgbox.com/dd/e1/Ltmz9YUI_t.jpg",
  // },
];

// doings data (ex categories data)
export const propertyTypeData = [
  {
    id: 1,
    title: "House",
    subTitle: "",
  },
  {
    id: 2,
    title: "Apartment",
    subTitle: "",
  },
  {
    id: 3,
    title: "Cabin",
    subTitle: "",
  },
  {
    id: 4,
    title: "Hotel",
    subTitle: "",
  },
];

// status data
export const statusData = [
  {
    id: 1,
    title: "Waiting",
    subTitle: "",
  },
  {
    id: 2,
    title: "Running",
    subTitle: "",
  },
  {
    id: 3,
    title: "Done",
    subTitle: "",
  },
  {
    id: 4,
    title: "Canceled",
    subTitle: "",
  },
];

export const footerCompanyLinks = [
  {
    name: "Footer-About-1",
    link: "/company",
  },
];

export const footerServicesLinks = [
  {
    name: "Footer-Services-1",
    link: "/calendars",
  },
  {
    name: "Footer-Services-2",
    link: "/bookings",
  },
  {
    name: "Footer-Services-3",
    link: "/management",
  },
];

export const footerSupportLinks = [
  {
    name: "Footer-Support-1",
    link: "/faq",
  },
  {
    name: "Footer-Support-2",
    link: "/contact",
  },
];
