import React, { useEffect, useState } from "react";
import {
  AiOutlineDelete,
  AiOutlinePlusCircle,
  AiTwotoneEdit,
  AiOutlineFieldTime,
} from "react-icons/ai";
import {
  GiExitDoor,
  GiEntryDoor,
  GiVacuumCleaner,
  GiDiplodocus,
} from "react-icons/gi";
import { LiaPeopleCarrySolid, LiaWrenchSolid } from "react-icons/lia";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import styles from "../../styles/styles";
import { toast } from "react-toastify";
import { doingsData } from "../../static/data";


import { DateTime } from "luxon";
import Board from "react-trello";

import { backend_url } from "../../server";

import { getBookingHost } from "../../redux/actions/ical";
import { createEpisode } from "../../redux/actions/episode";
import { getAllTeamsHost } from "../../redux/actions/team";

const CreateEpisode = () => {
  const { host } = useSelector((state) => state.host);
  const { booking } = useSelector((state) => state.icals);
  const { success, error } = useSelector((state) => state.episodes);
  const { teamsHost } = useSelector((state) => state.teams);

  const { id } = useParams();
  const hostId = host._id;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [startDate, setStartDate] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [endDate, setEndDate] = useState(null);
  const [status, setStatus] = useState("Waiting");
  const [bookingEpisode, setBookingEpisode] = useState("");
  const [teams, setTeams] = useState("");

  const [services, setServices] = useState([]);
  const [doing, setDoing] = useState("");
  const [serviceStatus, setServiceStatus] = useState("");
  const [serviceStartDate, setServiceStartDate] = useState(null);
  const [serviceEndDate, setServiceEndDate] = useState(null);
  const [team, setTeam] = useState("");
  const [comments, setComments] = useState("");

  //Bringing the booking
  useEffect(() => {
    if (id) {
      dispatch(getBookingHost(id));
      dispatch(getAllTeamsHost(hostId));
    }
  }, [id, hostId, dispatch]);

  //Seting the BookingEpisode
  useEffect(() => {
    if (booking && booking._id) {
      if (booking._id === id) {
        setBookingEpisode(booking);
      }
    }
  }, [booking, id]);

  //Seting the Teams
  useEffect(() => {
    if (teamsHost && teamsHost.length > 0) {
      setTeams(teamsHost);
    }
  }, [teamsHost]);

  const placeName = bookingEpisode && bookingEpisode.place.name;
  const placeId = bookingEpisode && bookingEpisode.place._id;

  useEffect(() => {
    const updateDate = () => {
      if (
        bookingEpisode &&
        bookingEpisode.end &&
        bookingEpisode.place &&
        bookingEpisode.place.defaultCheckIn
      ) {
        const timeZone = DateTime.local().setZone().zoneName;
        const startDate = DateTime.fromISO(bookingEpisode.end, { zone: "utc" })
          .setZone(timeZone)
          .toFormat("yyyy-MM-dd'T'HH:mm");
        setStartDate(startDate);
        setServiceStartDate(startDate);

        const dateWithoutTime =
          DateTime.fromISO(startDate).toFormat("yyyy-MM-dd");

        const defaultCheckInTime = DateTime.fromFormat(
          bookingEpisode.place.defaultCheckIn,
          "hh:mm a"
        ).toFormat("HH:mm");

        const endDate = DateTime.fromFormat(
          `${dateWithoutTime} ${defaultCheckInTime}`,
          "yyyy-MM-dd HH:mm"
        ).toFormat("yyyy-MM-dd'T'HH:mm");
        setEndDate(endDate);
        setServiceEndDate(endDate);
      }
    };
    updateDate();
  }, [bookingEpisode]);

  const handleServiceChange = () => {
    setServices((prevServices) => [
      ...prevServices,
      {
        id: new Date().toISOString(),
        doing: doing,
        startDate: serviceStartDate,
        endDate: serviceEndDate,
        teamId: team._id,
        teamAvatar: team.avatar,
        comments: comments,
        status: "Added",
      },
    ]);
  };

  const calculateTimeRemaining = (startDate) => {
    const now = new Date();
    const startDateObj = new Date(startDate);
    if (startDateObj < now) {
      return "0m";
    }
    const timeDifference = startDateObj - now;
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );
    let result = "";
    if (days !== 0) {
      result += `${days}d `;
    }
    if (hours !== 0) {
      result += `${hours}h `;
    }
    result += `${minutes}m`;
    return result.trim(); // Elimina espacios en blanco adicionales
  };

  const handleDelete = (id) => {
    setServices((prevServices) => {
      return prevServices.filter((service) => service.id !== id);
    });
  };

  const IconComponent = ({ children, ...props }) => {
    const iconMapping = {
      GiEntryDoor: GiEntryDoor,
      GiExitDoor: GiExitDoor,
      GiVacuumCleaner: GiVacuumCleaner,
      LiaPeopleCarrySolid: LiaPeopleCarrySolid,
      LiaWrenchSolid: LiaWrenchSolid,
      GiDiplodocus: GiDiplodocus,
    };
    const Icon = iconMapping[children];
    if (!Icon) {
      console.error(`IconComponent: Icon '${children}' not found`);
      return null;
    }
    return <Icon {...props} />;
  };

  console.log("Doing: ", doing);
  const data = {
    lanes: [
      {
        id: "services",
        title: "Services Added", //la idea es que el lane indique el status
        //style: { backgroundColor: 'yellow' },
        //cardStyle: { backgroundColor: 'blue' },
        cards: services.map((service) => ({
          // cardStyle: {
          //   borderRadius: 6,
          //   boxShadow: "0 0 6px 1px #BD3B36",
          //   marginBottom: 15,
          // },
          id: service.id,
          title: (
            <div className="flex items-center justify-start">
              {" "}
              {service.doing.title}
              <IconComponent
                size={30}
                className="ml-1 mr-2 text-right"
                color="#1B1E23"
                onClick={() => handleDelete()}
              >
                {service.doing.reactIcon}
              </IconComponent>{" "}
            </div>
          ),
          label: (
            <div className="h-full flex items-center justify-end">
              <span className="text-sm">{service.teamName}</span>
            </div>
          ),
          description: (
            <div className="">
              <div className="flex">
                <div className="mr-4">
                  {service.teamAvatar && (
                    <img
                      src={`${backend_url}${service.teamAvatar}`}
                      alt="Team Avatar"
                      className="w-[50px] h-[50px] rounded-full object-cover"
                    />
                  )}
                </div>
                <div className="">
                  <p>Start Date: {service.startDate}</p>
                  <p>End Date: {service.endDate}</p>
                  <p>Place: {placeName}</p>
                </div>
              </div>
              <div className="block">
                <p>Comments: {service.comments}</p>
              </div>
              <div>
                <hr />
                <div>
                  <div className="flex items-center justify-between">
                    <div className="flex justify-start">
                      <AiOutlineFieldTime
                        size={20}
                        className="ml-1 mr-2 mt-2 text-left "
                        color="#1B1E23"
                      />
                      <span className="ml-1 mt-2 text-left ">{`${calculateTimeRemaining(
                        service.startDate
                      )} left`}</span>
                    </div>
                    <div className="flex justify-end">
                      <AiTwotoneEdit
                        size={20}
                        className="ml-1 mr-2 mt-2 text-right"
                        color="#1B1E23"
                        onClick={() => handleDelete()}
                      />

                      <AiOutlineDelete
                        size={20}
                        className="ml-1 mt-2 text-right justify-end"
                        color="#1B1E23"
                        onClick={() => handleDelete()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ),
        })),
      },
    ],
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    if (success) {
      toast.success("Episode created successfully!");
      navigate("/dashboard-calendars");
      window.location.reload();
    }
  }, [dispatch, error, success]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const newForm = new FormData();
    //Fields form
    newForm.append("startDate", new Date(startDate).toISOString());
    newForm.append("endDate", new Date(endDate).toISOString());
    newForm.append("status", status);
    services.forEach((service, index) => {
      newForm.append(`services[${index}][id]`, service.id);
      newForm.append(`services[${index}][doing]`, service.doing);
      newForm.append(`services[${index}][startDate]`, service.serviceStartDate);
      newForm.append(`services[${index}][endDate]`, service.teamName);
      newForm.append(`services[${index}][endDate]`, service.phoneNumber);
      newForm.append(`services[${index}][endDate]`, service.serviceEndDate);
      newForm.append(`services[${index}][comments]`, service.comments);
      newForm.append(`services[${index}][status]`, service.status);
      newForm.append(`services[${index}][teamId]`, service.teamId);
    });
    newForm.append("bookingId", bookingEpisode._id);
    newForm.append("placeId", placeId);
    newForm.append("hostId", host._id);
    dispatch(createEpisode(newForm));
  };

  const formatDate = (dateString) => {
    const formattedDate = DateTime.fromISO(dateString, { zone: "utc" })
      .setZone("local")
      .toFormat("MMM-dd-yyyy hh:mm a");
    return formattedDate;
  };

  return (
    <div className="w-[90%] 800px:w-[50%] bg-white  shadow h-[80vh] rounded-[4px] p-3 overflow-y-scroll">
    <div className="sm:mx-auto sm:w-full sm:max-w-md">
      {/* <h1 className={`{${styles.logoText}`}>ChecK 'N' CleaN</h1> */}
      <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
        Create an Episode
      </h2>
      <h5 className="mt-2 text-[20px] font-Poppins text-center font-semibold">{`${placeName}`}</h5>

    </div>
      <br />
      <div className="mt-2 appearance-none block w-full bg-violet-100 px-3 border border-gray-300 rounded-[5px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
        {" "}
        <label className="pb-2">
          <span className={`text-[20px] ${styles.logoText} font-semibold`}>
            {" "}
            Booking{" "}
          </span>
        </label>
        <div className="pb-2">
          {bookingEpisode ? (
            <>
              <span className="h-full mt-1 block items-center text-left appearance-none w-full px-1 placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                {bookingEpisode.summary}
              </span>
              <span className="h-full mt-1 block items-center text-left appearance-none w-full px-1 placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                From{" "}
                <span className={`font-bold`}>
                  {" "}
                  {formatDate(bookingEpisode.start)}
                </span>{" "}
                to{" "}
                <span className={`font-bold`}>
                  {formatDate(bookingEpisode.end)}
                </span>
              </span>
            </>
          ) : (
            <span className="h-full mt-1 block items-center text-left appearance-none w-full px-1 placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
              No bookings in sight
            </span>
          )}
        </div>
      </div>
      <br />

      {/* create episode form */}
      <div className="text-center">
        <label className="pb-2">
          <span className={`text-[20px] ${styles.logoText} font-bold`}>
            {" "}
            Episode Services <span className="text-gray-800"></span>
          </span>{" "}
        </label>
      </div>

      <form onSubmit={handleSubmit}>
        <div>
          <div className="mt-3 rounded-lg border border-gray-300 p-4">
            <div className="px-2 mt-3 relative">
              <label className="appearance-none block h-[35px] ">
                Doing <span className="text-red-500">*</span>
              </label>
              <select
                className="block w-full px-3 h-[25px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                value={JSON.stringify(doing)}
                onChange={(e) => {
                  const selectedDoing =
                    e.target.value !== "Choose a Doing"
                      ? JSON.parse(e.target.value)
                      : null;
                  setDoing(selectedDoing);
                }}
              >
                {doingsData &&
                  doingsData.map((i) => (
                    <option key={i.title} value={JSON.stringify(i)}>
                      <div className="w-full flex items-start-py-3">
                        <h1>{i.title}</h1>
                      </div>
                    </option>
                  ))}
              </select>
            </div>
            <div className="flex w-full flex-col sm:flex-row">
              <div className="w-full sm:w-[50%] px-2 mt-3">
                <label className="appearance-none block h-[35px] text-center">
                  Start Date<span className="text-red-500">*</span>
                </label>
                <input
                  type="datetime-local"
                  name="startDateTime"
                  id="service-start-date-time"
                  // min={today}
                  value={serviceStartDate}
                  onFocus={() => setShowCalendar(true)}
                  onChange={(e) => setServiceStartDate(e.target.value)}
                  className="appearance-none block w-full px-3 h-[25px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
              <div className="w-full sm:w-[50%] px-2 mt-3 sm:ml-2">
                <label className="appearance-none block h-[35px] text-center ">
                  End Date<span className="text-red-500">*</span>
                </label>
                <input
                  type="datetime-local"
                  name="endDateTime"
                  id="service-end-date-time"
                  // min={today}
                  value={serviceEndDate}
                  onFocus={() => setShowCalendar(true)}
                  onChange={(e) => setServiceEndDate(e.target.value)}
                  className="appearance-none block w-full px-3 h-[25px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="px-2 mt-3 relative">
              <label className="appearance-none block h-[35px] ">
                Team <span className="text-red-500"></span>
              </label>
              <select
                className="block w-full px-3 h-[25px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                value={team ? team.name : "Choose a Team"}
                onChange={(e) => {
                  const selectedTeam = teams.find(
                    (t) => t.name === e.target.value
                  );
                  setTeam(selectedTeam || null);
                }}
              >
                <option value="Choose a Team">Choose a Team</option>
                {teams &&
                  teams.map((i) => (
                    <option key={i.name} value={i.name}>
                      <div className="w-full flex items-start-py-3">
                        <h1>{i.name}</h1>
                      </div>
                    </option>
                  ))}
              </select>
            </div>
            <div className="px-2 mt-3">
              <label className="appearance-none block h-[35px] ">
                Comments
              </label>
              <textarea
                cols="30"
                required
                rows="5"
                type="text"
                name="comments"
                value={comments}
                className="appearance-none block w-full pt-2 px-3 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                onChange={(e) => setComments(e.target.value)}
                placeholder="Enter your episode service comments..."
              ></textarea>
            </div>
          </div>
          <div className="px-2 flex mt-1 justify-end">
            <label className="pb-2 items-right text-right">
              <span className={`text-[20px] ${styles.logoText} font-bold`}>
                {" "}
                Add Service <span className="text-gray-800"></span>
              </span>{" "}
            </label>
            <label>
              <AiOutlinePlusCircle
                size={30}
                className="ml-1 mt-2 text-right "
                color="#1B1E23"
                onClick={() => handleServiceChange()}
              />
            </label>
          </div>
          <br />
          <div>
            <input
              type="submit"
              value="Create" //esto lo eliminaría, y haría algo dinámico sin create... quizás solo algo para confirmar cada modificación... o eventualmente eliminar el episodio
              className="mt-2 cursor-pointer appearance-none text-center block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>
          <div className="items-center">
            <Board data={data} draggable></Board>
          </div>
          <br />
          <br />
        </div>

        <br />
        <div className="px-2 mt-2">
          <button
            type="submit"
            className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateEpisode;
