import React, { useEffect } from 'react'
import Signup from "../components/Signup/Signup"
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';


const SignupPage = () => {
  const navigate = useNavigate();
  const { isUser } = useSelector ((state) => state.user);

useEffect (() =>{

  if (isUser === true){
    navigate("/");
  }
},[])

  return (
    <div>
        <Signup />
    </div>
  )
}

export default SignupPage