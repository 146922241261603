import React, { useEffect, useState } from "react";
import {
  AiOutlineDelete,
  AiOutlinePlusCircle,
  AiTwotoneEdit,
  AiOutlineFieldTime,
  AiOutlineWhatsApp,
} from "react-icons/ai";
import {
  GiExitDoor,
  GiEntryDoor,
  GiVacuumCleaner,
  GiDiplodocus,
} from "react-icons/gi";
import { LiaPeopleCarrySolid, LiaWrenchSolid } from "react-icons/lia";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import styles from "../../styles/styles";
import { toast } from "react-toastify";
import { doingsData } from "../../static/data";
import { backend_url } from "../../server";

import {
  getEpisodeHost,
  updateStatusService,
  deleteEpisode,
} from "../../redux/actions/episode";

import {
  createService,
  getServicesBooking,
  deleteService,
} from "../../redux/actions/service.js";

import { getBookingHost } from "../../redux/actions/ical";
import { getAllTeamsHost } from "../../redux/actions/team";
import { messageCreate } from "../../redux/actions/message";

//Debería hacer update sobre bookings sobre todo por las modificaciones en checkin y checkout
//import { updateEpisode } from "../../redux/actions/episode";
import { DateTime } from "luxon";
import Board from "react-trello";

function adjustTime(dateTimeString, defaultTime) {
  const adjustedDateTime = new Date(dateTimeString);
  if (!isNaN(adjustedDateTime.getTime())) {
    const [hours, minutes] = defaultTime.split(":");
    const isPM = defaultTime.includes("PM");
    let adjustedHours = parseInt(hours, 10);
    if (isPM && adjustedHours !== 12) {
      adjustedHours += 12;
    } else if (!isPM && adjustedHours === 12) {
      adjustedHours = 0;
    }
    adjustedDateTime.setHours(adjustedHours, parseInt(minutes, 10), 0, 0);
    adjustedDateTime.setDate(adjustedDateTime.getDate()); // correct null
  }
  return adjustedDateTime.toISOString();
}

const calculateTimeRemaining = (startDate) => {
  const now = new Date();
  const startDateObj = new Date(startDate);
  if (startDateObj < now) {
    return "0m";
  }
  const timeDifference = startDateObj - now;
  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
  let result = "";
  if (days !== 0) {
    result += `${days}d `;
  }
  if (hours !== 0) {
    result += `${hours}h `;
  }
  result += `${minutes}m`;
  return result.trim(); // Elimina espacios en blanco adicionales
};

const calculateTimeDiference = (startDate, endDate) => {
  const startDateObj = new Date(startDate);
  const endDateObj = new Date(endDate);
  if (startDateObj > endDateObj) {
    return "0m";
  }
  const timeDifference = endDateObj - startDateObj;
  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
  let result = "";
  if (days !== 0) {
    result += `${days}d `;
  }
  if (hours !== 0) {
    result += `${hours}h `;
  }
  result += `${minutes}m`;
  return result.trim(); // Elimina espacios en blanco adicionales
};

const EpisodeDetails = () => {
  const { host } = useSelector((state) => state.host);
  const { bookingHost } = useSelector((state) => state.icals);
  const { placesHost } = useSelector((state) => state.places);

  const { teamsHost } = useSelector((state) => state.teams);
  const { servicesBooking, success, error } = useSelector(
    (state) => state.services
  );

  const hostId = host._id;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showCalendar, setShowCalendar] = useState(false);
  let [startDate, setStartDate] = useState(null);
  let [endDate, setEndDate] = useState(null);
  let [status] = useState("");
  let [bookingId] = useState("");
  let [placeId] = useState("");
  let [place] = useState([]);
  let [placeName] = useState("");
  let [defaultCheckIn] = useState(null);
  let [defaultCheckOut] = useState(null);
  let [defaultDurationCleaning] = useState(null);
  let [defaultDurationCheckOut] = useState(null);
  let [defaultDurationCheckIn] = useState(null);
  let [active, setActive] = useState(0);

  const [teams, setTeams] = useState("");

  // const [services, setServices] = useState([]);
  const [doing, setDoing] = useState("");
  const [serviceStartDate, setServiceStartDate] = useState(null);
  const [serviceEndDate, setServiceEndDate] = useState(null);
  const [team, setTeam] = useState("");
  const [comments, setComments] = useState("");

  const id = useParams();
  useEffect(() => {
    if (id) {
      dispatch(getBookingHost(id.id));
      dispatch(getAllTeamsHost(hostId));
    }
  }, [id, hostId, dispatch]);

  console.log("Booking Host es: ", bookingHost);

  useEffect(() => {
    if (id) {
      dispatch(getServicesBooking(id.id));
    }
  }, [id, dispatch]);

  if (bookingHost) {
    bookingId = bookingHost._id;
    status = bookingHost.status;
    placeId = bookingHost.placeId;
    place = placesHost.find((place) => place._id === placeId);
    if (place) {
      placeName = place && place.name;
      defaultDurationCleaning = place.defaultDurationCleaning;
      defaultDurationCheckOut = place.defaultDurationCheckOut;
      defaultDurationCheckIn = place.defaultDurationCheckIn;
      defaultCheckIn = place.defaultCheckIn;
      defaultCheckOut = place.defaultCheckOut;
    } else {
      defaultDurationCleaning = 0;
      defaultDurationCheckOut = 0;
      defaultDurationCheckIn = 0;
      defaultCheckOut = "10:00 AM";
      defaultCheckIn = "15:00 PM";
    }
    startDate = adjustTime(new Date(bookingHost.start), defaultCheckIn);
    endDate = adjustTime(new Date(bookingHost.end), defaultCheckOut);
  }

  //Seting Teams
  useEffect(() => {
    if (teamsHost && teamsHost.length > 0) {
      setTeams(teamsHost);
    }
  }, [teamsHost]);

  console.log("Equipos teamHost: ", teamsHost);

  //Click Add Service
  const handleServiceChange = async () => {
    let doingArray;
    if (doing) {
      doingArray = JSON.parse(doing);
    }
    if (serviceStartDate && serviceEndDate) {
      const newService = {
        doing: doingArray.title,
        reactIcon: doingArray.reactIcon,
        startDate: serviceStartDate,
        endDate: serviceEndDate,
        comments: comments,
        teamId: team._id,
        bookingId: bookingId,
        placeId: placeId,
        hostId: hostId,
      };
      await dispatch(createService(newService));
      dispatch(getServicesBooking(bookingId));
    }
  };

  const handleMessage = async (id) => {
    const confirmed = window.confirm(
      "You are going to request the service via Whatsapp, do you want to continue?"
    );
    if (confirmed) {
      const serviceId = id; // es local bookingId
      const serviceData = servicesBooking.find(
        (service) => service._id === serviceId
      );
      if (serviceData) {
        const teamName = serviceData.team.name;
        const hostName = host.name;
        const hostPhone = host.phoneNumber;
        const placeAddress =
          place.streetAddress + " " + place.floor + " " + place.doorNumber;
        const serviceType = serviceData.doing;
        const serviceDate = formatDateMessage(
          serviceData.startDate,
          serviceData.endDate
        );
        const serviceComments = serviceData.comments;
        const message =
          "Hi " +
          teamName +
          ", I hope you are doing well, \n" +
          hostName +
          " is requesting: " +
          " \n*" +
          serviceType +
          "* \n" +
          "Where: " +
          placeAddress +
          " \n" +
          "When: " +
          serviceDate +
          " \n" +
          serviceComments +
          " \n" +
          "Send *1* to confirm or *2* to reject." +
          " \n" +
          "If you prefer to coordinate something different please contact your host" +
          "(+" +
          "1234567890"+
          // hostPhone +
          ")";
        const newForm = {
          phoneNumber: serviceData.team.phoneNumber,
          message: message,
          serviceId: serviceId,
          teamId: serviceData.team._id,
          hostId: hostId,
        };
        console.log("New Message: ", newForm);
        await dispatch(messageCreate(newForm));
      } else {
        console.log("No se encontró el servicio con el ID proporcionado.");
      }
    } else {
      console.log("El usuario canceló el envío del mensaje.");
    }
  };

  const handleEdit = async (id) => {
    const serviceId = id;
    navigate(`/dashboard-service/${serviceId}`);
  };

  const handleStatus = async (id, newStatus) => {
    const newStatusService = {
      serviceId: id,
      status: newStatus,
      bookingId: bookingId,
      hostId: hostId,
    };
    await dispatch(updateStatusService(newStatusService));
    dispatch(getBookingHost(bookingId));
  };

  const handleDelete = async (id) => {
    await dispatch(deleteService(id));
    dispatch(getServicesBooking(id));
  };

  const IconComponent = ({ children, ...props }) => {
    const iconMapping = {
      GiEntryDoor: GiEntryDoor,
      GiExitDoor: GiExitDoor,
      GiVacuumCleaner: GiVacuumCleaner,
      LiaPeopleCarrySolid: LiaPeopleCarrySolid,
      LiaWrenchSolid: LiaWrenchSolid,
      GiDiplodocus: GiDiplodocus,
    };
    const Icon = iconMapping[children];
    if (!Icon) {
      console.error(`IconComponent: Icon '${children}' not found`);
      return null;
    }
    return <Icon {...props} />;
  };

  const data = {
    lanes: [
      {
        id: "todo",
        title: "To Do", //la idea es que el lane indique el status
        style: { backgroundColor: "rgb(136,226,244)" },
        // style: { backgroundColor: 'rgb(80,124,203)' }, //alternativa fuerte
        cardStyle: { backgroundColor: "rgb(235,235,235)" },
        cards: servicesBooking
          ? servicesBooking
              .filter((service) => new Date(service.startDate) > new Date())
              .map((service) => {
                let cardBackgroundColor;
                switch (service.status) {
                  case "Added":
                    cardBackgroundColor = "rgb(102,204,255)";
                    break;
                  case "Confirmed":
                    cardBackgroundColor = "rgb(153,204,235)";
                    break;
                  case "Rejected":
                    cardBackgroundColor = "rgb(255,204,215)";
                    break;
                  default:
                    cardBackgroundColor = "inherit";
                }
                return {
                  id: service.id,
                  title: (
                    <div className="flex items-center justify-start">
                      {" "}
                      {service.doing}
                      <IconComponent
                        size={30}
                        className="ml-1 mr-2 text-right"
                        color="#1B1E23"
                      >
                        {service.reactIcon}
                      </IconComponent>{" "}
                    </div>
                  ),
                  label: (
                    <div className="h-full flex items-center justify-end">
                      <span className="text-sm">{service.team.name}</span>
                    </div>
                  ),
                  description: (
                    <div className="">
                      <div className="flex">
                        <div className="mr-4">
                          {service.team.avatar && (
                            <img
                              src={`${backend_url}/${service.team.avatar}`}
                              alt="Team Avatar"
                              className="w-[50px] h-[50px] rounded-full object-cover"
                            />
                          )}
                        </div>
                        <div className="">
                          <p>Start Date: {service.startDate}</p>
                          <p>End Date: {service.endDate}</p>
                          <p>Place: {placeName}</p>
                        </div>
                      </div>
                      <div className="block mb-2">
                        <p>Comments: {service.comments}</p>
                      </div>
                      <div>
                        <hr />
                        <div>
                          <div className="flex items-center justify-between">
                            <div className="flex justify-start">
                              <AiOutlineFieldTime
                                size={20}
                                className="ml-1 mr-2 mt-2 text-left "
                                color="#1B1E23"
                              />
                              {service.startDate &&
                              Date.now() <
                                new Date(service.startDate).getTime() ? (
                                <span className="ml-1 mt-2 text-left">{`${calculateTimeRemaining(
                                  service.startDate
                                )} left`}</span>
                              ) : (
                                <span className="ml-1 mt-2 text-left text-red-500">
                                  0 m left
                                </span>
                              )}
                            </div>
                            <div className="flex justify-end">
                              <AiOutlineWhatsApp
                                size={20}
                                className="ml-1 mr-2 mt-2 text-right"
                                color="#25D366"
                                strokeWidth={20}
                                onClick={() => handleMessage(`${service._id}`)}
                              />
                              <AiTwotoneEdit
                                size={20}
                                className="ml-1 mr-2 mt-2 text-right"
                                color="#1B1E23"
                                onClick={() => handleEdit(`${service._id}`)}
                              />
                              <AiOutlineDelete
                                size={20}
                                className="ml-1 mt-2 text-right justify-end"
                                color="#1B1E23"
                                onClick={() => handleDelete(`${service._id}`)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ),
                  style: {
                    backgroundColor: cardBackgroundColor,
                    borderRadius: 10,
                    boxShadow: "0 0 8px 1px rgba(0, 0, 0, 0.2)",
                    marginBottom: 15,
                    overflow: "hidden",
                  },
                };
              })
          : [],
      },
      {
        id: "inprogress",
        title: "In Progress", //la idea es que el lane indique el status
        style: { backgroundColor: "rgb(244,230,136)" },
        // style: { backgroundColor: 'rgb(248,180,68)' },
        cardStyle: {
          backgroundColor: "rgb(250,250,250)",
          borderRadius: 10,
          boxShadow: "0 0 8px 1px rgba(0, 0, 0, 0.2)",
          marginBottom: 15,
          overflow: "hidden",
        },
        cards: servicesBooking
          ? servicesBooking
              .filter((service) => {
                return (
                  new Date(service.startDate) <= new Date() &&
                  new Date(service.endDate) >= new Date()
                );
              })
              .map((service) => ({
                id: service.id,
                title: (
                  <div className="flex items-center justify-start">
                    {" "}
                    {service.doing}
                    <IconComponent
                      size={30}
                      className="ml-1 mr-2 text-right"
                      color="#1B1E23"
                    >
                      {service.reactIcon}
                    </IconComponent>{" "}
                  </div>
                ),
                label: (
                  <div className="h-full flex items-center justify-end">
                    <span className="text-sm">{service.team.name}</span>
                  </div>
                ),
                description: (
                  <div className="">
                    <div className="flex">
                      <div className="mr-4">
                        {service.team.avatar && (
                          <img
                            src={`${backend_url}${service.team.avatar}`}
                            alt="Team Avatar"
                            className="w-[50px] h-[50px] rounded-full object-cover"
                          />
                        )}
                      </div>
                      <div className="">
                        <p>Start Date: {service.startDate}</p>
                        <p>End Date: {service.endDate}</p>
                        <p>Place: {placeName}</p>
                      </div>
                    </div>
                    <div className="block mb-2">
                      <p>Comments: {service.comments}</p>
                    </div>
                    <div>
                      <hr />
                      <div>
                        <div className="flex items-center justify-between">
                          <div className="flex justify-start">
                            <AiOutlineFieldTime
                              size={20}
                              className="ml-1 mr-2 mt-2 text-left "
                              color="#1B1E23"
                            />
                            {service.startDate &&
                            Date.now() <
                              new Date(service.startDate).getTime() ? (
                              <span className="ml-1 mt-2 text-left">{`${calculateTimeRemaining(
                                service.startDate
                              )} left`}</span>
                            ) : (
                              <span className="ml-1 mt-2 text-left text-red-500">
                                0 m left
                              </span>
                            )}
                          </div>
                          <div className="flex justify-end">
                            <AiOutlineWhatsApp
                              size={20}
                              className="ml-1 mr-2 mt-2 text-right"
                              color="#25D366"
                              strokeWidth={20}
                              onClick={() => handleMessage(`${service._id}`)}
                            />
                            <AiTwotoneEdit
                              size={20}
                              className="ml-1 mr-2 mt-2 text-right"
                              color="#1B1E23"
                              onClick={() => handleEdit(`${service._id}`)}
                            />
                            <AiOutlineDelete
                              size={20}
                              className="ml-1 mt-2 text-right justify-end"
                              color="#1B1E23"
                              onClick={() => handleDelete(`${service._id}`)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ),
              }))
          : [],
      },
      {
        id: "done",
        title: "Done", //la idea es que el lane indique el status
        // style: { backgroundColor: 'rgb(136,244,142)' },
        style: { backgroundColor: "rgb(78,195,89)" },
        cardStyle: {
          backgroundColor: "rgb(205,205,205)",
          borderRadius: 10,
          boxShadow: "0 0 8px 1px rgba(0, 0, 0, 0.2)",
          marginBottom: 15,
          overflow: "hidden",
        },
        cards: servicesBooking
          ? servicesBooking
              .filter((service) => new Date(service.endDate) < new Date())
              .map((service) => ({
                id: service.id,
                title: (
                  <div className="flex items-center justify-start">
                    {" "}
                    {service.doing}
                    <IconComponent
                      size={30}
                      className="ml-1 mr-2 text-right"
                      color="#1B1E23"
                    >
                      {service.reactIcon}
                    </IconComponent>{" "}
                  </div>
                ),
                label: (
                  <div className="h-full flex items-center justify-end">
                    <span className="text-sm">{service.team.name}</span>
                  </div>
                ),
                description: (
                  <div className="">
                    <div className="flex">
                      <div className="mr-4">
                        {service.team.avatar && (
                          <img
                            src={`${backend_url}${service.team.avatar}`}
                            alt="Team Avatar"
                            className="w-[50px] h-[50px] rounded-full object-cover"
                          />
                        )}
                      </div>
                      <div className="">
                        <p>Start Date: {service.startDate}</p>
                        <p>End Date: {service.endDate}</p>
                        <p>Place: {placeName}</p>
                      </div>
                    </div>
                    <div className="block mb-2">
                      <p>Comments: {service.comments}</p>
                    </div>
                    <div>
                      <hr />
                      <div>
                        <div className="flex items-center justify-between">
                          <div className="flex justify-start">
                            <AiOutlineFieldTime
                              size={20}
                              className="ml-1 mr-2 mt-2 text-left "
                              color="#1B1E23"
                            />
                            {service.startDate &&
                            Date.now() <
                              new Date(service.startDate).getTime() ? (
                              <span className="ml-1 mt-2 text-left">{`${calculateTimeRemaining(
                                service.startDate
                              )} left`}</span>
                            ) : (
                              <span className="ml-1 mt-2 text-left text-red-500">
                                0 m left
                              </span>
                            )}
                          </div>
                          <div className="flex justify-end">
                            <AiOutlineWhatsApp
                              size={20}
                              className="ml-1 mr-2 mt-2 text-right"
                              color="#25D366"
                              strokeWidth={20}
                              onClick={() => handleMessage(`${service._id}`)}
                            />
                            <AiTwotoneEdit
                              size={20}
                              className="ml-1 mr-2 mt-2 text-right"
                              color="#1B1E23"
                              onClick={() => handleEdit(`${service._id}`)}
                            />
                            <AiOutlineDelete
                              size={20}
                              className="ml-1 mt-2 text-right justify-end"
                              color="#1B1E23"
                              onClick={() => handleDelete(`${service._id}`)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ),
              }))
          : [],
      },
    ],
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    if (success) {
      toast.success("Service added successfully!");
      // navigate("/dashboard-calendars");
      // window.location.reload();
    }
  }, [dispatch, error, success]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedBooking = {
      startDate,
      endDate,
      status,
      bookingId,
    };
    // dispatch(updateBooking(updatedBooking));
  };

  //Handles Click
  const handleClick1 = () => {
    setActive(1);
    const selectedDoing = doingsData.find((d) => d.title === "Check In");
    setDoing(JSON.stringify(selectedDoing));
  };

  const handleClick2 = () => {
    setActive(2);
    const selectedDoing = doingsData.find((d) => d.title === "Check Out");
    setDoing(JSON.stringify(selectedDoing));
  };

  const handleClick3 = () => {
    setActive(3);
    const selectedDoing = doingsData.find((d) => d.title === "Cleaning");
    setDoing(JSON.stringify(selectedDoing));
  };

  const handleClick4 = () => {
    setActive(4);
    const selectedDoing = doingsData.find((d) => d.title === "Carry");
    setDoing(JSON.stringify(selectedDoing));
  };

  const handleClick5 = () => {
    setActive(5);
    const selectedDoing = doingsData.find((d) => d.title === "Maintenance");
    setDoing(JSON.stringify(selectedDoing));
  };

  const handleClick6 = () => {
    setActive(6);
    const selectedDoing = doingsData.find((d) => d.title === "Other");
    setDoing(JSON.stringify(selectedDoing));
  };

  useEffect(() => {
    const selectedDoing = doing ? JSON.parse(doing) : null;
    if (selectedDoing) {
      if (selectedDoing.title === "Check In") {
        setServiceStartDate(
          DateTime.fromISO(startDate).toFormat("yyyy-MM-dd'T'HH:mm")
        );
        setServiceEndDate(
          DateTime.fromISO(startDate)
            .plus({
              minutes: defaultDurationCheckIn,
            })
            .toFormat("yyyy-MM-dd'T'HH:mm")
        );
        setActive(1);
      } else if (selectedDoing.title === "Check Out") {
        setServiceStartDate(
          DateTime.fromISO(endDate).toFormat("yyyy-MM-dd'T'HH:mm")
        );
        //adjustTime(new Date(bookingHost.end), defaultCheckOut),
        setServiceEndDate(
          DateTime.fromISO(endDate)
            .plus({
              minutes: defaultDurationCheckOut,
            })
            .toFormat("yyyy-MM-dd'T'HH:mm")
        );
        setActive(2);
      } else if (selectedDoing.title === "Cleaning") {
        setServiceStartDate(
          DateTime.fromISO(endDate)
            .plus({
              minutes: defaultDurationCheckOut,
            })
            .toFormat("yyyy-MM-dd'T'HH:mm")
        );
        setServiceEndDate(
          DateTime.fromISO(endDate)
            .plus({
              minutes: defaultDurationCheckOut + defaultDurationCleaning,
            })
            .toFormat("yyyy-MM-dd'T'HH:mm")
        );
        setActive(3);
      } else if (selectedDoing.title === "Carry") {
        setServiceStartDate(DateTime.local().toFormat("yyyy-MM-dd'T'HH:mm"));
        setServiceEndDate(DateTime.local().toFormat("yyyy-MM-dd'T'HH:mm"));
        setActive(4);
      } else if (selectedDoing.title === "Maintenance") {
        setServiceStartDate(DateTime.local().toFormat("yyyy-MM-dd'T'HH:mm"));
        setServiceEndDate(DateTime.local().toFormat("yyyy-MM-dd'T'HH:mm"));
        setActive(5);
      } else if (selectedDoing.title === "Other") {
        setServiceStartDate(DateTime.local().toFormat("yyyy-MM-dd'T'HH:mm"));
        setServiceEndDate(DateTime.local().toFormat("yyyy-MM-dd'T'HH:mm"));
        setActive(6);
      } else {
        setServiceStartDate(DateTime.local().toFormat("yyyy-MM-dd'T'HH:mm"));
        setServiceEndDate(DateTime.local().toFormat("yyyy-MM-dd'T'HH:mm"));
      }
    }
  }, [doing]);

  const handleSelectChange = (e) => {
    setDoing(e.target.value);
  };

  const formatDate = (dateString) => {
    const formattedDate = DateTime.fromISO(dateString, { zone: "utc" })
      .setZone("local")
      // .toFormat("MMM-dd-yyyy hh:mm a");
      .toFormat("DDDD");
    return formattedDate;
  };

  const formatDateMessage = (dateString1, dateString2) => {
    const date1 = DateTime.fromISO(dateString1, { zone: "utc" }).setZone(
      "local"
    );
    const date2 = DateTime.fromISO(dateString2, { zone: "utc" }).setZone(
      "local"
    );
    const formattedDate1 = date1.toFormat("'On' EEEE, MMM dd 'at' hh:mm a");
    const formattedDate2 = date2.toFormat("EEEE, MMM dd 'at' hh:mm a");
    const formattedDate =
      date1.toISODate() !== date2.toISODate()
        ? `${formattedDate1} to ${formattedDate2}`
        : `${formattedDate1} to ${date2.toFormat("hh:mm a")}`;
    return formattedDate;
  };

  return (
    <div className="w-[90%] 1150px:w-[50%] bg-white  shadow h-[80vh] rounded-[4px] p-3 overflow-y-scroll">
      <h5 className="mt-2 text-[30px] font-Poppins text-center">
        {`${placeName}`} Booking
      </h5>

      <div className="mt-2 appearance-none block w-full bg-violet-100 px-3 border border-gray-300 rounded-[5px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
        {" "}
        <label className="pb-2">
          <span className={`text-[20px] ${styles.logoText} font-semibold`}>
            {" "}
            {bookingHost && bookingHost.status === "Deprecated" ? (
              <span style={{ color: "red" }}>
                Attention! Obsolete Reservation
              </span>
            ) : (
              "Booking"
            )}{" "}
          </span>
        </label>
        <div className="pb-2">
          {bookingHost ? (
            <>
              <span className="h-full mt-1 block items-center text-left appearance-none w-full px-1 placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                {bookingHost.summary}
              </span>
              <span className="h-full mt-1 block items-center text-left appearance-none w-full px-1 placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                From{" "}
                <span className={`font-bold`}> {formatDate(startDate)}</span> to{" "}
                <span className={`font-bold`}>{formatDate(endDate)}</span>
                <span className="text-red-500"></span>
              </span>
            </>
          ) : (
            <span className="h-full mt-1 block items-center text-left appearance-none w-full px-1 placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
              No bookings in sight
            </span>
          )}
        </div>
      </div>
      {/* create episode form */}
      <form onSubmit={handleSubmit}>
        <div>
          <label className="pb-2">
            {/* `New Episode for ${place.placeName}`<span className="text-red-500">*</span> */}
          </label>
        </div>
        {/* Add Service form */}
        <div className="text-center mt-3">
          <label className="pb-2">
            <span className={`text-[20px] ${styles.logoText} font-bold`}>
              {" "}
              Booking Episode Services <span className="text-gray-800"></span>
            </span>{" "}
          </label>
        </div>
        <div className="mt-1 rounded-lg border border-gray-300 p-4">
          <div className="px-2 mt-2 relative">
            <label className="appearance-none block h-[35px] ">
              Doing <span className="text-red-500">*</span>
            </label>
            <div className=" flex">
              <div className="w-full flex flex-col items-center p-2">
                <GiExitDoor
                  size={30}
                  color={`${active === 1 ? "crimson" : "#555"}`}
                  onClick={handleClick1}
                />
                <h5
                  className={`text-[10px] font-[500] ${
                    active === 1 ? "text-red-800" : "text-cyan-800"
                  }`}
                >
                  {"Check in"}
                </h5>
              </div>
              <div className="w-full flex flex-col items-center p-2">
                <GiEntryDoor
                  size={30}
                  color={`${active === 2 ? "crimson" : "#555"}`}
                  onClick={handleClick2}
                />
                <h5
                  className={`text-[10px] font-[500] ${
                    active === 2 ? "text-red-800" : "text-cyan-800"
                  }`}
                >
                  {"Check out"}
                </h5>
              </div>
              <div className="w-full flex flex-col items-center p-2">
                <GiVacuumCleaner
                  size={30}
                  color={`${active === 3 ? "crimson" : "#555"}`}
                  onClick={handleClick3}
                />
                <h5
                  className={`text-[10px] font-[500] ${
                    active === 3 ? "text-red-800" : "text-cyan-800"
                  }`}
                >
                  {"Cleaning"}
                </h5>
              </div>
              <div className="w-full flex flex-col items-center p-2">
                <LiaPeopleCarrySolid
                  size={30}
                  color={`${active === 4 ? "crimson" : "#555"}`}
                  onClick={handleClick4}
                />
                <h5
                  className={`text-[10px] font-[500] ${
                    active === 4 ? "text-red-800" : "text-cyan-800"
                  }`}
                >
                  {"Carry"}
                </h5>
              </div>
              <div className="w-full flex flex-col items-center p-2">
                <LiaWrenchSolid
                  size={30}
                  color={`${active === 5 ? "crimson" : "#555"}`}
                  onClick={handleClick5}
                />
                <h5
                  className={`text-[10px] font-[500] ${
                    active === 5 ? "text-red-800" : "text-cyan-800"
                  }`}
                >
                  {"Maintenance"}
                </h5>
              </div>
              <div className="w-full flex flex-col items-center p-2">
                <GiDiplodocus
                  size={30}
                  color={`${active === 6 ? "crimson" : "#555"}`}
                  onClick={handleClick6}
                />
                <h5
                  className={`text-[10px] font-[500] ${
                    active === 6 ? "text-red-800" : "text-cyan-800"
                  }`}
                >
                  {"Other"}
                </h5>
              </div>
            </div>
            <select
              className="block w-full px-3 h-[25px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              value={doing}
              onChange={handleSelectChange}
            >
              {doingsData &&
                doingsData.map((i) => (
                  <option key={i.title} value={JSON.stringify(i)}>
                    <div className="w-full flex items-start-py-3">
                      <h1>{i.title}</h1>
                    </div>
                  </option>
                ))}
            </select>
          </div>
          <div className="flex w-full flex-col sm:flex-row">
            <div className="w-full sm:w-[50%] px-2 mt-2">
              <label className="appearance-none block h-[35px] text-center">
                Start<span className="text-red-500">*</span>
              </label>
              <input
                type="datetime-local"
                name="startDateTime"
                id="service-start-date-time"
                // min={today}
                value={serviceStartDate}
                onFocus={() => setShowCalendar(true)}
                onChange={(e) => setServiceStartDate(e.target.value)}
                step="300"
                className="appearance-none block w-full px-3 h-[25px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            <div className="w-full sm:w-[50%] px-2 mt-2 sm:ml-2">
              <label className="appearance-none block h-[35px] text-center ">
                End<span className="text-red-500">*</span>
              </label>
              <input
                type="datetime-local"
                name="endDateTime"
                id="service-end-date-time"
                // min={today}
                value={serviceEndDate}
                onFocus={() => setShowCalendar(true)}
                onChange={(e) => setServiceEndDate(e.target.value)}
                step="300"
                className="appearance-none block w-full px-3 h-[25px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            <div className="w-full sm:w-[50%] px-2 mt-11 sm:ml-2 text-center flex">
              <label className="appearance-none block h-[35px] text-sm">
                Duration:
                {serviceStartDate && serviceEndDate ? (
                  <span className="ml-1 mt-1 text-xs">{`${calculateTimeDiference(
                    serviceStartDate,
                    serviceEndDate
                  )}`}</span>
                ) : (
                  <span className="ml-1 mt-2 text-red-500">0 m</span>
                )}
              </label>
            </div>
          </div>
          <div className="px-2 mt-2 relative">
            <label className="appearance-none block h-[35px] ">
              Team <span className="text-red-500"></span>
            </label>
            <select
              className="block w-full px-3 h-[25px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              value={team ? team.name : "Choose a Team"}
              onChange={(e) => {
                const selectedTeam = teams.find(
                  (t) => t.name === e.target.value
                );
                setTeam(selectedTeam || null);
              }}
            >
              <option value="Choose a Team">Choose a Team</option>
              {teams &&
                teams.map((i) => (
                  <option key={i.name} value={i.name}>
                    <div className="w-full flex items-start-py-3">
                      <h1>{i.name}</h1>
                    </div>
                  </option>
                ))}
            </select>
          </div>
          <div className="px-2 mt-2">
            <label className="appearance-none block h-[35px] ">Comments</label>
            <textarea
              cols="30"
              required
              rows="5"
              type="text"
              name="comments"
              value={comments}
              className="appearance-none block w-full pt-2 px-3 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              onChange={(e) => setComments(e.target.value)}
              placeholder="Enter your episode service comments..."
            ></textarea>
          </div>
          <div className="px-2 flex mt-1 justify-center">
            <label className="pb-2  mt-2 items-right text-right">
              <span className={`text-[20px] text-gray-800 font-bold`}>
                {" "}
                Add Service <span className="text-gray-800"></span>
              </span>{" "}
            </label>
            <label>
              <AiOutlinePlusCircle
                size={35}
                className="ml-1 mt-2 text-right "
                color="#1B1E23"
                onClick={() => handleServiceChange()}
              />
            </label>
          </div>
        </div>
        <br />
      </form>
      <div className="px-2 flex mt-1 justify-center">
        <label className="pb-2 items-right text-right">
          <span className={`text-[15px] font-semibold`}>
            {" "}
            Services Dashboard<span className="text-gray-800"></span>
          </span>{" "}
        </label>
      </div>
      <div className="justify-center flex bg-[#9db9be]">
        <Board
          data={data}
          style={{ backgroundColor: "rgb(210,210,210)" }}
          draggable
        ></Board>
      </div>
      <br />
    </div>
  );
};

export default EpisodeDetails;
