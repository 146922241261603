import React, { } from "react";
import DashboardHeader from "../../components/Host/Layout/DashboardHeader";
import DashboardSideBar from "../../components/Host/Layout/DashboardSideBar";
import CreateTeam from "../../components/Host/CreateTeam";

const HostCreateEventPage = () => {

  return (
    <div>
      <DashboardHeader />
      <div className="flex items-center justify-between w-full">
      <div className="w-[80px] 800px:w-[100px]">
          <DashboardSideBar active={7} />
        </div>
        <div className="w-full justify-center flex">
          <CreateTeam />
        </div>
      </div>
    </div>
  );
};

export default HostCreateEventPage;
