import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  service: null,
  services: null,
  updatedService: null,
  servicesHost: null,
  servicesBooking: null,
  isLoading: false,
  success: false,
  error: null,
};

export const serviceReducer = createReducer(initialState, {
  //createService
  serviceCreateRequest: (state) => {
    state.isLoading = true;
    state.success = false;
  },
  serviceCreateSuccess: (state, action) => {
    state.isLoading = false;
    state.service = action.payload;
    state.success = true;
  },
  serviceCreateFail: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.success = false;
  },

  // get all services of host
  getAllServicesHostRequest: (state) => {
    state.isLoading = true;
    state.error = null;
    state.success = false;
  },
  getAllServicesHostSuccess: (state, action) => {
    state.isLoading = false;
    state.servicesHost = action.payload;
  },
  getAllServicesHostFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  // get Services of a Booking
  getServicesBookingRequest: (state) => {
    state.isLoading = true;
    state.error = null;
    state.success = false;
  },
  getServicesBookingSuccess: (state, action) => {
    state.isLoading = false;
    state.servicesBooking = action.payload;
  },
  getServicesBookingFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  // get Service of a Host
  getServiceRequest: (state) => {
    state.isLoading = true;
    state.error = null;
  },
  getServiceSuccess: (state, action) => {
    state.isLoading = false;
    state.service = action.payload;
  },
  getServiceFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  //Update Service
  updateServiceRequest: (state) => {
    state.isLoading = true;
  },
  updateServiceSuccess: (state, action) => {
    state.isLoading = false;
    state.updatedService = action.payload;
    state.success = true;
  },
  updateServiceFail: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.success = false;
  },

  // Delete service of a host
  deleteServiceRequest: (state) => {
    state.isLoading = true;
  },
  deleteServiceSuccess: (state, action) => {
    state.isLoading = false;
    state.message = action.payload;
  },
  deleteServiceFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  clearErrors: (state) => {
    state.error = null;
  },
});
