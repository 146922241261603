import React, { useEffect } from "react";
import DashboardHeader from '../../components/Host/Layout/DashboardHeader'
import DashboardSideBar from '../../components/Host/Layout/DashboardSideBar'
import AllEpisodes from "../../components/Host/AllEpisodes";
import { getAllPlacesHost } from "../../redux/actions/place";
import { useDispatch, useSelector } from "react-redux";

const HostAllEpisodesPage = () => {

  const { host } = useSelector((state) => state.host);
  const hostId = host._id;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllPlacesHost(hostId));
  }, [hostId]);

  return (
    <div>
        <DashboardHeader />
        <div className="flex justify-between w-full">
        <div className="w-[80px] 800px:w-[100px]">
              <DashboardSideBar active={3} />
            </div>
            <div className="w-full justify-center flex">
                <AllEpisodes />
            </div>
          </div>
    </div>
  )
}

export default HostAllEpisodesPage;