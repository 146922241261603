import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styles from "../../../styles/styles";
import { MdCalendarMonth, MdGroupAdd, MdMessage } from "react-icons/md";
import { backend_url } from "../../../server";

const DashboardHeader = () => {
  const { host } = useSelector((state) => state.host);
  
  return (
    <div className="w-full h-[64px] bg-white shadow sticky top-0 left-0 z-30 flex items-center justify-between px-4">
      <div>
        <Link to="/dashboard">
          <h1 className={`{${styles.logoText}`}>ChecK 'N' CleaN</h1>
        </Link>
      </div>
      <div className="flex items-center">
        <div className="flex items-center mr-4">
          <Link to="/dashboard-calendars" className="800px:block hidden">
            <MdCalendarMonth
              color="#006060"
              size={30}
              className="mx-5 cursor-pointer"
            />
          </Link>
          <Link to="/dashboard-messages" className="800px:block hidden">
            <MdMessage
              color="#006060"
              size={30}
              className="mx-5 cursor-pointer"
            />
          </Link>
          <Link to="/dashboard-create-team" className="800px:block hidden">
            <MdGroupAdd
              color="#006060"
              size={30}
              className="mx-5 cursor-pointer"
            />
          </Link>
          {/* <Link to={`/host`}> */}
          <Link to={`/host/${host._id}`}>
             <img
              src={host.avatar.startsWith('http') ? host.avatar : `${backend_url}/${host.avatar}`}
              alt=""
              className="w-[50px] h-[50px] rounded-full object-cover"
            /> 
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DashboardHeader;
