import React from 'react'
import Header from "../components/Layout/Header.jsx"
import Footer from "../components/Layout/Footer"

const ServicesPage = () => {
  return (
    <div>
      <Header activeHeading={1} />
      <Services />
      <Footer />
    </div>
  );
};

const Services = () => {
  return (
    <div>ServicesPage</div>
  )
}

export default ServicesPage