import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../../styles/styles";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import { getAllPlacesHost } from "../../redux/actions/place";
import { createManualBooking } from "../../redux/actions/ical";
import { RxAvatar } from "react-icons/rx";
import PlaceCard from "../Route/GeneralCard/PlaceCard";
import { DateTime } from "luxon";

const CreateBooking = () => {
  const { host } = useSelector((state) => state.host);
  const { placesHost, isLoading } = useSelector((state) => state.places);
  const hostId = host._id;
  const { success, error } = useSelector((state) => state.icals);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let [guestName, setGuestName] = useState("");
  let [summary, setSummary] = useState("");
  let [status, setStatus] = useState("");
  const [description, setDescription] = useState("");
  let [link, setLink] = useState("");
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  let [TPUID, setTPUID] = useState("");
  let [platform, setPlatform] = useState("");
  let [episodes, setEpisodes] = useState([]);
  const [placeId, setPlaceId] = useState("");
  const [place, setPlace] = useState("");

  useEffect(() => {
    dispatch(getAllPlacesHost(hostId));
  }, [hostId, dispatch]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    if (success) {
      toast.success("Booking Created Successfully!");
      window.location.reload();
      // navigate("/dashboard-calendars");
    }
  }, [dispatch, error, success]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const placeId = place._id;
    if (guestName) {
      summary = "Reserved - " + guestName;
    }
    status = "Confirmed";
    TPUID = host._id + new Date().toISOString() + "@checknclean.com";
    platform = "ChecKNCleaN";
    link = "";
    if (
      place &&
      place !== "Choose a Place" //&&
      //   place.name !== "Choose a Place" &&
      //   place.name !== "" &&
      //   place.name !== null &&
      //   place.name !== undefined
    ) {
      const newForm = new FormData();
      newForm.append("summary", summary);
      newForm.append("status", status);
      newForm.append("description", description);
      newForm.append("link", link);
      newForm.append("start", start);
      newForm.append("end", end);
      newForm.append("TPUID", TPUID);
      newForm.append("platform", platform);
      newForm.append("placeId", placeId);
      newForm.append("place", place);

      newForm.append("hostId", hostId);
      dispatch(createManualBooking(newForm));
    }
  };

  return (
    <div className="w-[90%] 800px:w-[50%] bg-white  shadow h-[80vh] rounded-[4px] p-3 overflow-y-scroll">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        {/* <h1 className={`{${styles.logoText}`}>ChecK 'N' CleaN</h1> */}
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Create a Booking Manually
        </h2>
      </div>
      <form className="space-y-6 " onSubmit={handleSubmit}>
        <div className="px-2 mt-2">
          <label className="pb-2 font-semibold">
            Place<span className="text-red-500">*</span>
          </label>
          <select
            className="block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            value={place ? JSON.stringify(place) : "Choose a Place"}
            onChange={(e) => {
              const selectedPlace =
                e.target.value !== "Choose a Place"
                  ? JSON.parse(e.target.value)
                  : null;
              setPlace(selectedPlace);
            }}
          >
            <option value="Choose a Place">Choose a Place</option>
            {placesHost &&
              placesHost.map((i) => (
                <option key={i.name} value={JSON.stringify(i)}>
                  <div className="w-full flex items-start-py-3">
                    <h1>{i.name}</h1>
                  </div>
                </option>
              ))}
          </select>
        </div>
        {/* <br /> */}
        <div className="px-2 mt-2">
          <label className="pb-2 font-semibold">
            Booking Start Date Time<span className="text-red-500">*</span>
          </label>
          <input
            type="datetime-local"
            name="startTime"
            id="start-time"
            required
            value={
              start
                ? DateTime.fromISO(start).toFormat("yyyy-MM-dd'T'HH:mm")
                : ""
            }
            onChange={(e) => setStart(e.target.value)}
            className="appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            placeholder="Enter your episode start date..."
          />
        </div>
        {/* <br /> */}
        <div className="px-2 mt-2">
          <label className="pb-2 font-semibold">
            Booking End Date Time<span className="text-red-500">*</span>
          </label>
          <input
            type="datetime-local"
            name="endTime"
            id="end-time"
            required
            min={start}
            value={
              end ? DateTime.fromISO(end).toFormat("yyyy-MM-dd'T'HH:mm") : ""
            }
            onChange={(e) => setEnd(e.target.value)}
            className="appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            placeholder="Enter your episode start date..."
          />
        </div>
        <div className="px-2 mt-2">
          <label className="pb-2 font-semibold">
            Guest Name<span className="text-red-500">*</span>
          </label>
          <input
            cols="30"
            required
            rows="5"
            type="text"
            name="guestName"
            value={guestName}
            className="appearance-none block w-full pt-2 px-3 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setGuestName(e.target.value)}
            placeholder="Enter Guest Name..."
          />
        </div>
        <div className="px-2 mt-2">
          <label className="pb-2 font-semibold">
            Description<span className="text-red-500"></span>
          </label>
          <textarea
            cols="30"
            rows="5"
            type="text"
            name="description"
            value={description}
            className="appearance-none block w-full pt-2 px-3 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter a booking description..."
          ></textarea>
        </div>
        <br />

        <div className="px-2 mt-2">
          <button
            type="submit"
            className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
          >
            Create
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateBooking;
