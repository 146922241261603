import React, { useEffect } from "react";
import DashboardHeader from "../../components/Host/Layout/DashboardHeader";
import DashboardSideBar from "../../components/Host/Layout/DashboardSideBar";
import AllCalendars from "../../components/Host/AllCalendars";
import { getAllPlacesHost } from "../../redux/actions/place";
import { getAllEpisodesHost } from "../../redux/actions/episode";
import { useDispatch, useSelector } from "react-redux";

const HostAllCalendarsPage = () => {
  const { host } = useSelector((state) => state.host);

  const dispatch = useDispatch();
  const hostId = host._id;
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getAllPlacesHost(hostId));
        await dispatch(getAllEpisodesHost(hostId));
      } catch (error) {
        console.log(error);
      }
    };
      fetchData();
  }, [dispatch, hostId]);

  return (
    <div>
      <DashboardHeader />
      <div className="flex justify-between w-full">
        <div className="w-[80px] 800px:w-[100px]">
          <DashboardSideBar active={2} />
        </div>
        <div className="w-full justify-center flex">
          <AllCalendars />
        </div>
      </div>
    </div>
  );
};

export default HostAllCalendarsPage;
