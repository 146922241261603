import React from "react";
import DashboardHeader from "../../components/Host/Layout/DashboardHeader";
// import DashboardSideBar from "../../components/Host/Layout/DashboardSideBar";
import AllMessages from "../../components/Host/AllMessages.jsx";

const HostAllMessagesPage = () => {
  return (
    <div>
      <DashboardHeader />
      <AllMessages /> 
    </div>
  );
};

export default HostAllMessagesPage;