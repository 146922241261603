import axios from "axios";
import { server, backend_url } from "../../server";

// load host
export const loadHost = () => async (dispatch) => {
  try {
    dispatch({
      type: "LoadHostRequest",
    });
    const { data } = await axios.get(`${server}/host/gethost`, {
      withCredentials: true,
    });
    dispatch({
      type: "LoadHostSuccess",
      payload: data.host,
    });
  } catch (error) {
    dispatch({
      type: "LoadHostFail",
      payload: error.response.data.message,
    });
  }
};

//Cargar Host Google
export const loadHostAuth = () => async (dispatch) => {
  try {
    dispatch({
      type: "LoadHostAuthRequest",
    });
    const { data } = await axios.get(`${backend_url}/auth/login/success`, {
      withCredentials: true,
    });
    dispatch({
      type: "LoadHostAuthSuccess",
      payload: data.host,
    });
  } catch (error) {
    dispatch({
      type: "LoadHostAuthFail",
      payload: error.response.data.message,
    });
  }
};

// host update information
export const updateHostTerms = (host, termsAccepted) => async (dispatch) => {
  try {
    dispatch({
      type: "updateHostTermsRequest",
    });
    console.log("Action Host: ", host);
    console.log("Action Terms Accept: ", termsAccepted);
    const { data } = await axios.put(
      `${server}/host/update-host-terms`,
      {
        host,
        termsAccepted,
      },
      {
        withCredentials: true,
      }
    );
    dispatch({
      type: "updateHostTermsSuccess",
      payload: data.host,
    });
  } catch (error) {
    dispatch({
      type: "updateHostTermsFailed",
      payload: error.response.data.message,
    });
  }
};

// host update information
export const updateHostInfo = (newForm) => async (dispatch) => {
  try {
    dispatch({
      type: "updateHostInfoRequest",
    });
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Credentials": true,
      },
      withCredentials: true,
    };
    const { data } = await axios.put(
      `${server}/host/update-host-info`,
      newForm,
      config
    );
    dispatch({
      type: "updateHostInfoSuccess",
      payload: data.host,
    });
  } catch (error) {
    dispatch({
      type: "updateHostInfoFailed",
      payload: error.response.data.message,
    });
  }
};

// create team
export const updateHost = (newForm) => async (dispatch) => {
  try {
    dispatch({
      type: "teamCreateRequest",
    });

    const config = { headers: { "Content-Type": "multipart/form-data" } };

    const { data } = await axios.post(
      `${server}/team/create-team`,
      newForm,
      config
    );
    dispatch({
      type: "teamCreateSuccess",
      payload: data.team,
    });
  } catch (error) {
    dispatch({
      type: "teamCreateFail",
      payload: error.response.data.message,
    });
  }
};

// get all hosts --- admin
export const getAllHost = () => async (dispatch) => {
  try {
    dispatch({
      type: "getAllHostsRequest",
    });

    const { data } = await axios.get(`${server}/host/admin-all-hosts`, {
      withCredentials: true,
    });
    dispatch({
      type: "getAllHostsSuccess",
      payload: data.hosts,
    });
  } catch (error) {
    dispatch({
      type: "getAllHostsFailed",
      //   payload: error.response.data.message,
    });
  }
};
