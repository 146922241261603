import React, { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import styles from "../../styles/styles";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { server, backend_url } from "../../server";
import { toast } from "react-toastify";
import { RxAvatar } from "react-icons/rx";
import HostTermsModal from "./HostTermsModal";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const HostCreate = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState("");
  const [address, setAddress] = useState("");
  const [zipCode, setZipCode] = useState();
  const [avatar, setAvatar] = useState();
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!termsAccepted) {
      toast.error("You must accept the terms and conditions.");
      return;
    }
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    const newForm = new FormData();
    newForm.append("name", name);
    newForm.append("email", email);
    newForm.append("password", password);
    newForm.append("file", avatar);
    newForm.append("address", address);
    newForm.append("phoneNumber", phoneNumber.replace(/^\+\d+/, ""));
    newForm.append("country", country);
    newForm.append("zipCode", zipCode);
    newForm.append("termsAccepted", termsAccepted);
    axios
      .post(`${server}/host/create-host`, newForm, config)
      .then((res) => {
        toast.success(res.data.message);
        setName("");
        setEmail("");
        setPassword("");
        setPhoneNumber("");
        setCountry("");
        setAddress("");
        setZipCode("");
        setAvatar();
        setTermsAccepted(false);
        navigate("/host-login");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const handleCountryChange = (value, country) => {
    setPhoneNumber(value);
    setCountry(country.name); // Guarda el nombre del país
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    setAvatar(file);
  };

  const handleGoogleAuth = () => {
    try {
      // window.open(`${backend_url}/auth/google/callback`, "_self");
      window.location.href = `${backend_url}/auth/google/callback`;
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h1 className={`{${styles.logoText}`}>ChecK 'N' CleaN</h1>
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Sign Up
        </h2>
        <br />
        <button
          className="group relative w-full h-[40px] flex justify-center items-center py-2 px-4 border-2 border-solid border-gray-500 text-medium font-medium rounded-md text-[#2c444e] bg-white hover:bg-gray-100"
          onClick={handleGoogleAuth}
        >
          <img
            src="./images/google.png"
            alt="google icon"
            width="25"
            height="25"
          />
          <span className="ml-1"> Continue with Google</span>
        </button>
        <br />
        <div className="flex items-center justify-center">
          <div className="h-px bg-green-500 flex-grow"></div>
          <p className="mx-4">OR</p>
          <div className="h-px bg-green-500 flex-grow"></div>
        </div>
      </div>

      <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-[35rem]">
        <div className="bg-white py-4 px4 shadow sm:rounded-lg sm:px-10">
          <h1 className="mb-4 text-center font-bold text-gray-900">
            Register as a Host
          </h1>
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Host Name
              </label>
              <div className="mt-1">
                <input
                  type="name"
                  name="name"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Phone Number
              </label>
              <div className="mt-1">
                <PhoneInput
                  country={"us"} // País por defecto
                  value={phoneNumber}
                  onChange={(phone, country) =>
                    handleCountryChange(phone, country)
                  }
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email address
              </label>
              <div className="mt-1">
                <input
                  type="email"
                  name="email"
                  autoComplete="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="text"
                className="block text-sm font-medium text-gray-700"
              >
                Address
              </label>
              <div className="mt-1">
                <input
                  type="address"
                  name="address"
                  required
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Zip Code
              </label>
              <div className="mt-1">
                <input
                  type="number"
                  name="zipcode"
                  required
                  value={zipCode}
                  onChange={(e) => setZipCode(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <div className="mt-1 relative">
                <input
                  type={visible ? "text" : "password"}
                  name="password"
                  autoComplete="current-password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
                {visible ? (
                  <AiOutlineEye
                    className="absolute right-2 top-2 cursor-pointer"
                    size={25}
                    onClick={() => setVisible(false)}
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    className="absolute right-2 top-2 cursor-pointer"
                    size={25}
                    onClick={() => setVisible(true)}
                  />
                )}
              </div>
            </div>

            <div>
              <label
                htmlFor="avatar"
                className="block text-sm font-medium text-gray-700"
              ></label>
              <div className="mt-2 flex items-center">
                <span className="inline-block h-8 w-8 rounded-full overflow-hidden">
                  {avatar ? (
                    <img
                      src={URL.createObjectURL(avatar)}
                      alt="avatar"
                      className="h-full w-full object-cover rounded-full"
                    />
                  ) : (
                    <RxAvatar className="h-8 w-8" />
                  )}
                </span>
                <label
                  htmlFor="file-input"
                  className="ml-5 flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                >
                  <span>Upload a file</span>
                  <input
                    type="file"
                    name="avatar"
                    id="file-input"
                    onChange={handleFileInputChange}
                    className="sr-only"
                  />
                </label>
              </div>
            </div>
            <div className="flex items-center">
              <input
                type="checkbox"
                id="termsAcceptance"
                name="termsAcceptance"
                checked={termsAccepted}
                onChange={(e) => setTermsAccepted(e.target.checked)}
                className="ml-2 mt-1 w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
              />
              <label
                htmlFor="termsAcceptance"
                className="ml-2 block text-sm font-medium text-gray-700"
              >
                Accept the{" "}
                <button
                  onClick={openModal}
                  className="text-blue-600 hover:underline"
                >
                  Terms & Conditions
                </button>
              </label>
              <HostTermsModal isOpen={isModalOpen} onClose={closeModal} />
            </div>

            <div>
              <button
                type="submit"
                className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent  rounded-md text-white bg-blue-600 hover:bg-blue-700"
              >
                <span className="text-medium font-medium"> Continue </span>
              </button>
            </div>
            <div className={`${styles.normalFlex} w-full`}>
              <h4>Already have a host account?</h4>
              <Link to="/host-login" className="text-blue-600 pl-2">
                Sign in
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default HostCreate;
