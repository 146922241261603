import { configureStore } from "@reduxjs/toolkit";
import { userReducer } from "./reducers/user";
import { hostReducer } from "./reducers/host";
import { placeReducer } from "./reducers/place";
import { episodeReducer } from "./reducers/episode";
import { serviceReducer } from "./reducers/service";
import { icalReducer } from "./reducers/ical";
import { teamReducer } from "./reducers/team";
import { messageReducer } from "./reducers/message.js";
import { translationReducer } from "./reducers/translation.js";

const Store = configureStore({
  reducer: {
    user: userReducer,
    host: hostReducer,
    places: placeReducer,
    episodes: episodeReducer,
    services: serviceReducer,
    icals: icalReducer,
    teams: teamReducer,
    messages: messageReducer,
    translations: translationReducer,
  },
});

export default Store;
