import axios from "axios";
import { server } from "../../server";
import { getBookingHost } from "../actions/ical";

// create episode
export const createEpisode = (newForm) => async (dispatch) => {
  try {
    dispatch({
      type: "createEpisodeRequest",
    });
    console.log("New Form Action Pre: ", newForm);
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    const { data } = await axios.post(
      `${server}/episode/create-episode`,
      newForm,
      config
    );
    dispatch({
      type: "createEpisodeSuccess",
      payload: data.episode,
    });
  } catch (error) {
    dispatch({
      type: "createEpisodeFail",
      payload: error.response.data.message,
    });
  }
};

export const createService = (newService) => async (dispatch) => {
  try {
    dispatch({
      type: "createServiceRequest",
    });
    console.log("New Service Action: ", newService);
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    const { data } = await axios.post(
      `${server}/episode/create-service`,
      newService,
      config
    );
    console.log("New Service Action: ", newService);

    dispatch({
      type: "createServiceSuccess",
      payload: data.service,
    });
  } catch (error) {
    dispatch({
      type: "createServiceFail",
      payload: error.response.data.message,
    });
  }
};

//Update status service
export const updateStatusService = async (newStatusService) => {
  try {
    const { data } = await axios.put(
      `${server}/episode/update-episode-service-status`,
      newStatusService
    );
    console.log("Update Booking status success:", data);
  } catch (error) {
    console.error("Error updating Booking status:", error);
  }
};

// get all episodes of a host
export const getAllEpisodesHost = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "getAllEpisodesHostRequest",
    });
    const { data } = await axios.get(
      `${server}/episode/get-all-episodes-host/${id}`
    );
    dispatch({
      type: "getAllEpisodesHostSuccess",
      payload: data.episodesHost,
    });
  } catch (error) {
    dispatch({
      type: "getAllEpisodesHostFailed",
      payload: error.response.data.message,
    });
  }
};

// delete episode of a host
export const deleteEpisode = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "deleteEpisodeRequest",
    });
    const { data } = await axios.delete(
      `${server}/episode/delete-host-episode/${id}`,
      {
        withCredentials: true,
      }
    );
    dispatch({
      type: "deleteEpisodeSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "deleteEpisodeFailed",
      payload: error.response.data.message,
    });
  }
};



//Get Episode of a Host
export const getEpisodeHost = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "getEpisodeHostRequest",
    });
    const { data } = await axios.get(
      `${server}/episode/get-episode-host/${id}`
    );
    dispatch({
      type: "getEpisodeHostSuccess",
      payload: data.episodeHost,
    });
  } catch (error) {
    dispatch({
      type: "getEpisodeHostFailed",
      payload: error.response.data.message,
    });
  }
};


// get all episodes
export const getAllEpisodes = () => async (dispatch) => {
  try {
    dispatch({
      type: "getAllEpisodesRequest",
    });

    const { data } = await axios.get(`${server}/episode/get-all-episodes`);
    dispatch({
      type: "getAllEpisodesSuccess",
      payload: data.episodes,
    });
  } catch (error) {
    dispatch({
      type: "getAllEpisodesFailed",
      payload: error.response.data.message,
    });
  }
};
