import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isHost: false,
  isAuth: false,
  isLoading: false,
  error: null,
  success: false,
};

export const hostReducer = createReducer(initialState, {
  //Carga Host Manual
  LoadHostRequest: (state) => {
    state.isLoading = true;
  },
  LoadHostSuccess: (state, action) => {
    state.isLoading = false;
    state.isHost = true;
    state.host = action.payload;
  },
  LoadHostFail: (state, action) => {
    state.isLoading = false;
    state.isHost = false;
    state.error = action.payload;
  },

  //Cargar Host Google
  LoadHostAuthRequest: (state) => {
    state.isLoading = true;
  },
  LoadHostAuthSuccess: (state, action) => {
    state.isLoading = false;
    state.isAuth = true;
    state.host = action.payload;
  },
  LoadHostAuthFail: (state, action) => {
    state.isLoading = false;
    state.isAuth = false;
    state.error = action.payload;
  },

  // update host information
  updateHostInfoRequest: (state) => {
    state.isLoading = true;
  },
  updateUserInfoSuccess: (state, action) => {
    state.isLoading = false;
    state.host = action.payload;
  },
  updateUserInfoFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  // update host terms
  updateHostTermsRequest: (state) => {
    state.isLoading = true;
  },
  updateHostTermsSuccess: (state, action) => {
    state.isLoading = false;
    state.host = action.payload;
    state.success = true;
  },
  updateHostTermsFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.success = false;
  },

  // update host terms
  updateHostInfoRequest: (state) => {
    state.isLoading = true;
  },
  updateHostInfoSuccess: (state, action) => {
    state.isLoading = false;
    state.host = action.payload;
    state.success = true;
  },
  updateHostInfoFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.success = false;
  },

  // get all hosts ---admin
  getAllHostsRequest: (state) => {
    state.isLoading = true;
  },
  getAllHostsSuccess: (state, action) => {
    state.isLoading = false;
    state.hosts = action.payload;
  },
  getAllHostFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  clearErrors: (state) => {
    state.error = null;
  },
});
