import React, { forwardRef } from "react";
import styles from "../../styles/styles";

const About = forwardRef((props, ref) => {
  return (
    <>
      <div
        ref={ref}
        id="1"
        className={`relative min-h-[70vh] 800px:min-h-[80vh] w-full bg-no-repeat ${styles.normalFlex}`}
        style={{
          backgroundImage: "url(https://i.imgur.com/hj2EjHx.jpg)",
        }}
      >
        <div className={`${styles.section} w-[90%] 800px:w-[60%]`}>
          <h1
            className={`text-[35px] leading-[1.2] 800px:text-[60px] text-[#ffffff] font-[600] capitalize`}
          >
            <br />
            <br />
            <br />
            <br />
            Who We Are
          </h1>
        </div>
      </div>
      {/* second section */}
      <div
        className={`relative min-h-[70vh] 800px:min-h-[80vh] w-full bg-no-repeat ${styles.normalFlex} bg-black`}
      >
        <div className={`${styles.section} w-full 800px:w-[60%]`}>
          <h2
            className={`text-[35px] leading-[1.2] 800px:text-[60px] text-[#ffffff] font-[600] capitalize`}
          >
            We work to{" "}
            <span
              className={`{mt-6 text-center text-cyan-500 whitespace-no-wrap}`}
            >
              Improve well-being
            </span>{" "}
            around the world
          </h2>
          <p className="text-[25px] font-[Poppins] font-[400] text-[#e2dada]">
            We accompany the hosts of the world, we excel at that. Every morning
            we wake up thinking about how to improve the performance of the
            places that you, the hosts, manage. We think of you and your
            difficulties. We think of a way for them to find the profits they
            expect. With the rhythm of the present World. Today. <br />
          </p>
        </div>
      </div>
    </>
  );
});

export default About;
