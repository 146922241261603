import { Button } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import React, { useEffect } from "react";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAllTeamsHost, deleteTeam } from "../../redux/actions/team";
import Loader from "../Layout/Loader";

const AllTeams = () => {
  const { teamsHost, isLoading } = useSelector((state) => state.teams);
  const { host } = useSelector((state) => state.host);

  const dispatch = useDispatch();
  const hostId = host._id;

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getAllTeamsHost(hostId));
      } catch (error) {
        console.log(error);
      }
    };
      fetchData();
  }, [dispatch, hostId]);


  const handleDelete = (id) => {
    dispatch(deleteTeam(id));
    window.location.reload();
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 50,
      flex: 1,
    },
    {
      field: "reachOut",
      headerName: "Reach Out By",
      minWidth: 120,
      flex: 2,
    },
    {
      field: "Preview",
      flex: 0.4,
      minWidth: 50,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/team/${params.id}`}>
              <Button>
                <AiOutlineEye size={20} />
              </Button>
            </Link>
          </>
        );
      },
    },
    {
      field: "Delete",
      flex: 0.4,
      minWidth: 50,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        const confirmDelete = () => {
          const userConfirmed = window.confirm(
            "Are you sure you want to delete this team?"
          );
          if (userConfirmed) {
            handleDelete(params.id);
          }
        };
        return (
          <>
            <Button onClick={confirmDelete}>
              <AiOutlineDelete size={20} />
            </Button>
          </>
        );
      },
    },
  ];

  const row = [];
  teamsHost &&
    teamsHost.forEach((item, index) => {
      let reachOut = "";
      if (item.byWA) {
        reachOut += "Whatsapp, ";
      }
      if (item.bySMS) {
        reachOut += "SMS, ";
      }
      if (item.byEmail) {
        reachOut += "Email, ";
      }
      // Remove the last comma and space if at least one option is selected
      if (reachOut.length > 0) {
        reachOut = reachOut.slice(0, -2);
      } else {
        reachOut = "No contact method selected";
      }

      row.push({
        id: item._id,
        name: item.name,
        reachOut: reachOut,
      });
    });

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="w-full mx-8 pt-1 mt-10 bg-white">
          <DataGrid
            rows={row}
            columns={columns.map((column) => ({
              ...column,
              align: "center",
              headerAlign: "center",
            }))}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
          />
        </div>
      )}
    </>
  );
};

export default AllTeams;
