const styles = {
    custom_container: "w-11/12 hidden sm:block",
    heading: 'text-[35px] text-center items-center font-[600] font-Roboto justify-center',
    section:'w-11/12 mx-auto',
    placeTitle: 'text-[25px] font-[600] font-Roboto text-[#333]',
    placeDiscountPrice:"font-bold text-[18px] text-[#333] font-Roboto",
    price:"font-[500] text-[16px] text-[#d55b45] pl-3 mt-[-4px] line-through",
    shop_name:"pt-3 text-[15px] text-blue-400 pb-3",
    active_indicator:"absolute bottom-[-27%] left-0 h-[3px] w-full bg-[crimson]",
    button: 'w-[150px] bg-[#00008B] h-[50px] my-3 flex items-center text-center justify-center rounded-xl cursor-pointer',
    cart_button: "px-[20px] h-[38px] rounded-[20px] bg-[#f63b60] flex items-center justify-center cursor-pointer",
    cart_button_text:"text-[#fff] text-[16px] font-[600]",
    input:"w-full border p-1 rounded-[5px]",
    activeStatus:"w-[10px] h-[10px] rounded-full absolute top-0 right-1 bg-[#40d132]",
    normalFlex:"flex items-center",
    logoText: "mt-6 text-center text-4xl font-extrabold text-cyan-500"
  };
  
  export default styles;
  