import React, { useEffect, useState } from "react";
import {
  GiExitDoor,
  GiEntryDoor,
  GiVacuumCleaner,
  GiDiplodocus,
} from "react-icons/gi";
import { LiaPeopleCarrySolid, LiaWrenchSolid } from "react-icons/lia";
import { useDispatch, useSelector } from "react-redux";
import ReactDOM from "react-dom";
import { useNavigate } from "react-router-dom";
import Loader from "../Layout/Loader";
import { createIcal, getAllBookingsHost } from "../../redux/actions/ical";
import { createEpisode, getAllEpisodesHost } from "../../redux/actions/episode";
import { backend_url } from "../../server";

import { getAllServicesHost } from "../../redux/actions/service.js";

import { getAllPlacesHost } from "../../redux/actions/place";

import "vis-timeline/styles/vis-timeline-graph2d.min.css";
import { Timeline } from "vis-timeline/standalone";
import { toast } from "react-toastify";

const AllCalendars = () => {
  const { host } = useSelector((state) => state.host);
  const { placesHost, isLoading } = useSelector((state) => state.places);
  const { bookingsHost } = useSelector((state) => state.icals);
  const { episodesHost, success, error } = useSelector(
    (state) => state.episodes
  );
  const { servicesHost } = useSelector((state) => state.services);
  const dispatch = useDispatch();
  const hostId = host._id;
  const navigate = useNavigate();

  function adjustTime(dateTimeString, defaultTime) {
    const adjustedDateTime = new Date(dateTimeString);
    if (!isNaN(adjustedDateTime.getTime())) {
      const [hours, minutes] = defaultTime.split(":");
      const isPM = defaultTime.includes("PM");
      let adjustedHours = parseInt(hours, 10);
      if (isPM && adjustedHours !== 12) {
        adjustedHours += 12;
      } else if (!isPM && adjustedHours === 12) {
        adjustedHours = 0;
      }
      adjustedDateTime.setHours(adjustedHours, parseInt(minutes, 10), 0, 0);
      adjustedDateTime.setDate(adjustedDateTime.getDate()); // correct null
    }
    return adjustedDateTime.toISOString();
  }

  function moveAdjustTime(dateTimeString, defaultTime) {
    const adjustedDateTime = new Date(dateTimeString);
    if (!isNaN(adjustedDateTime.getTime())) {
      const [hours, minutes] = defaultTime.split(":");
      const isPM = defaultTime.includes("PM");
      let adjustedHours = parseInt(hours, 10);
      if (isPM && adjustedHours !== 12) {
        adjustedHours += 12;
      } else if (!isPM && adjustedHours === 12) {
        adjustedHours = 0;
      }
      adjustedDateTime.setHours(adjustedHours, parseInt(minutes, 10), 0, 0);
      adjustedDateTime.setDate(adjustedDateTime.getDate() + 1); // correct one day
    }
    return adjustedDateTime.toISOString();
  }

  const [data1Fetched, setData1Fetched] = useState(false);
  const [data2Updated, setData2Updated] = useState(false);
  const [data3Fetched, setData3Fetched] = useState(false);

  useEffect(() => {
    if (!data1Fetched && !data2Updated && !data3Fetched) {
      const fetchData1 = async () => {
        dispatch(getAllPlacesHost(hostId));
        setData1Fetched(true);
      };
      fetchData1();
    }
  }, [dispatch, hostId, data1Fetched, data2Updated, data3Fetched]);

  useEffect(() => {
    if (data1Fetched && !data2Updated && !data3Fetched) {
      const updateCalendars = async () => {
        try {
          await Promise.all(
            placesHost.map(async (place) => {
              const placeId = place._id;
              const calendars = place.calendars;
              if (calendars && calendars.length > 0) {
                await calendars.map(async (calendar) => {
                  const calendarUrl = calendar.calendarUrl;
                  const platform = calendar.platform;
                  await dispatch(
                    createIcal({ calendarUrl, platform, placeId, hostId })
                  );
                });
              } else {
                console.error("calendarUrls not defined for placeId:", placeId);
              }
            })
          );
          setData2Updated(true);
        } catch (error) {
          console.error("Error updating calendars:", error);
        }
      };
      updateCalendars();
    }
  }, [dispatch, placesHost, hostId, data1Fetched, data2Updated, data3Fetched]);

  useEffect(() => {
    if (data1Fetched && data2Updated && !data3Fetched) {
      const fetchData2 = async () => {
        try {
          await dispatch(getAllBookingsHost(hostId));
          await dispatch(getAllEpisodesHost(hostId));
          await dispatch(getAllServicesHost(hostId));
          setData3Fetched(true);
        } catch (error) {
          console.log(error);
        }
      };
      fetchData2();
    }
  }, [dispatch, hostId, data1Fetched, data2Updated, data3Fetched]);

  const getContent = (reactIcon, img) => {
    const item = document.createElement("div");
    item.className = "flex flex-row";
    item.style.borderRadius = "8px";
    const iconContainer = document.createElement("div");
    ReactDOM.render(
      <IconComponent size={40} className="ml-1 mr-2 text-right" color="#1B1E23">
        {reactIcon}
      </IconComponent>,
      iconContainer
    );
    const image = document.createElement("img");
    image.setAttribute("src", img);
    image.style.width = "45px"; // Ajusta el tamaño según tus necesidades
    image.style.height = "45px";
    image.style.borderRadius = "50%";
    item.appendChild(iconContainer);
    item.appendChild(image);

    return item;
  };

  const IconComponent = ({ children, ...props }) => {
    const iconMapping = {
      GiEntryDoor: GiEntryDoor,
      GiExitDoor: GiExitDoor,
      GiVacuumCleaner: GiVacuumCleaner,
      LiaPeopleCarrySolid: LiaPeopleCarrySolid,
      LiaWrenchSolid: LiaWrenchSolid,
      GiDiplodocus: GiDiplodocus,
    };
    const Icon = iconMapping[children];
    if (!Icon) {
      console.error(`IconComponent: Icon '${children}' not found`);
      return null;
    }
    return <Icon {...props} />;
  };

  //Intervalo para refrescar página
  useEffect(() => {
    const intervalId = setInterval(() => {
      setData1Fetched(true);
      setData2Updated(false);
      setData3Fetched(false);
    }, 180000); // 120k ms
    return () => clearInterval(intervalId);
  }, [hostId, dispatch, data1Fetched, data2Updated, data3Fetched]);

  useEffect(() => {
    if (data3Fetched) {
      //     1) Todas las bookings de existingBookings con end de hoy en adelante las borramos de Booking.
      //     2) Cargo todas las bookings en newBooking y las inserto en el Booking
      const timelineInstances = [];
      const renderTimeline = async () => {
        try {
          if (bookingsHost && bookingsHost.length > 0) {
            const container = document.getElementById(
              "all-calendars-container"
            );
            if (container) {
              container.innerHTML = "";

              const visPanels = container.getElementsByClassName("vis-panel");
              await Promise.all(
                placesHost.map(async (place, index) => {
                  const placeContainer = document.createElement("div");
                  placeContainer.classList.add("place-container");
                  container.appendChild(placeContainer);

                  const defaultCheckIn = place.defaultCheckIn;
                  const defaultCheckOut = place.defaultCheckOut;

                  const placeLabel = document.createElement("h2");
                  placeLabel.textContent = place.name;
                  placeLabel.style = `background-color: ${getRandomColor(
                    index
                  )}`;
                  placeLabel.style.color = "#f4f4f4";
                  placeLabel.style.padding = "0px";
                  placeLabel.style.fontWeight = "bold";
                  placeLabel.style.fontSize = "20px";
                  placeLabel.style.textAlign = "center";
                  placeLabel.style.height = "32px";
                  placeLabel.style.lineHeight = "32px";
                  placeLabel.addEventListener("click", () => {
                    navigate(`/dashboard-calendar/${place._id}?index=${index}`);
                  });
                  placeContainer.appendChild(document.createElement("br"));
                  placeContainer.appendChild(placeLabel);
                  placeContainer.style.backgroundColor = "#f4f4f4";

                  const options = {
                    start: new Date().setDate(new Date().getDate() - 2),
                    end: new Date().setDate(new Date().getDate() + 3),
                    align: "center",
                  };

                  const timelineInstance = new Timeline(
                    placeContainer,
                    [],
                    options
                  );

                  let placeBookings = [];
                  let placeEpisodes = [];
                  let uniquePlatforms = [];

                  if (bookingsHost) {
                    //Bookings and Episodes filters according to place
                    placeBookings = bookingsHost
                      ? bookingsHost.filter(
                          (booking) => booking.placeId === place._id
                        )
                      : [];
                    if (placeBookings) {
                      placeEpisodes = episodesHost
                        ? episodesHost.filter(
                            (episode) => episode.placeId === place._id
                          )
                        : [];
                      uniquePlatforms = Array.from(
                        new Set(
                          placeBookings.map((booking) => booking.platform)
                        )
                      ).sort((a, b) => a.localeCompare(b));
                    }
                  }

                  const groups = uniquePlatforms.map((platform) => ({
                    id: platform,
                    content: platform,
                    style: `background-color: ${getRandomColor(platform)}; 
                      color: ${getTextColor(platform)};
                      font-weight: 600; 
                      text-align: center;`,
                  }));

                  groups.push({
                    id: "EPISODES",
                    content: "EPISODES",
                    type: "background",
                    style: `background-color: #e0f7fa ; 
                      color: #222222; 
                      font-weight: 600; 
                      text-align: center;
                      font-size: 16px;
                    `,
                  });

                  // Items 1 - Posible Conflicto - es el grupo de calendarios abiertos en otras plataformas cuando hay una reserva
                  let items1 = [];
                  placeBookings.forEach((booking) => {
                    if (booking.status === "Confirmed") {
                      const startDate = new Date(booking.start)
                        .toISOString()
                        .split("T")[0];
                      const endDate = new Date(booking.end)
                        .toISOString()
                        .split("T")[0];
                      // Verificar para cada plataforma
                      uniquePlatforms.forEach((platform) => {
                        if (
                          platform !== booking.platform &&
                          platform !== "ChecKNCleaN"
                        ) {
                          // Filtrar las reservas de la plataforma actual
                          const otherPlatformBookings = placeBookings
                            ? placeBookings.filter(
                                (otherBooking) =>
                                  otherBooking.platform === platform
                              )
                            : [];

                          // Verificar si la fecha de la reserva actual está disponible en otros calendarios
                          const dateIsAvailable = !otherPlatformBookings.some(
                            (otherBooking) => {
                              const otherStartDate = new Date(
                                otherBooking.start
                              )
                                .toISOString()
                                .split("T")[0];
                              const otherEndDate = new Date(otherBooking.end)
                                .toISOString()
                                .split("T")[0];

                              return (
                                (startDate >= otherStartDate &&
                                  startDate <= otherEndDate) ||
                                (endDate >= otherStartDate &&
                                  endDate <= otherEndDate) ||
                                (startDate <= otherStartDate &&
                                  endDate >= otherEndDate)
                              );
                            }
                          );

                          if (dateIsAvailable) {
                            items1.push({
                              id: `conflict_${booking._id}_${platform}`,
                              group: platform,
                              content: "Not blocked - Possible Conflict",
                              start: moveAdjustTime(startDate, defaultCheckIn),
                              end: moveAdjustTime(endDate, defaultCheckOut),
                              type: "range",
                              style: `background-color: grey; color: yellow;
                                font-family: 'Montserrat', sans-serif; 
                                font-style: italic;
                                font-weight: 500; 
                                font-size: 12px;
                                text-align: left;`,
                            });
                          }
                        }
                      });
                    }
                  });
                  // Fin verificación

                  //Item 2 carga de todo el calendario de un lugar
                  const items2 = placeBookings
                    ? placeBookings
                        .filter((booking) => booking.status !== "Deprecated")
                        .map((booking) => ({
                          id: booking._id,
                          group: booking.platform,
                          content: `<span style="color: ${getTextColor(
                            booking.platform
                          )}">${booking.summary}</span>`,
                          start: adjustTime(new Date(booking.start), defaultCheckIn),
                          end: adjustTime(new Date(booking.end), defaultCheckOut),
                          link: booking.link,
                          style: `background-color: ${getRandomColor(
                            booking.platform
                          )}; color: ${getTextColor(booking.platform)};
                            font-family: 'Montserrat', sans-serif; 
                            font-style: normal;
                            font-weight: 400; 
                            font-size: 12px;
                            text-align: left;
                            cursor: pointer;
                            border-radius: 4px;  
                            border: 1px solid #00bcd4;`,
                        }))
                    : [];

                  //Item 3 - Agrego un "ChecK 'N' CleaN Episode" en EPISODES para las bookings "Confirmed" (link a createEpisode)
                  let items3 = [];
                  const confirmedBookings = placeBookings
                    ? placeBookings.filter(
                        (booking) => booking.status === "Confirmed"
                      )
                    : [];
                  // Verificar si hay episodeos asociados a la reserva (start y end por separado)
                  confirmedBookings.forEach((booking, index) => {
                    const startDate = new Date(booking.start);
                    const endDate = new Date(booking.end);
                    if (endDate >= new Date()) {
                      items3.push({
                        id: `start_${booking._id}`,
                        group: "EPISODES",
                        content: `ChecK 'N' CleaN Episode`,
                        bookingId: booking._id,
                        start: adjustTime(startDate, defaultCheckIn),
                        end: adjustTime(endDate, defaultCheckIn),
                        link: `dashboard-episode/${booking._id}`,  
                        status: "Waiting",
                        type: "range",
                        style: `background-color: #e0f7fa; 
                            color: #333333;
                            font-family: 'Montserrat', sans-serif; 
                            font-style: italic;
                            font-weight: 600; 
                            text-align: left;
                            cursor: pointer;
                            border-radius: 4px;  
                            border: 1px solid #00bcd4;
                    `,
                      });
                    }
                  });

                  const items4 = servicesHost
                    ? servicesHost
                        .filter((service) => service.placeId === place._id)
                        .map((service) => ({
                          id: service._id,
                          group: "EPISODES",
                          content: getContent(
                            `${service.reactIcon}`,
                            `${backend_url}/${service.team.avatar}`
                          ),
                          start: new Date(service.startDate).toISOString(),
                          type: "box",
                          link: `dashboard-service/${service._id}`,
                          style: `background-color: ${getRandomColor(
                            "SERVICES"
                          )}; 
                          color: ${getTextColor("SERVICES")};
                            text-align: left;
                            cursor: pointer;
                            border-radius: 20px;  
                            border: 1px solid #F2B000;`,
                        }))
                    : [];

                  const combinedItems = [
                    ...items1,
                    ...items2,
                    ...items3,
                    ...items4,
                  ];

                  timelineInstances.push(timelineInstance);

                  timelineInstance.setGroups(groups);
                  timelineInstance.setItems(combinedItems);

                  timelineInstance.on("click", (properties) => {
                    const itemId = properties.item;

                    if (itemId !== null && itemId !== undefined) {
                      const item = combinedItems.find(
                        (item) => item.id === itemId
                      );
                      if (item && item.link) {
                        const isItemInItems2 = items2.some(
                          (item2) => item2.id === itemId
                        );
                        if (isItemInItems2) {
                          window.open(item.link, "_blank");
                        }
                        const isItemInItems3 = items3.some(
                          (item3) => item3.id === itemId
                        );
                        if (isItemInItems3) {
                          navigate(`/dashboard-episode/${item.bookingId}`);
                        }
                        const isItemInItems4 = items4.some(
                          (item4) => item4.id === itemId
                        );
                        if (isItemInItems4) {
                          navigate(`/dashboard-service/${item.id}`);
                        }
                      }
                    }
                  });

                  // timelineInstance.on("click", (properties) => {
                  //   const groupId = properties.group;
                  //   const itemId = properties.item;
                  //   if (
                  //     groupId === "EPISODES" &&
                  //     itemId !== null &&
                  //     itemId !== undefined
                  //   ) {
                  //     const item = combinedItems.find(
                  //       (item) => item.id === itemId
                  //     );
                  //     if (
                  //       item &&
                  //       item.type === "range" &&
                  //       item.content === "NEW EPISODE"
                  //     ) {
                  //       const newForm = {
                  //         startDate: item.start,
                  //         endDate: item.end,
                  //         status: item.status,
                  //         bookingId: item.bookingId,
                  //         placeId: place._id,
                  //         hostId: hostId,
                  //       };
                  //       dispatch(createEpisode(newForm));
                  //     }
                  //   }
                  // });

                  return timelineInstance;
                })
              );
            }
          }
        } catch (error) {
          console.log(error);
        }
      };

      renderTimeline();

      return () => {
        timelineInstances.forEach((timelineInstance) => {
          if (timelineInstance) {
            timelineInstance.destroy();
          }
        });
      };
    }
  }, [dispatch, bookingsHost, placesHost, data3Fetched]);

  const getRandomColor = (platform) => {
    const platformColors = {
      SERVICES: "#ECECEC",
      EPISODES: "#ECECEC",
      ChecKNCleaN: "#22D3EE",
      Airbnb: "#e00b40",
      Booking: "#1f3975",
      VRBO: "#3190ee",
    };
    if (platformColors.hasOwnProperty(platform)) {
      return platformColors[platform];
    }
    if (!isNaN(platform)) {
      const indexColor = [
        "#0070C0",
        "#92D050",
        "#ACB9CA",
        "#002060",
        "#FF0000",
        "#00B0F0",
        "#00B050",
        "#FFD966",
        "#d9e1f2",
        "#ededed",
        "#c6e0b4",
        "#bdd7ee",
        "#ebe699",
        "#ddebf7",
        "#dbdbdb",
        "#fce4d6",
        "#f8cbad",
        "#FFC000",
        "#7030A0",
        "#C00000",
      ];
      return indexColor[platform % indexColor.length];
    }
    const randomColors = [
      "#e2efda",
      "#b4c6e7",
      "#fff2cc",
      "#d9e1f2",
      "#ededed",
      "#c6e0b4",
      "#bdd7ee",
      "#ebe699",
      "#ddebf7",
      "#dbdbdb",
      "#fce4d6",
      "#f8cbad",
      "#acb9ca",
    ];
    const randomColor =
      randomColors[Math.floor(Math.random() * randomColors.length)];
    return randomColor;
  };

  const getTextColor = (platform) => {
    const platformColors = {
      Airbnb: "#FFFFFF",
      Booking: "#FFFFFF",
      VRBO: "#FFFFFF",
      ChecKNCleaN: "#FFFFFF",
      EPISODES: "#06B6D4",
      SERVICES: "#FFFFFF",
    };
    let basicColor = "#FFFFFF";
    if (platformColors.hasOwnProperty(platform)) {
      return platformColors[platform];
    } else {
      return basicColor; // Devolver el color base si la plataforma no está en la lista
    }
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    if (success) {
      toast.success("Episode created successfully!");
      window.location.reload();
      //navigate("/dashboard-calendars");
    }
  }, [dispatch, error, success]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="w-full justify-between mx-8 pt-1 mt-10 bg-white">
          <div
            className="w-full justify-between mt-4"
            id="all-calendars-container"
            style={{
              height: "50%",
              minHeight: "200px",
            }}
          />
        </div>
      )}
    </>
  );
};

export default AllCalendars;
