import React, { useEffect, useRef, forwardRef } from "react";
import styles from "../styles/styles";
import Header from "../components/Layout/Header.jsx";
import Footer from "../components/Layout/Footer";
import { useTranslation } from "react-i18next";

const CalendarsPage = () => {
  const servicesRef = useRef(null);

  useEffect(() => {
    if (servicesRef.current) {
      servicesRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <div>
      <Header activeHeading={2} />
      <Calendars ref={servicesRef} />
      <Footer ref={servicesRef} />
    </div>
  );
};

const Calendars = forwardRef((props, ref) => {
  const { t } = useTranslation();
  return (
    <div>
      {" "}
      <>
        <div
          ref={ref}
          id="1"
          className={`relative min-h-[70vh] 800px:min-h-[80vh] w-full bg-no-repeat ${styles.normalFlex}`}
          style={{
            backgroundImage: "url(https://i.imgur.com/VDOI4sh.jpg)",
          }}
        >
          <div className={`${styles.section} w-[90%] 800px:w-[60%]`}>
            <h1
              className={`text-[35px] leading-[1.2] 800px:text-[50px] text-[#ffffff] font-[600] capitalize`}
            >
              <br />
              <br />
              <br />
              <br />
              {t("MultiCalendar-0")}
            </h1>
          </div>
        </div>
        {/* second section */}
        <div
          className={`relative min-h-[70vh] 800px:min-h-[80vh] w-full bg-no-repeat ${styles.normalFlex} bg-black`}
        >
          <div className={`${styles.section} w-full 800px:w-[60%]`}>
            <h2
              className={`text-[35px] leading-[1.2] 800px:text-[60px] text-[#ffffff] font-[600] capitalize`}
            >
              Multi-Calendar{" "}
              <span
                className={`{mt-6 text-center text-6xl font-bold text-cyan-500 whitespace-no-wrap}`}
              >
                View
              </span>
            </h2>
            <p className="text-[25px] font-[Poppins] font-[400] text-[#e2dada]">
            {t("MultiCalendar-1")}
            {t("MultiCalendar-2")}
            {t("MultiCalendar-3")}
              <br />
            </p>
          </div>
        </div>
      </>
    </div>
  );
});

export default CalendarsPage;
