import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import styles from "../../styles/styles";
import { AiFillSave, AiOutlineWhatsApp } from "react-icons/ai";
import {
  GiExitDoor,
  GiEntryDoor,
  GiVacuumCleaner,
  GiDiplodocus,
} from "react-icons/gi";
import { LiaPeopleCarrySolid, LiaWrenchSolid } from "react-icons/lia";
import { DateTime } from "luxon";
import { toast } from "react-toastify";
import { doingsData } from "../../static/data";
import TeamMessages from "../Host/TeamMessages";
import { getService, updateService } from "../../redux/actions/service";
import { getAllTeamsHost } from "../../redux/actions/team";
import { messageCreate } from "../../redux/actions/message";

const ServiceDetails = () => {
  const { booking } = useSelector((state) => state.icals);
  const { service, success, error } = useSelector((state) => state.services);
  const { teamsHost } = useSelector((state) => state.teams);
  const { placesHost } = useSelector((state) => state.places);
  const { host } = useSelector((state) => state.host);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showCalendar, setShowCalendar] = useState(false);
  let placeId;
  let placeName;
  let place;
  let [defaultDurationCleaning] = useState(null);
  let [defaultDurationCheckOut] = useState(null);
  let [defaultDurationCheckIn] = useState(null);

  const [firstRender, setFirstRender] = useState(true);
  const [teams, setTeams] = useState("");
  let [team, setTeam] = useState([]);
  let [doing, setDoing] = useState("");
  let currentService;
  let serviceId;
  let [serviceStartDate, setServiceStartDate] = useState(null);
  let [serviceEndDate, setServiceEndDate] = useState(null);
  let [comments, setComments] = useState("");
  let [status, setStatus] = useState("");

  const { id } = useParams();
  console.log("ID del servicio1: ", id);

  useEffect(() => {
    if (id) {
      dispatch(getService(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (service) {
      dispatch(getAllTeamsHost(service.hostId));
    }
  }, [service, dispatch]);

  useEffect(() => {
    if (teamsHost && teamsHost.length > 0) {
      setTeams(teamsHost);
    }
  }, [teamsHost]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    if (success) {
      toast.success("Service Updated Successfully!");
      navigate(`/dashboard-service/${serviceId}`);
      // window.location.reload();
    }
  }, [error, success, serviceId, dispatch, navigate]);

  // First Load
  useEffect(() => {
    if (service) {
      setStatus(service.status);
      setDoing(currentService);
      setServiceStartDate(
        DateTime.fromISO(service.startDate).toFormat("yyyy-MM-dd'T'HH:mm")
      );
      setServiceEndDate(
        DateTime.fromISO(service.endDate).toFormat("yyyy-MM-dd'T'HH:mm")
      );
      setTeam(service.team);
      setComments(service.comments);
      setFirstRender(false);
    }
  }, [firstRender, service]);

  if (service) {
    serviceId = service._id;
    placeId = service.placeId;
 
    currentService = doingsData.find((item) => item.title === service.doing);
    place = placesHost.find((place) => place._id === placeId);
    if (place) {
      placeName = place && place.name;
      defaultDurationCleaning = place.defaultDurationCleaning;
      defaultDurationCheckOut = place.defaultDurationCheckOut;
      defaultDurationCheckIn = place.defaultDurationCheckIn;
    } else {
      defaultDurationCleaning = 0;
      defaultDurationCheckOut = 0;
      defaultDurationCheckIn = 0;
    }
  }

  //Click update service
  const handleUpdateService = async () => {
    const updatedService = {
      _id: serviceId,
      doing: doing.title,
      reactIcon: doing.reactIcon,
      startDate: serviceStartDate,
      endDate: serviceEndDate,
      comments: comments,
      status: status,
      teamId: team._id,
      team: team,
      episodeId: service.episodeId,
      hostId: service.hostId,
    };
    await dispatch(updateService(updatedService));
    await dispatch(getService(serviceId));
  };

  const handleMessage = async (id) => {
    const confirmed = window.confirm(
      "¿Estás seguro de que deseas enviar el mensaje?"
    );
    if (confirmed) {
      const serviceData = service;
      if (serviceData) {
        const teamName = serviceData.team.name;
        const hostName = host.name;
        const hostPhone = host.phoneNumber;
        const placeAddress =
          place.streetAddress + " " + place.floor + " " + place.doorNumber;
        const serviceType = serviceData.doing;
        const serviceDate = formatDateMessage(
          serviceData.startDate,
          serviceData.endDate
        );
        const serviceComments = serviceData.comments;
        const message =
          "Hi " +
          teamName +
          ", I hope you are doing well, \n" +
          hostName +
          " is requesting: " +
          " \n*" +
          serviceType +
          "* \n" +
          "Where: " +
          placeAddress +
          " \n" +
          "When: " +
          serviceDate +
          " \n" +
          serviceComments +
          " \n" +
          "Send *1* to confirm or *2* to reject." +
          " \n" +
          "If you prefer to coordinate something different please contact your host" +
          "(+" +
          hostPhone +
          ")";
        const newForm = {
          phoneNumber: serviceData.team.phoneNumber,
          message: message,
          serviceId: serviceData._id,
          teamId: serviceData.team._id,
          hostId: serviceData.hostId,
        };
        console.log("New Message: ", newForm);
        await dispatch(messageCreate(newForm));
      } else {
        console.log("No se encontró el servicio con el ID proporcionado.");
      }
    } else {
      console.log("El usuario canceló el envío del mensaje.");
    }
  };

  const IconComponent = ({ children, ...props }) => {
    const iconMapping = {
      GiEntryDoor: GiEntryDoor,
      GiExitDoor: GiExitDoor,
      GiVacuumCleaner: GiVacuumCleaner,
      LiaPeopleCarrySolid: LiaPeopleCarrySolid,
      LiaWrenchSolid: LiaWrenchSolid,
      GiDiplodocus: GiDiplodocus,
    };
    const Icon = iconMapping[children];
    if (!Icon) {
      console.error(`IconComponent: Icon '${children}' not found`);
      return null;
    }
    return <Icon {...props} />;
  };

  const formatDateMessage = (dateString1, dateString2) => {
    const date1 = DateTime.fromISO(dateString1, { zone: "utc" }).setZone(
      "local"
    );
    const date2 = DateTime.fromISO(dateString2, { zone: "utc" }).setZone(
      "local"
    );
    const formattedDate1 = date1.toFormat("'On' EEEE, MMM dd 'at' hh:mm a");
    const formattedDate2 = date2.toFormat("EEEE, MMM dd 'at' hh:mm a");
    const formattedDate =
      date1.toISODate() !== date2.toISODate()
        ? `${formattedDate1} to ${formattedDate2}`
        : `${formattedDate1} to ${date2.toFormat("hh:mm a")}`;
    return formattedDate;
  };

  return (
    <div className="">
      {/* Edit Service form */}
      <div className="text-center">
        <label className="pb-2 ">
          <span
            className={
              "text-[20px] mt-6 text-center text-4xl font-extrabold text-black "
            }
          >
            {" "}
            {`${placeName}`} Service <span className="text-gray-800"></span>
          </span>{" "}
        </label>
      </div>
      <div className="flex flex-wrap items-center justify-center">
        <div className="mt-1 rounded-lg border border-gray-300 p-4 ">
          <div className="flex justify-end">
            <label className="">
              Status:{" "}
              <span className={`${styles.logoText} font-bold text-[15px] mt-0`}>
                {`${status}`}
              </span>
            </label>
          </div>
          <div className="px-2 relative">
            <label className="appearance-none block h-[35px] ">
              Doing <span className="text-red-500">*</span>
            </label>
            <select
              className="block w-full px-3 h-[25px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              value={doing ? doing.title : ""}
              onChange={(e) => {
                const selectedDoing = doingsData.find(
                  (t) => t.title === e.target.value
                );
                setDoing(selectedDoing || null);
                if (booking) {
                  if (selectedDoing.title === "Check In") {
                    setServiceStartDate(
                      DateTime.fromISO(booking.start).toFormat(
                        "yyyy-MM-dd'T'HH:mm"
                      )
                    );
                    setServiceEndDate(
                      DateTime.fromISO(
                        DateTime.fromISO(booking.start).plus({
                          minutes: defaultDurationCheckIn,
                        })
                      ).toFormat("yyyy-MM-dd'T'HH:mm")
                    );
                  }
                  if (selectedDoing.title === "Check Out") {
                    setServiceStartDate(
                      DateTime.fromISO(booking.end).toFormat(
                        "yyyy-MM-dd'T'HH:mm"
                      )
                    );
                    setServiceEndDate(
                      DateTime.fromISO(
                        DateTime.fromISO(booking.end).plus({
                          minutes: defaultDurationCheckOut,
                        })
                      ).toFormat("yyyy-MM-dd'T'HH:mm")
                    );
                  }
                  if (selectedDoing.title === "Cleaning") {
                    setServiceStartDate(
                      DateTime.fromISO(
                        DateTime.fromISO(booking.end).plus({
                          minutes: defaultDurationCheckOut,
                        })
                      ).toFormat("yyyy-MM-dd'T'HH:mm")
                    );
                    setServiceEndDate(
                      DateTime.fromISO(
                        DateTime.fromISO(booking.end).plus({
                          minutes:
                            defaultDurationCheckOut + defaultDurationCleaning,
                        })
                      ).toFormat("yyyy-MM-dd'T'HH:mm")
                    );
                  }
                }
                if (
                  selectedDoing.title !== "Check In" &&
                  selectedDoing.title !== "Check Out" &&
                  selectedDoing.title !== "Cleaning"
                ) {
                  setServiceStartDate(
                    DateTime.local().toFormat("yyyy-MM-dd'T'HH:mm")
                  );
                  setServiceEndDate(
                    DateTime.local().toFormat("yyyy-MM-dd'T'HH:mm")
                  );
                }
              }}
            >
              {doingsData &&
                doingsData.map((i) => (
                  <option key={i.title} value={i.title}>
                    <div className="w-full flex items-start py-3">
                      <h1>{i.title}</h1>
                    </div>
                  </option>
                ))}
            </select>
          </div>
          <div className="flex w-full flex-col sm:flex-row">
            <div className="w-full sm:w-[50%] px-2 mt-2">
              <label className="appearance-none block h-[35px] text-center">
                Start Date<span className="text-red-500">*</span>
              </label>
              <input
                type="datetime-local"
                name="startDateTime"
                id="service-start-date-time"
                // min={today}
                value={serviceStartDate}
                onFocus={() => setShowCalendar(true)}
                onChange={(e) => {
                  const selectedStartDate = e.target.value;
                  setServiceStartDate(selectedStartDate);
                  if (serviceEndDate < selectedStartDate) {
                    setServiceEndDate(selectedStartDate);
                  }
                }}
                className="appearance-none block w-full px-3 h-[25px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            <div className="w-full sm:w-[50%] px-2 mt-2 sm:ml-2">
              <label className="appearance-none block h-[35px] text-center ">
                End Date<span className="text-red-500">*</span>
              </label>
              <input
                type="datetime-local"
                name="endDateTime"
                id="service-end-date-time"
                min={serviceStartDate}
                value={serviceEndDate}
                onFocus={() => setShowCalendar(true)}
                onChange={(e) => setServiceEndDate(e.target.value)}
                className="appearance-none block w-full px-3 h-[25px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
          </div>
          <div className="px-2 mt-2 relative">
            <label className="appearance-none block h-[35px] ">
              Team <span className="text-red-500"></span>
            </label>
            <select
              className="block w-full px-3 h-[25px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              value={team ? team.name : "Choose a Team"}
              onChange={(e) => {
                const selectedTeam = teams.find(
                  (t) => t.name === e.target.value
                );
                setTeam(selectedTeam || null);
              }}
            >
              <option value="Choose a Team">Choose a Team</option>
              {teams &&
                teams.map((i) => (
                  <option key={i.name} value={i.name}>
                    <div className="w-full flex items-start py-3">
                      <h1>{i.name}</h1>
                    </div>
                  </option>
                ))}
            </select>
          </div>
          <div className="px-2 mt-2">
            <label className="appearance-none block h-[35px] ">Comments</label>
            <textarea
              cols="30"
              required
              rows="5"
              type="text"
              name="comments"
              value={comments}
              className="appearance-none block w-full pt-2 px-3 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              onChange={(e) => setComments(e.target.value)}
              placeholder="Enter your episode service comments..."
            ></textarea>
          </div>
          <div className="flex justify-center">
            <div className="flex justify-end">
              <AiOutlineWhatsApp
                size={40}
                className="ml-1 mr-5 mt-3 text-right"
                color="#25D366"
                strokeWidth={20}
                onClick={() => handleMessage(`${service._id}`)}
              />
            </div>
            <div
              className="px-2 flex items-center justify-center mt-1 w-[128px]  bg-gray-100 border border-gray-300 rounded-[10px] placeholder-gray-400 sm:text-sm"
              onClick={() => handleUpdateService()}
            >
              <label className="pb-2  mt-2">
                <span
                  className={`${styles.logoText} font-bold text-[25px] mt-0`}
                >
                  {" "}
                  Save{" "}
                  <span
                    className={`${styles.logoText} font-bold text-[25px] mt-0`}
                  ></span>
                </span>{" "}
              </label>
              <label>
                <AiFillSave size={30} className="ml-1 mt-1" color="#22D3EE" />
              </label>
            </div>
          </div>
        </div>
        <div className="ml-2">
          <TeamMessages id={id} />
        </div>
      </div>
    </div>
  );
};

export default ServiceDetails;