import React from "react";
import styles from "../../styles/styles";

const AI = () => {
  return (
    <>
      <div
        id="4"
        className={`relative min-h-[70vh] 800px:min-h-[80vh] w-full bg-no-repeat ${styles.normalFlex}`}
        style={{
          backgroundImage: "url(https://i.imgur.com/ePMft40.jpg)",
        }}

      >
        <div className={`${styles.section} w-[90%] 800px:w-[60%]`}>
          <h1
            className={`text-[35px] leading-[1.2] 800px:text-[50px] text-[#ffffff] font-[600] capitalize`}
          >
            <br />
            <br />
            <br />
            <br />
            AI
          </h1>
        </div>
      </div>
      {/* second section */}
      <div
        className={`relative min-h-[70vh] 800px:min-h-[80vh] w-full bg-no-repeat ${styles.normalFlex} bg-black`}
      >
        <div className={`${styles.section} w-full 800px:w-[60%]`}>
          <h2
            className={`text-[35px] leading-[1.2] 800px:text-[60px] text-[#ffffff] font-[600] capitalize`}
          >
            Awareness of the{" "}
            <span
              className={`{mt-6 text-center text-cyan-500 whitespace-no-wrap}`}
            >
              Road ahead
            </span>
          </h2>
          <p className="text-[25px] font-[Poppins] font-[400] text-[#e2dada]">
            The hunger of human intelligence to make life more efficient
            converged in a type of intelligence where its processes are more
            reliable. We apply this technology to be better and to be
            inspiration for future generations.
            <br />
          </p>
        </div>
      </div>
    </>
  );
};

export default AI;
