import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "../../styles/styles";
import { navItems } from "../../static/data";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { BiMenuAltLeft } from "react-icons/bi";
import { CgProfile } from "react-icons/cg";
import { IoEarthSharp } from "react-icons/io5";
import DropDown from "./DropDown";
import Navbar from "./Navbar";
import { useSelector } from "react-redux";
import { backend_url } from "../../server";
import LanguageSelector from "../Layout/LanguageSelector";
import { useTranslation } from "react-i18next";

const Header = ({ activeHeading }) => {
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const { isHost } = useSelector((state) => state.host);
  const [active, setActive] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  window.addEventListener("scroll", () => {
    if (window.scrollY > 50) {
      setActive(true);
    } else {
      setActive(false);
    }
  });

  //cierre al clickear afuera en mobile
  useEffect(() => {
    document.addEventListener("click", setOpen(false));
    return () => {
      document.removeEventListener("click", setOpen(false));
    };
  }, [open]);

  const handleItemClick = (item) => {
    console.log(`Elemento clickeado: ${item.title}`);
    setDropDown(false); // Cerrar el menú después de hacer clic en un elemento
  };

  return (
    <>
      <div className={`${styles.section} items-center`}>
        <div className="hidden 800px:h-[50px] 800px:my-[10px] 800px:flex items-center justify-between">
          <div className="flex items-center justify-center">
            <Link to="/">
              <div className="flex">
                {" "}
                <h1 className={`{${styles.logoText} flex whitespace-no-wrap}`}>
                  ChecK '
                  <img
                    src="https://images2.imgbox.com/11/c7/UtCrlGjj_o.png"
                    alt="Logo"
                    className="w-10 h-10"
                  />
                  ' CleaN
                </h1>{" "}
              </div>
            </Link>
          </div>
          <div className="flex items-center justify-between">
            <div className={`${styles.button}`}>
              <Link to={`${isHost ? "/dashboard" : "/host-create"}`}>
                <h1 className="text-[#fff] flex items-center">
                  {isHost ? t("Header-isHost-True") : t("Header-isHost-False")}{" "}
                  <IoIosArrowForward className="ml-1" />
                </h1>
              </Link>
            </div>
            <div className="ml-5 flex items-center justify-right">
              <IoEarthSharp
                size={25}
                className="ml-0 mr-0 mt-0 text-right"
                color="gray"
                strokeWidth={20}
              />
              <div className="flex-none bg-blue-500">
                <LanguageSelector />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Navbar */}
      <div
        className={`${
          active === true ? "shadow-sm fixed top-0 left-0 z-10" : null
        } transition hidden 800px:flex items-center justify-between w-full bg-[#06B6D4] h-[70px]`}
      >
        <div
          className={`${styles.section} relative ${styles.normalFlex} justify-between`}
        >
          {/*Features */}
          <div onClick={() => setDropDown(!dropDown)}>
            <div className="relative h-[60px] mt-[10px] w-[256px] hidden 1000px:block">
              <BiMenuAltLeft size={30} className="absolute top-3 left-2" />
              <button
                className={`h-[100%] w-full flex justify-between items-center pl-10 bg-white font-sans text-lg font-[500] select-none rounded-t-md`}
              >
                {t("Navbar-Features")}
              </button>
              <IoIosArrowDown
                size={20}
                className="absolute right-2 top-4 cursor-pointer"
                onClick={() => setDropDown(!dropDown)}
              />
              {dropDown ? (
                <DropDown
                  navItems={navItems}
                  setDropDown={setDropDown}
                  onClick={() => handleItemClick()}
                />
              ) : null}
            </div>
          </div>
        </div>
        {/* navItems */}
        <div className={`${styles.normalFlex}`}>
          <Navbar active={activeHeading} />
        </div>

        <div className={`${styles.normalFlex}`}>
          <div className="relative cursor-pointer mr-[15px]">
            {isAuthenticated ? (
              <Link to="/profile">
                <img
                  src={`${backend_url}${user.avatar}`}
                  alt=""
                  className="w-[700px] h-[40px] border-[1px] border-[#0eae88] rounded-full"
                />
              </Link>
            ) : (
              <Link to={`${isHost ? "/dashboard" : "/login"}`}>
                <CgProfile size={30} className="rgb(255 255 255 / 83%)" />
              </Link>
            )}
          </div>
        </div>
      </div>

      {/* mobile header */}
      <div
        className={`${
          active === true ? "shadow-sm fixed top-0 left-0 z-10" : null
        }
      w-full h-[60px] bg-[#fff] z-50 top-0 left-0 shadow-sm 800px:hidden`}
      >
        <div className="w-full flex items-center justify-between">
          <div>
            <BiMenuAltLeft
              size={40}
              className="ml-4"
              onClick={() => setDropDown(!dropDown)}
            />
            {dropDown ? (
              <div>
                <DropDown
                  navItems={navItems}
                  setDropDown={setDropDown}
                  onClick={() => handleItemClick()}
                />
              </div>
            ) : null}
          </div>
          <div>
            <Link to="/">
              <div className="flex">
                {" "}
                <h1 className={`{${styles.logoText} flex whitespace-no-wrap}`}>
                  ChecK '
                  <img
                    src="https://images2.imgbox.com/11/c7/UtCrlGjj_o.png"
                    alt="Logo"
                    className="w-10 h-10"
                  />
                  ' CleaN
                </h1>{" "}
              </div>
            </Link>
          </div>
        </div>
        {/* header sidebar */}
        {open && ( //si es true entonces &&
          <div
            className={`fixed w-full bg-[#0000005f] z-20 h-full top-0 left-0`}
          >
            <div className="fixed w-[50%] bg-[#fff] h-screen top-0 left-0 z-10 overflow-y-scroll">
              <Navbar active={activeHeading} />
              <div className={`${styles.button} ml-4 !rounded-[4px]`}>
                <Link to="/host-create">
                  <h1 className="text-[#fff] flex items-center">
                    Host Login <IoIosArrowForward className="ml-1" />
                  </h1>
                </Link>
              </div>
              <br />
              <div className="flex w-full justify-center">
                {isAuthenticated ? (
                  <div>
                    <Link to="/profile">
                      <img
                        src={`${backend_url}${user.avatar}`}
                        alt=""
                        className="w-[60px] h-[60px] rounded-full border-[2px] border-[#0eae89f0]"
                      />
                    </Link>
                  </div>
                ) : (
                  <>
                    <Link
                      to="/login"
                      className="text-[18px] pr-[10px] text-[#000000ad]"
                    >
                      Login /
                    </Link>
                    <Link
                      to="/sign-up"
                      className="text-[18px] text-[#000000ad]"
                    >
                      Sign up
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Header;
