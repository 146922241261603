import React, { useEffect, useState } from "react";
import DashboardHeader from "../../components/Host/Layout/DashboardHeader";
import DashboardSideBar from "../../components/Host/Layout/DashboardSideBar";
import CalendarDetails from "../../components/Host/CalendarDetails";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPlace } from "../../redux/actions/place";

const HostCalendarDetailsPage = () => {
  // const { place } = useSelector((state) => state.places);
  // const dispatch = useDispatch();
  // const { id } = useParams(); //este id es de place
  // const placeId = id;

  // useEffect(() => {
  //   //este useEffect está resolviendo el problema del cruce de datos al recargar la página saliendo y entrando a otro placeId
  //   dispatch(getPlace(placeId));
  // }, [dispatch, placeId]);

  return (
    <div>
      <DashboardHeader />
      <div className="flex justify-between w-full">
      <div className="w-[80px] 800px:w-[100px]">
          <DashboardSideBar active={6} />
        </div>
        <div className="w-full justify-center flex">
          <CalendarDetails />
        </div>
      </div>
    </div>
  );
};

export default HostCalendarDetailsPage;
