import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HostTermsModal from "./HostTermsModal";
import { updateHostTerms } from "../../redux/actions/host";
import { toast } from "react-toastify";

const HostTerms = () => {
  const { host, success, isHost, isAuth } = useSelector((state) => state.host);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isHost && !isAuth) {
      navigate(`/host-login`);
    }
  }, [navigate, isHost, isAuth]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await dispatch(updateHostTerms(host, termsAccepted));
    if (success) {
      toast.success("Thanks and welcome to ChecK 'N' CleaN!");
      navigate("/dashboard");
    }
  };

  return (
    <div>
      <form className="space-y-6" onSubmit={handleSubmit}>
        <div className="flex items-center">
          <input
            type="checkbox"
            id="termsAcceptance"
            name="termsAcceptance"
            checked={termsAccepted}
            onChange={(e) => setTermsAccepted(e.target.checked)}
            className="ml-2 mt-1 w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
          />
          <label
            htmlFor="termsAcceptance"
            className="ml-2 block text-sm font-medium text-gray-700"
          >
            Accept the{" "}
            <button
              onClick={openModal}
              className="text-blue-600 hover:underline"
            >
              Terms & Conditions
            </button>
          </label>
          <HostTermsModal isOpen={isModalOpen} onClose={closeModal} />
        </div>

        <div>
          <button
            type="submit"
            className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent  rounded-md text-white bg-blue-600 hover:bg-blue-700"
          >
            <span className="text-medium font-medium"> Continue </span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default HostTerms;
