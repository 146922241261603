import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { backend_url, server } from "../../server";
import styles from "../../styles/styles";
import Loader from "../Layout/Loader";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getAllTeamsHost } from "../../redux/actions/team";
import { getAllPlacesHost } from "../../redux/actions/place";
import { updateHostInfo } from "../../redux/actions/host";
import { DateTime } from "luxon";
import PhoneInput from "react-phone-input-2";
import { RxAvatar } from "react-icons/rx";
import { FaUpload } from "react-icons/fa";
import "react-phone-input-2/lib/style.css";

const HostInfo = () => {
  const { teams } = useSelector((state) => state.teams);
  const { places } = useSelector((state) => state.places);
  const { host, success } = useSelector((state) => state.host); //agregado

  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState(host?.name || "");
  const [email, setEmail] = useState(host?.email || "");
  const [language, setLanguage] = useState(host?.language || "");
  const [phoneNumber, setPhoneNumber] = useState(host?.phoneNumber || "");
  const [country, setCountry] = useState("");
  const [avatar, setAvatar] = useState();
  const [avatarPreview, setAvatarPreview] = useState(host?.avatar || "");
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (success) {
      toast.success("Host updated successfully!");
      navigate("/dashboard");
      window.location.reload();
    }
  }, [dispatch, navigate, success]);

  useEffect(() => {
    dispatch(getAllPlacesHost(id));
    dispatch(getAllTeamsHost(id));
  }, [dispatch]);

  useEffect(() => {
    setName(host?.name || "");
    setPhoneNumber(host?.phoneNumber || "");
    setAvatarPreview(host?.avatar || "");
  }, [host]);

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    console.log("Selected file:", file); // Verificar el archivo seleccionado
    setAvatar(file);
    setAvatarPreview(URL.createObjectURL(file));
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (isEditing) {
      const newForm = new FormData();
      newForm.append("id", host._id);
      newForm.append("name", name);
      newForm.append("email", email);
      newForm.append("phoneNumber", phoneNumber.replace(/^\+\d+/, ""));
      newForm.append("country", country);
      newForm.append("language", language);
      if (avatar) {
        newForm.append("file", avatar);
      } 
      dispatch(updateHostInfo(newForm));
    }
    setIsEditing(!isEditing);
  };

  const cancelUpdate = (e) => {
    setIsEditing(!isEditing);
  };
  // Save changes
  // dispatch(editHost({ id: host._id, name, phoneNumber, language }));

  const handleCountryChange = (value, country) => {
    setPhoneNumber(value);
    setCountry(country.name); // Guarda el nombre del país
  };

  const logoutHandler = async () => {
    axios // Deslogeo del usuario de la Checknclean
      .get(`${server}/host/logout`, { withCredentials: true })
      .then((res) => {
        toast.success(res.data.message);
        navigate("/host-login");
        window.location.reload(true);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
    axios // Deslogeo del usuario de Google
      .get(`${backend_url}/auth/logout`, { withCredentials: true })
      .then((res) => {
        toast.success(res.data.message);
        navigate("/host-login");
        window.location.reload(true);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };

  const totalPlaces = places ? places.length : 0;

  const totalTeams = teams ? teams.length : 0;

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="">
          <div className="w-full py-5">
            <div className="w-full flex item-center justify-center relative">
              {isEditing ? (
                <div className="mb-2 relative">
                  <label
                    htmlFor="avatar"
                    className="block text-sm font-medium text-gray-700"
                  ></label>
                  <div className="mt-2 flex flex-col items-center">
                    <span className="relative w-[150px] h-[150px] rounded-full overflow-hidden">
                      {host?.avatar ? (
                        <img
                          src={
                            host.avatar.startsWith("http")
                              ? host.avatar
                              : `${backend_url}/${host.avatar}`
                          }
                          alt="avatar"
                          className="w-[150px] h-[150px] object-cover rounded-full"
                        />
                      ) : (
                        <RxAvatar className="w-[150px] h-[150px] object-cover rounded-full" />
                      )}

                      <label
                        htmlFor="file-input"
                        className="absolute bottom-[16px] right-[16px] flex items-center justify-center p-2 border border-gray-300 rounded-full shadow-sm bg-[#e7f3ff] hover:bg-gray-50 z-20"
                      >
                        <input
                          type="file"
                          name="avatar"
                          id="file-input"
                          onChange={handleFileInputChange}
                          className="sr-only"
                        />
                        <FaUpload className="text-gray-700 w-6 h-6" />
                      </label>
                    </span>
                  </div>
                </div>
              ) : (
                <img
                  src={
                    host.avatar.startsWith("http")
                      ? host.avatar
                      : `${backend_url}/${host.avatar}`
                  }
                  alt=""
                  className="w-[150px] h-[150px] object-cover rounded-full"
                />
              )}
            </div>
            <h3 className="text-center py-2 text-[20px]">
              {isEditing ? (
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="border p-2 rounded bg-[#e7f3ff] w-full"
                />
              ) : (
                host.name
              )}
            </h3>
            <p className="text-[12px] text-[#000000a6] p-[5px] flex items-center italic">
              Last Login:{" "}
              {host?.createdAt
                ? DateTime.fromISO(host.lastLogin).toFormat("MMM dd, yyyy")
                : ""}
            </p>
          </div>
          <div className="bg-gray-300">
            <div className="p-3">
              <h5 className="font-[600]">Email</h5>
              <h4 className="text-[#000000a6] break-words">{host.email}</h4>
            </div>
            <div className="p-3">
              <h5 className="font-[600]">Phone Number</h5>
              <h4 className="text-[#000000a6]">
                {isEditing ? (
                  <div className="mt-1">
                    <PhoneInput
                      country={"us"} // País por defecto
                      value={phoneNumber}
                      onChange={(phone, country) =>
                        handleCountryChange(phone, country)
                      }
                      inputStyle={{ width: "100%", background: "#e7f3ff" }} // Usar el 100% del ancho disponible
                      containerClass="w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg "
                    />
                  </div>
                ) : (
                  host.phoneNumber
                )}
              </h4>
            </div>
            <div className="p-3">
              <h5 className="font-[600]">Total Places</h5>
              <h4 className="text-[#000000a6]">{totalPlaces}</h4>
            </div>
            <div className="p-3">
              <h5 className="font-[600]">Total Teams</h5>
              <h4 className="text-[#000000b0]">{totalTeams}</h4>
            </div>
            <div className="p-3">
              <h5 className="font-[600]">Joined On</h5>
              <h4 className="text-[#000000b0]">
                {host?.createdAt
                  ? DateTime.fromISO(host.createdAt).toFormat("MMM dd, yyyy")
                  : ""}
              </h4>
            </div>
          </div>
          <div className="mt-3 bg-gray-300">
            <div className="p-3">
              <h5 className="font-[600]">Language Preference</h5>
              <h4 className="text-[#000000a6]">
                {isEditing ? (
                  <select
                    value={language || "en"}
                    onChange={(e) => setLanguage(e.target.value)}
                    className="border p-2 rounded w-full bg-[#e7f3ff]"
                  >
                    {" "}
                    <option value="DE">Deutsch</option>
                    <option value="EN">English</option>
                    <option value="ES">Español</option>
                    <option value="FR">Français</option>
                    <option value="IT">Italiano</option>
                    <option value="PT">Português</option>
                  </select>
                ) : (
                  {
                    DE: "Deutsch",
                    EN: "English",
                    ES: "Español",
                    FR: "Français",
                    IT: "Italiano",
                    PT: "Português",
                  }[host?.language || "EN"]
                )}
              </h4>
            </div>
          </div>
          <div className="py-3 px-4">
            <div
              className={`${styles.button} !w-full !h-[42px] !rounded-[5px]`}
              onClick={handleUpdate}
            >
              <span className="text-white">
                {isEditing ? "Save" : "Edit Profile"}
              </span>
            </div>
            {isEditing ? (
              <div
                className={`${styles.button} !w-full !h-[42px] !rounded-[5px] mt-2 bg-red-600`}
                onClick={cancelUpdate}
              >
                <span className="text-white">Cancel</span>
              </div>
            ) : (
              <div
                className={`${styles.button} !w-full !h-[42px] !rounded-[5px] mt-2`}
                onClick={logoutHandler}
              >
                <span className="text-white">Logout</span>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default HostInfo;
