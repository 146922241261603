import React, { useEffect } from "react";
import DashboardHeader from "../../components/Host/Layout/DashboardHeader";
import DashboardSideBar from "../../components/Host/Layout/DashboardSideBar";
import CreateBooking from "../../components/Host/CreateBooking";
import { useSelector } from "react-redux";

const HostCreateBookingPage = () => {
  const { host } = useSelector((state) => state.host);

  return (
    <div>
      <DashboardHeader />
      <div className="flex items-center justify-between w-full">
      <div className="w-[80px] 800px:w-[100px]">
          <DashboardSideBar active={6} />
        </div>
        <div className="w-full justify-center flex">
          <CreateBooking />
        </div>
      </div>
    </div>
  );
};

export default HostCreateBookingPage;
