import axios from "axios";
import { server } from "../../server";

// create team
export const createTeam = (newForm) => async (dispatch) => {
  try {
    dispatch({
      type: "teamCreateRequest",
    });
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    const { data } = await axios.post(
      `${server}/team/create-team`,
      newForm,
      config,
      
    );
    dispatch({
      type: "teamCreateSuccess",
      payload: data.team,
    });
  } catch (error) {
    dispatch({
      type: "teamCreateFail",
      payload: error.response.data.message,
    });
  }
};

// get All Teams of a host
export const getAllTeamsHost = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "getAllTeamsHostRequest",
    });
    const { data } = await axios.get(
      `${server}/team/get-all-teams-host/${id}`
    );
    dispatch({
      type: "getAllTeamsHostSuccess",
      payload: data.teamsHost,
    });
    
  } catch (error) {
    dispatch({
      type: "getAllTeamsHostFailed",
      payload: error.response.data.message,
    });
  }
};

// delete team of a host
export const deleteTeam = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "deleteTeamRequest",
    });

    const { data } = await axios.delete(
      `${server}/team/delete-host-team/${id}`,
      {
        withCredentials: true,
      }
    );

    dispatch({
      type: "deleteTeamSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "deleteTeamFailed",
      payload: error.response.data.message,
    });
  }
};