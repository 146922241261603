import React from "react";
import styles from "../../styles/styles";

const Offering = () => {
  return (
    <>
      <div
        id="2"
        className={`relative min-h-[70vh] 800px:min-h-[80vh] w-full bg-no-repeat ${styles.normalFlex}`}
        style={{
          backgroundImage: "url(https://i.imgur.com/laCu2oM.jpg)",
        }}
      >
        <div className={`${styles.section} w-[90%] 800px:w-[60%]`}>
          <h1
            className={`text-[35px] leading-[1.2] 800px:text-[60px] text-[#ffffff] font-[600] capitalize`}
          >
            <br />
            <br />
            <br />
            <br />
            Our offer
          </h1>
        </div>
      </div>
      {/* second section */}
      <div
        className={`relative min-h-[70vh] 800px:min-h-[80vh] w-full bg-no-repeat ${styles.normalFlex} bg-black`}
      >
        <div className={`${styles.section} w-full 800px:w-[60%]`}>
          <h2
            className={`text-[35px] leading-[1.2] 800px:text-[60px] text-[#ffffff] font-[600] capitalize`}
          >
            <span
              className={`{mt-6 text-center text-cyan-500 whitespace-no-wrap}`}
            >
              Excellence
            </span>{" "}
            With Humanity, Quality and Lasting over time.
          </h2>
          <p className="text-[25px] font-[Poppins] font-[400] text-[#e2dada]">
            Our services focus on maximizing the profitability of places. We add
            value to the platforms' calendars. We facilitate the key activities
            of hosts around the places they manage. Improving the performance of
            places is our reason for being <br />
          </p>
        </div>
      </div>
    </>
  );
};

export default Offering;
