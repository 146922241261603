import axios from "axios";
import { server } from "../../server";

// create service
export const createService = (newForm) => async (dispatch) => {
  try {
    dispatch({
      type: "serviceCreateRequest",
    });

    const config = { headers: { "Content-Type": "multipart/form-data" } };
  //  const config = { headers: { "Content-Type": "application/json" } };
    const { data } = await axios.post(
      `${server}/service/create-service`,
      newForm,
      config
    );
    dispatch({
      type: "serviceCreateSuccess",
      payload: data.service,
    });
  } catch (error) {
    dispatch({
      type: "serviceCreateFail",
      payload: error.response.data.message,
    });
  }
};

// get All Services of a host
export const getAllServicesHost = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "getAllServicesHostRequest",
    });
    const { data } = await axios.get(
      `${server}/service/get-all-services-host/${id}`,
      {
        withCredentials: true,
      }
    );
    dispatch({
      type: "getAllServicesHostSuccess",
      payload: data.servicesHost,
    });
  } catch (error) {
    dispatch({
      type: "getAllServicesHostFailed",
      payload: error.response.data.message,
    });
  }
};

// get Services of a Booking
export const getServicesBooking = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "getServicesBookingRequest",
    });
    const { data } = await axios.get(
      `${server}/service/get-services-booking/${id}`,
      {
        withCredentials: true,
      }
    );
    dispatch({
      type: "getServicesBookingSuccess",
      payload: data.servicesBooking,
    });
  } catch (error) {
    dispatch({
      type: "getServicesBookingFailed",
      payload: error.response.data.message,
    });
  }
};

// get Service of a Host
export const getService = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "getServiceRequest",
    });
    const { data } = await axios.get(
      `${server}/service/get-service-host/${id}`,
      {
        withCredentials: true,
      }
    );
    dispatch({
      type: "getServiceSuccess",
      payload: data.service,
    });
  } catch (error) {
    dispatch({
      type: "getServiceFailed",
      payload: error.response.data.message,
    });
  }
};


//actualizar servicio
export const updateService = (updateService) => async (dispatch) => {
  try {
    if (updateService._id) {
      dispatch({
        type: "updateServiceRequest",
      });
      console.log("Updated Service Booking: ", updateService);
      const config = { headers: { "Content-Type": "multipart/form-data" } };
        //  const config = { headers: { "Content-Type": "application/json" } };
      const { data } = await axios.put(
        `${server}/service/update-service`,
        updateService,
        config
      );
      dispatch({
        type: "updateServiceSuccess",
        payload: data.updatedService,
      });
    }
  } catch (error) {
    dispatch({
      type: "updateServiceFail",
      payload: error.response.data.message,
    });
  }
};


// delete service of a host
export const deleteService = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "deleteServiceRequest",
    });
    const { data } = await axios.delete(
      `${server}/service/delete-host-service/${id}`,
      {
        withCredentials: true,
      }
    );
    dispatch({
      type: "deleteServiceSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "deleteServiceFailed",
      payload: error.response.data.message,
    });
  }
};


