import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  translation: null,
};

export const translationReducer = createReducer(initialState, {
  
    // get translation
    getTranslationRequest: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    getTranslationSuccess: (state, action) => {
      state.isLoading = false;
      state.translation = action.payload;
    },
    getTranslationFailed: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

  clearErrors: (state) => {
    state.error = null;
  },
});
