import { Button } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import React, { useEffect } from "react";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAllPlacesHost } from "../../redux/actions/place";
import { deletePlace } from "../../redux/actions/place";
import Loader from "../Layout/Loader";

const AllPlaces = () => {
  const { placesHost, isLoading } = useSelector((state) => state.places);
  const { host } = useSelector((state) => state.host);

  const dispatch = useDispatch();
  const hostId = host._id;
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getAllPlacesHost(hostId));
      } catch (error) {
        console.log(error);
      }
    };
      fetchData();
  }, [dispatch, hostId]);

  const handleDelete = (id) => {
    dispatch(deletePlace(id));
    window.location.reload();
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 40,
      flex: 1,
    },
    {
      field: "address",
      headerName: "Address",
      minWidth: 150,
      flex: 2,
    },
    {
      field: "size",
      headerName: "Size",
      minWidth: 40,
      flex: 0.6,
    },
    {
      field: "propertyType",
      headerName: "Type",
      minWidth: 80,
      flex: 0.6,
    },
    {
      field: "Preview",
      flex: 0.3,
      minWidth: 40,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/place/${params.id}`}>
              <Button>
                <AiOutlineEye size={20} />
              </Button>
            </Link>
          </>
        );
      },
    },
    {
      field: "Delete",
      flex: 0.3,
      minWidth: 40,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Button onClick={() => handleDelete(params.id)}>
              <AiOutlineDelete size={20} />
            </Button>
          </>
        );
      },
    },
  ];

  const row = [];
  placesHost &&
    placesHost.forEach((item, index) => {
      row.push({
        id: item._id,
        address: item.streetAddress + " " + item.floor + item.doorNumber + ", " + item.cityTown + ", " + item.stateProvince,
        name: item.name,
        propertyType: item.propertyType,
        size: item.size + " " + item.unitSize,
      });
    });

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="w-full mx-8 pt-1 mt-10 bg-white">
          <DataGrid
            rows={row}
            columns={columns.map((column) => ({
              ...column,
              align: 'center',
              headerAlign: 'center',
            }))}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
          />
        </div>
      )}
    </>
  );
};

export default AllPlaces;
