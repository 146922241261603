import React, { useEffect, useState } from "react";
import styles from "../../styles/styles";
import { Link, useLocation } from "react-router-dom";
import { IoIosArrowBack, IoMdRefresh } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { getAllBookingsPlace } from "../../redux/actions/ical";
import Loader from "../Layout/Loader";
import "vis-timeline/styles/vis-timeline-graph2d.min.css";
import { Timeline } from "vis-timeline/standalone";

const CalendarDetails = ({}) => {
  const { place } = useSelector((state) => state.places);
  const { isLoading, bookingsPlace } = useSelector((state) => state.icals);

  const dispatch = useDispatch();
  const placeId = place && place._id;
  const [timeline, setTimeline] = useState(null);

  const index = new URLSearchParams(useLocation().search).get("index"); // Obtén el valor de index desde la consulta

  useEffect(() => {
    // Traer calendarios
    const fetchData = async () => {
      try {
        await dispatch(getAllBookingsPlace(placeId));
      } catch (error) {
        console.error("Error:", error);
      } finally {
      }
    };
    fetchData();
  }, [dispatch, placeId]);

  useEffect(() => {
    if (bookingsPlace && bookingsPlace.length > 0) {
      // Crear y configurar la línea de tiempo
      const container = document.getElementById("timeline-container");
      if (container) {
        container.innerHTML = "";
        const visPanels = container.getElementsByClassName("vis-panel");
        const placeContainer = document.createElement("div");
        placeContainer.classList.add("place-container");
        container.appendChild(placeContainer);

        const placeLabel = document.createElement("h2");
        placeLabel.textContent = place && place.name;
        placeLabel.style = `background-color: ${getRandomColor(index)}`;
        placeLabel.style.color = "#f4f4f4";
        placeLabel.style.padding = "6px";
        placeLabel.style.fontWeight = "bold";
        placeLabel.style.fontSize = "20px";
        placeContainer.appendChild(document.createElement("br"));
        placeContainer.appendChild(placeLabel);
        placeContainer.style.backgroundColor = "#f4f4f4";

        const options = {
          start: new Date().setDate(new Date().getDate() - 7),
          end: new Date().setDate(new Date().getDate() + 10),
        };
        const timelineInstance = new Timeline(placeContainer, [], options);
        const placeBookings = bookingsPlace.filter(
          (booking) => booking.placeId === place._id
        );
        const uniquePlatforms = Array.from(
          new Set(placeBookings.map((booking) => booking.platform)) //< cargo las reservas de esa plataforma de ese placeId...
        );
        const groups = uniquePlatforms.map((platform) => ({
          id: platform,
          content: platform,
          style: `background-color: ${getRandomColor(
            platform
          )}; color: ${getTextColor(platform)}; font-weight: 600;`,
        }));
        const items = placeBookings.map((booking) => ({
          id: booking._id,
          group: booking.platform,
          content: `<span style="color: ${getTextColor(booking.platform)}">${
            booking.summary
          }</span>`,
          start: new Date(booking.start).toISOString(),
          end: new Date(booking.end).toISOString(),
          link: booking.link,
          style: `background-color: ${getRandomColor(
            booking.platform
          )}; color: ${getTextColor(booking.platform)};`,
        }));
        timelineInstance.setGroups(groups);
        timelineInstance.setItems(items);

        return () => {
          if (timelineInstance) {
            timelineInstance.destroy();
            setTimeline(null);
          }
        };
      }
    }
  }, [bookingsPlace]);

  const getRandomColor = (platform) => {
    const platformColors = {
      Airbnb: "#e00b40",
      Booking: "#1f3975",
      VRBO: "#3190ee",
    };
    if (platformColors.hasOwnProperty(platform)) {
      return platformColors[platform];
    }
    if (!isNaN(platform)) {
      const indexColor = [
        "#FFC000",
        "#92D050",
        "#0070C0",
        "#002060",
        "#FF0000",
        "#00B0F0",
        "#00B050",
        "#FFD966",
        "#d9e1f2",
        "#ededed",
        "#c6e0b4",
        "#bdd7ee",
        "#ebe699",
        "#ddebf7",
        "#dbdbdb",
        "#fce4d6",
        "#f8cbad",
        "#acb9ca",
        "#7030A0",
        "#C00000",
      ];
      return indexColor[platform % indexColor.length];
    }
    const randomColors = [
      "#e2efda",
      "#b4c6e7",
      "#fff2cc",
      "#d9e1f2",
      "#ededed",
      "#c6e0b4",
      "#bdd7ee",
      "#ebe699",
      "#ddebf7",
      "#dbdbdb",
      "#fce4d6",
      "#f8cbad",
      "#acb9ca",
    ];
    const randomColor =
      randomColors[Math.floor(Math.random() * randomColors.length)];
    return randomColor;
  };

  const getTextColor = (platform) => {
    const platformColors = {
      Airbnb: "#FFFFFF",
      Booking: "#FFFFFF",
      VRBO: "#FFFFFF",
    };
    let basicColor = "#FFFFFF";
    if (platformColors.hasOwnProperty(platform)) {
      return platformColors[platform];
    } else {
      return basicColor; // Devolver el color base si la plataforma no está en la lista
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="w-full ">
          <div className="flex items-center justify-center ">
            <div
              className={`${styles.button} items-center ml-4 !rounded-[4px]`}
            >
              <Link to="/dashboard-calendars">
                <h1 className="text-[#fff] flex items-center">
                  <IoIosArrowBack className="mr-2" />
                  Back
                </h1>
              </Link>
            </div>
            <div
              className={` ${styles.button}items-center  ml-4 !rounded-[4px]`}
            >
              <Link to={`/dashboard-calendar/${placeId}`}>
                <h1 className="text-[#fff] flex items-center">
                  <IoMdRefresh className="mr-2" />
                  Refresh
                </h1>
              </Link>
            </div>
          </div>
          <h1 className="font-bold">Calendar Place Details</h1>
          <div
            className="w-full justify-between mt-4"
            id="timeline-container"
            style={{
              height: "50%",
              minHeight: "200px",
            }}
          />
        </div>
      )}
    </>
  );
};

export default CalendarDetails;
