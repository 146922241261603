import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import Store from "./redux/store";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

ReactDOM.render(
  <Provider store={Store}>
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
      </BrowserRouter>
    </I18nextProvider>
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();
