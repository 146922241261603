import React from "react";
import { AiFillFacebook, AiFillInstagram, AiFillYoutube } from "react-icons/ai";
import { RiTwitterXFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import {
  footerCompanyLinks,
  footerServicesLinks,
  footerSupportLinks,
} from "../../static/data";
import { useTranslation } from "react-i18next";

const Footer = ({ aboutRef, servicesRef }) => {
  const handleCompanyLinkClick = () => {
    // Si la referencia a "About" existe, desplazarse hacia esa sección
    if (servicesRef && servicesRef.current) {
      servicesRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const { t } = useTranslation();

  const handleServicesLinkClick = () => {
    // Si la referencia a "Services" existe, desplazarse hacia esa sección
    if (aboutRef && aboutRef.current) {
      aboutRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="bg-[#000] text-white">
      <div className="md:flex md:justify-between md:items-center sm:px-12 px-4 bg-[#342ac8] py-7">
        <h1 className="lg:text-4xl text-3xl md:mb-0 mb-6 lg:leading-normal font-semibold md:w-2/5">
          <span className="text-[#56d879]">{t("Footer-Subscribe-1")}</span>{" "}
          {t("Footer-Subscribe-2")} <br />
          {t("Footer-Subscribe-3")}
        </h1>
        <div>
          <input
            type="text"
            required
            placeholder={t("Footer-Subscribe-4")}
            className="text-gray-800
                sm:w-72 w-full sm:mr-5 mr-1 lg:mb-0 mb-4 py-2.5 rounded px-2 focus:outline-none"
          />
          <button className="bg-[#56d879] hover:bg-teal-500 duration-300 px-5 py-2.5 rounded-md text-whie md:w-auto w-full">
            {t("Footer-Submit")}
          </button>
        </div>
      </div>
      <div className="grid grid-cols-1 sm:gird-cols-3 lg:grid-cols-4 gap-6 sm:px-8 px-5 py-16 sm:text-center">
        <ul className="px-5 text-center sm:text-start flex sm:block flex-col items-center">
          <div className="flex rounded-md overflow-hidden">
            <img
              src="https://images2.imgbox.com/6e/40/6IZPvOSQ_o.png"
              alt="checknclean-1-logo.png"
              className=""
            />
          </div>
          <br />
          <p className="text-sm leading-5 mb-2">{t("Footer-Slogan")}</p>
          <div className="flex items-center mt-[15px]">
            <AiFillFacebook size={25} className="cursor-pointer" />
            <RiTwitterXFill
              size={25}
              style={{ marginLeft: "15px", cursor: "pointer" }}
            />
            <AiFillInstagram
              size={25}
              style={{ marginLeft: "15px", cursor: "pointer" }}
            />
            <AiFillYoutube
              size={25}
              style={{ marginLeft: "15px", cursor: "pointer" }}
            />
          </div>
        </ul>

        <ul className="text-center sm:text-start">
          <h1 className="mb-1 font-semibold">{t("Footer-About-0")}</h1>
          {footerCompanyLinks.map((link, index) => (
            <li key={index}>
              <Link
                className="text-gray-400 hover:text-teal-400 duration-300
                   text-sm cursor-pointer leading-6"
                to={link.link}
                onClick={handleCompanyLinkClick}
              >
                {t(link.name)}
              </Link>
            </li>
          ))}
        </ul>

        <ul className="text-center sm:text-start">
          <h1 className="mb-1 font-semibold">{t("Footer-Services-0")}</h1>
          {footerServicesLinks.map((link, index) => (
            <li key={index}>
              <Link
                className="text-gray-400 hover:text-teal-400 duration-300
                   text-sm cursor-pointer leading-6"
                to={link.link}
                onClick={handleServicesLinkClick}
              >
                {t(link.name)}
              </Link>
            </li>
          ))}
        </ul>

        <ul className="text-center sm:text-start">
          <h1 className="mb-1 font-semibold">{t("Footer-Support-0")}</h1>
          {footerSupportLinks.map((link, index) => (
            <li key={index}>
              <Link
                className="text-gray-400 hover:text-teal-400 duration-300
                   text-sm cursor-pointer leading-6"
                to={link.link}
              >
                {t(link.name)}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div
        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10
         text-center pt-2 text-gray-400 text-sm pb-8"
      >
        <span>{t("Footer-Rights")}</span>
        <span>{t("Footer-Policy")}</span>
      </div>
    </div>
  );
};

export default Footer;
