import React, { useEffect, useState } from "react";
import {
  AiOutlineDelete,
  AiOutlinePlusCircle,
  AiTwotoneEdit,
  AiOutlineFieldTime,
  AiOutlineWhatsApp,
  AiOutlineTeam,
} from "react-icons/ai";
import {
  GiExitDoor,
  GiEntryDoor,
  GiVacuumCleaner,
  GiDiplodocus,
} from "react-icons/gi";
import { LiaPeopleCarrySolid, LiaWrenchSolid } from "react-icons/lia";
import { useDispatch, useSelector } from "react-redux";
import { getAllServicesHost, deleteService } from "../../redux/actions/service";
import { messageCreate } from "../../redux/actions/message";
import { getAllPlacesHost } from "../../redux/actions/place";
import { backend_url } from "../../server";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import Board from "react-trello";
import { useTranslation } from "react-i18next";

const calculateTimeRemaining = (startDate) => {
  const now = new Date();
  const startDateObj = new Date(startDate);
  if (startDateObj < now) {
    return "0m";
  }
  const timeDifference = startDateObj - now;
  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
  let result = "";
  if (days !== 0) {
    result += `${days}d `;
  }
  if (hours !== 0) {
    result += `${hours}h `;
  }
  result += `${minutes}m`;
  return result.trim(); // Elimina espacios en blanco adicionales
};

const Dashboard = () => {
  const { host } = useSelector((state) => state.host);
  const { servicesHost } = useSelector((state) => state.services);
  const { placesHost } = useSelector((state) => state.places);
  const hostId = host._id;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(host ? "private" : "public");

  //Definitions
  let [defaultDurationCleaning] = useState(null);
  let [defaultDurationCheckOut] = useState(null);
  let [defaultDurationCheckIn] = useState(null);

  let [doing, setDoing] = useState("");
  let [startDate, setStartDate] = useState(null);
  let [endDate, setEndDate] = useState(null);
  let [status, setStatus] = useState("");
  let [team, setTeam] = useState([]);
  let [comments, setComments] = useState("");
  let [place] = useState([]);
  let placeId;
  let placeName;
  let serviceId;
  let bookingId;

  // useEffect(() => {
  //   if (hostId) {
  //     dispatch(getAllServicesHost(hostId));
  //   }
  // }, [hostId, dispatch]);

  let fetchData1;
  useEffect(() => {
    fetchData1 = async () => {
      try {
        if (hostId) {
          dispatch(getAllServicesHost(hostId));
          await dispatch(getAllPlacesHost(hostId));
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData1();
    const intervalId = setInterval(() => {
      fetchData1();
    }, 60000); // 60k ms
    return () => clearInterval(intervalId);
  }, [dispatch, hostId]);

  // if (servicesHost) {
  //   serviceId = servicesHost._id;
  //   doing = servicesHost.doing;
  //   startDate = servicesHost.startDate;
  //   endDate = servicesHost.endDate;
  //   status = servicesHost.status;
  //   team = servicesHost.team;
  //   comments = servicesHost.comments;
  //   bookingId = servicesHost.bookingId;
  //   placeId = servicesHost.placeId;
  //   place = placesHost.find((place) => place._id === placeId);
  //   if (place) {
  //     placeName = place && place.name;
  //     defaultDurationCleaning = place.defaultDurationCleaning;
  //     defaultDurationCheckOut = place.defaultDurationCheckOut;
  //     defaultDurationCheckIn = place.defaultDurationCheckIn;
  //   } else {
  //     defaultDurationCleaning = 0;
  //     defaultDurationCheckOut = 0;
  //     defaultDurationCheckIn = 0;
  //   }
  // }

  const handleMessage = async (id) => {
    const confirmed = window.confirm(
      "You are going to request the service via Whatsapp, do you want to continue?"
    );
    if (confirmed) {
      const serviceId = id; // es local episodeId
      const serviceData = servicesHost.find(
        (service) => service._id === serviceId
      );
      if (serviceData) {
        place = placesHost.find((place) => place._id === serviceData.placeId);
        const teamName = serviceData.team.name;
        const hostName = host.name;
        const hostPhone = host.phoneNumber;
        const placeAddress =
          place.streetAddress + " " + place.floor + " " + place.doorNumber;
        const serviceType = serviceData.doing;
        const serviceDate = formatDateMessage(
          serviceData.startDate,
          serviceData.endDate
        );
        const serviceComments = serviceData.comments;
        const message =
          t("message-req-1") +
          teamName +
          t("message-req-2") +
          hostName +
          t("message-req-3") +
          t("message-req-4") +
          serviceType +
          t("message-req-5") +
          t("message-req-6") +
          placeAddress +
          t("message-req-7") +
          t("message-req-8") +
          serviceDate +
          t("message-req-9") +
          serviceComments +
          t("message-req-10") +
          t("message-req-11") +
          t("message-req-12") +
          t("message-req-13") +
          t("message-req-14") +
          hostPhone +
          t("message-req-15");
        const newForm = {
          phoneNumber: serviceData.team.phoneNumber,
          message: message,
          serviceId: serviceId,
          teamId: serviceData.team._id,
          hostId: hostId,
        };
        await dispatch(messageCreate(newForm));
      } else {
        console.log("No se encontró el servicio con el ID proporcionado.");
      }
    } else {
      console.log("El usuario canceló el envío del mensaje.");
    }
  };

  const handleEdit = async (id) => {
    const serviceId = id;
    navigate(`/dashboard-service/${serviceId}`);
  };

  const handleDelete = async (id) => {
    await dispatch(deleteService(id));
    dispatch(getAllServicesHost(id));
  };

  const IconComponent = ({ children, ...props }) => {
    const iconMapping = {
      GiEntryDoor: GiEntryDoor,
      GiExitDoor: GiExitDoor,
      GiVacuumCleaner: GiVacuumCleaner,
      LiaPeopleCarrySolid: LiaPeopleCarrySolid,
      LiaWrenchSolid: LiaWrenchSolid,
      GiDiplodocus: GiDiplodocus,
    };
    const Icon = iconMapping[children];
    if (!Icon) {
      console.error(`IconComponent: Icon '${children}' not found`);
      return null;
    }
    return <Icon {...props} />;
  };

  const formatDateMessage = (dateString1, dateString2) => {
    const date1 = DateTime.fromISO(dateString1, { zone: "utc" }).setZone(
      "local"
    );
    const date2 = DateTime.fromISO(dateString2, { zone: "utc" }).setZone(
      "local"
    );
    const formattedDate1 = date1
      .toISO()
      .toFormat("'On' EEEE, MMM dd 'at' hh:mm a");
    const formattedDate2 = date2.toFormat("EEEE, MMM dd 'at' hh:mm a");
    const formattedDate =
      date1.toISODate() !== date2.toISODate()
        ? `${formattedDate1} to ${formattedDate2}`
        : `${formattedDate1} to ${date2.toFormat("hh:mm a")}`;
    return formattedDate;
  };

  const formatDate = (dateString) => {
    const formattedDate = DateTime.fromISO(dateString, { zone: "utc" })
      .setZone("local")
      .toFormat("MMM-dd-yyyy hh:mm a");
    return formattedDate;
  };

  const data = {
    lanes: [
      {
        id: "todo",
        title: "To Do", //la idea es que el lane indique el status
        style: { backgroundColor: "rgb(136,226,244)" },
        // style: { backgroundColor: 'rgb(80,124,203)' }, //alternativa fuerte
        cardStyle: { backgroundColor: "rgb(235,235,235)" },
        cards: servicesHost
          ? servicesHost
              .filter((service) => new Date(service.startDate) > new Date())
              .sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
              .map((service) => {
                if (placesHost) {
                  place = placesHost.find(
                    (place) => place._id === service.placeId
                  );
                }
                let cardBackgroundColor;
                switch (service.status) {
                  case "Added":
                    cardBackgroundColor = "rgb(102,204,255)";
                    break;
                  case "Confirmed":
                    cardBackgroundColor = "rgb(153,204,235)";
                    break;
                  case "Rejected":
                    cardBackgroundColor = "rgb(255,204,215)";
                    break;
                  default:
                    cardBackgroundColor = "inherit";
                }
                return {
                  id: service.id,
                  title: (
                    <div className="flex items-center justify-start">
                      {" "}
                      {service.doing}
                      <IconComponent
                        size={30}
                        className="ml-1 mr-2 text-right"
                        color="#1B1E23"
                      >
                        {service.reactIcon}
                      </IconComponent>{" "}
                    </div>
                  ),
                  label: (
                    <div>
                      <div className="h-full flex items-center justify-end">
                        <span className="text-xs font-extrabold">
                          {service.status}
                        </span>
                      </div>
                      <div className=" flex items-center justify-end">
                        <span className="text-xs font-semibold">
                          {place.name}
                        </span>
                      </div>
                    </div>
                  ),
                  description: (
                    <div className="">
                      <div className="text-left justify-between">
                        <div className="">
                          <span className="text-base font-bold">
                            {service.team.name}
                          </span>
                          <span className="text-base">{" Service Team"}</span>
                        </div>
                      </div>
                      <div className="flex">
                        <div className="flex">
                          <div className="mr-3 justify-between">
                            {service.team.avatar ? (
                              <img
                                src={`${backend_url}/${service.team.avatar}`}
                                alt="Team Avatar"
                                className="w-[50px] h-[50px] rounded-full object-cover "
                              />
                            ) : (
                              <AiOutlineTeam
                                size={35}
                                className="ml-1 mt-2 text-right justify-end"
                                color="#1B1E23"
                              />
                            )}
                          </div>
                          <div className="">
                            <p className="mb-0">
                              Start:{" "}
                              <span className="font-semibold">
                                {new Date(service.startDate).toISOString()}
                              </span>
                            </p>
                            <p className="mb-0">
                              End:{" "}
                              <span className="font-semibold">
                                {new Date(service.endDate).toISOString()}
                              </span>
                            </p>
                            <p className="mb-0">
                              Address:{" "}
                              <span className="font-semibold">
                                {place.streetAddress}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      {service.comments ? (
                        <div className="block mb-2">
                          <p>
                            Comments:{" "}
                            <span className="text-xs font-semibold">
                              {service.comments}
                            </span>
                          </p>
                        </div>
                      ) : (
                        <div className="block mb-2"></div>
                      )}
                      <div>
                        <hr />
                        <div>
                          <div className="flex items-center justify-between">
                            <div className="flex justify-start">
                              <AiOutlineFieldTime
                                size={20}
                                className="ml-1 mr-2 mt-2 text-left "
                                color="#1B1E23"
                              />
                              {service.startDate &&
                              Date.now() <
                                new Date(service.startDate).getTime() ? (
                                <span className="ml-1 mt-2 text-left">{`${calculateTimeRemaining(
                                  service.startDate
                                )} left`}</span>
                              ) : (
                                <span className="ml-1 mt-2 text-left text-red-500">
                                  0 m left
                                </span>
                              )}
                            </div>
                            <div className="flex justify-end">
                              <AiOutlineWhatsApp
                                size={20}
                                className="ml-1 mr-2 mt-2 text-right"
                                color="#25D366"
                                strokeWidth={20}
                                onClick={() => handleMessage(`${service._id}`)}
                              />
                              <AiTwotoneEdit
                                size={20}
                                className="ml-1 mr-2 mt-2 text-right"
                                color="#1B1E23"
                                onClick={() => handleEdit(`${service._id}`)}
                              />
                              <AiOutlineDelete
                                size={20}
                                className="ml-1 mt-2 text-right justify-end"
                                color="#1B1E23"
                                onClick={() => handleDelete(`${service._id}`)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ),
                  style: {
                    backgroundColor: cardBackgroundColor,
                    borderRadius: 10,
                    boxShadow: "0 0 8px 1px rgba(0, 0, 0, 0.2)",
                    marginBottom: 15,
                    overflow: "hidden",
                  },
                };
              })
          : [],
      },
      {
        id: "inprogress",
        title: "In Progress", //la idea es que el lane indique el status
        style: { backgroundColor: "rgb(244,230,136)" },
        // style: { backgroundColor: 'rgb(248,180,68)' },
        cardStyle: {
          backgroundColor: "rgb(250,250,250)",
          borderRadius: 10,
          boxShadow: "0 0 8px 1px rgba(0, 0, 0, 0.2)",
          marginBottom: 15,
          overflow: "hidden",
        },
        cards: servicesHost
          ? servicesHost
              .filter((service) => {
                return (
                  new Date(service.startDate) <= new Date() &&
                  new Date(service.endDate) >= new Date()
                );
              })
              .sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
              .map((service) => {
                let place = {};
                if (placesHost) {
                  place = placesHost.find(
                    (place) => place._id === service.placeId
                  );
                }

                return {
                  id: service.id,
                  title: (
                    <div className="flex items-center justify-start">
                      {" "}
                      {service.doing}
                      <IconComponent
                        size={30}
                        className="ml-1 mr-2 text-right"
                        color="#1B1E23"
                      >
                        {service.reactIcon}
                      </IconComponent>{" "}
                    </div>
                  ),
                  label: (
                    <div>
                      <div className="h-full flex items-center justify-end">
                        <span className="text-xs font-extrabold">
                          {service.status}
                        </span>
                      </div>
                      <div className=" flex items-center justify-end">
                        <span className="text-xs font-semibold">
                          {place.name}
                        </span>
                      </div>
                    </div>
                  ),
                  description: (
                    <div className="">
                      <div className="text-left justify-between">
                        <div className="">
                          <span className="text-base font-bold">
                            {service.team.name}
                          </span>
                          <span className="text-base">{" Service Team"}</span>
                        </div>
                      </div>
                      <div className="flex">
                        <div className="flex">
                          <div className="mr-3 justify-between">
                            {service.team.avatar ? (
                              <img
                                src={`${backend_url}/${service.team.avatar}`}
                                alt="Team Avatar"
                                className="w-[50px] h-[50px] rounded-full object-cover "
                              />
                            ) : (
                              <AiOutlineTeam
                                size={35}
                                className="ml-1 mt-2 text-right justify-end"
                                color="#1B1E23"
                              />
                            )}
                          </div>
                          <div className="">
                            <p className="mb-0">
                              Start:{" "}
                              <span className="font-semibold">
                                {new Date(service.startDate).toISOString()}
                              </span>
                            </p>
                            <p className="mb-0">
                              End:{" "}
                              <span className="font-semibold">
                                {new Date(service.endDate).toISOString()}
                              </span>
                            </p>
                            <p className="mb-0">
                              Address:{" "}
                              <span className="font-semibold">
                                {place.streetAddress}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      {service.comments ? (
                        <div className="block mb-2">
                          <p>
                            Comments:{" "}
                            <span className="text-xs font-semibold">
                              {service.comments}
                            </span>
                          </p>
                        </div>
                      ) : (
                        <div className="block mb-2"></div>
                      )}
                      <div>
                        <hr />
                        <div>
                          <div className="flex items-center justify-between">
                            <div className="flex justify-start">
                              <AiOutlineFieldTime
                                size={20}
                                className="ml-1 mr-2 mt-2 text-left "
                                color="#1B1E23"
                              />
                              {service.startDate &&
                              Date.now() <
                                new Date(service.startDate).getTime() ? (
                                <span className="ml-1 mt-2 text-left">{`${calculateTimeRemaining(
                                  service.startDate
                                )} left`}</span>
                              ) : (
                                <span className="ml-1 mt-2 text-left text-red-500">
                                  0 m left
                                </span>
                              )}
                            </div>
                            <div className="flex justify-end">
                              <AiOutlineWhatsApp
                                size={20}
                                className="ml-1 mr-2 mt-2 text-right"
                                color="#25D366"
                                strokeWidth={20}
                                onClick={() => handleMessage(`${service._id}`)}
                              />
                              <AiTwotoneEdit
                                size={20}
                                className="ml-1 mr-2 mt-2 text-right"
                                color="#1B1E23"
                                onClick={() => handleEdit(`${service._id}`)}
                              />
                              <AiOutlineDelete
                                size={20}
                                className="ml-1 mt-2 text-right justify-end"
                                color="#1B1E23"
                                onClick={() => handleDelete(`${service._id}`)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ),
                };
              })
          : [],
      },
      {
        id: "done",
        title: "Done", //la idea es que el lane indique el status
        // style: { backgroundColor: 'rgb(136,244,142)' },
        style: { backgroundColor: "rgb(78,195,89)" },
        cardStyle: {
          backgroundColor: "rgb(205,205,205)",
          borderRadius: 10,
          boxShadow: "0 0 8px 1px rgba(0, 0, 0, 0.2)",
          marginBottom: 15,
          overflow: "hidden",
        },
        cards: servicesHost
          ? servicesHost
              .filter((service) => new Date(service.endDate) < new Date())
              .sort((a, b) => new Date(b.endDate) - new Date(a.endDate))
              .map((service) => {
                let place = {};
                if (placesHost) {
                  place = placesHost.find(
                    (place) => place._id === service.placeId
                  );
                }
                return {
                  id: service.id,
                  title: (
                    <div className="flex items-center justify-start">
                      {" "}
                      {service.doing}
                      <IconComponent
                        size={30}
                        className="ml-1 mr-2 text-right"
                        color="#1B1E23"
                      >
                        {service.reactIcon}
                      </IconComponent>{" "}
                    </div>
                  ),
                  label: (
                    <div>
                      <div className="h-full flex items-center justify-end">
                        <span className="text-xs font-extrabold">
                          {service.status}
                        </span>
                      </div>
                      <div className=" flex items-center justify-end">
                        <span className="text-xs font-semibold">
                          {place.name}
                        </span>
                      </div>
                    </div>
                  ),
                  description: (
                    <div className="">
                      <div className="text-left justify-between">
                        <div className="">
                          <span className="text-base font-bold">
                            {service.team.name}
                          </span>
                          <span className="text-base">{" Service Team"}</span>
                        </div>
                      </div>
                      <div className="flex">
                        <div className="flex">
                          <div className="mr-3 justify-between">
                            {service.team.avatar ? (
                              <img
                                src={`${backend_url}/${service.team.avatar}`}
                                alt="Team Avatar"
                                className="w-[50px] h-[50px] rounded-full object-cover "
                              />
                            ) : (
                              <AiOutlineTeam
                                size={35}
                                className="ml-1 mt-2 text-right justify-end"
                                color="#1B1E23"
                              />
                            )}
                          </div>
                          <div className="">
                            <p className="mb-0">
                              Start:{" "}
                              <span className="font-semibold">
                                {new Date(service.startDate).toISOString()}
                              </span>
                            </p>
                            <p className="mb-0">
                              End:{" "}
                              <span className="font-semibold">
                                {new Date(service.endDate).toISOString()}
                              </span>
                            </p>
                            <p className="mb-0">
                              Address:{" "}
                              <span className="font-semibold">
                                {place.streetAddress}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      {service.comments ? (
                        <div className="block mb-2">
                          <p>
                            Comments:{" "}
                            <span className="text-xs font-semibold">
                              {service.comments}
                            </span>
                          </p>
                        </div>
                      ) : (
                        <div className="block mb-2"></div>
                      )}
                      <div>
                        <hr />
                        <div>
                          <div className="flex items-center justify-between">
                            <div className="flex justify-start">
                              <AiOutlineFieldTime
                                size={20}
                                className="ml-1 mr-2 mt-2 text-left "
                                color="#1B1E23"
                              />
                              {service.startDate &&
                              Date.now() <
                                new Date(service.startDate).getTime() ? (
                                <span className="ml-1 mt-2 text-left">{`${calculateTimeRemaining(
                                  service.startDate
                                )} left`}</span>
                              ) : (
                                <span className="ml-1 mt-2 text-left text-red-500">
                                  0 m left
                                </span>
                              )}
                            </div>
                            <div className="flex justify-end">
                              <AiOutlineWhatsApp
                                size={20}
                                className="ml-1 mr-2 mt-2 text-right"
                                color="#25D366"
                                strokeWidth={20}
                                onClick={() => handleMessage(`${service._id}`)}
                              />
                              <AiTwotoneEdit
                                size={20}
                                className="ml-1 mr-2 mt-2 text-right"
                                color="#1B1E23"
                                onClick={() => handleEdit(`${service._id}`)}
                              />
                              <AiOutlineDelete
                                size={20}
                                className="ml-1 mt-2 text-right justify-end"
                                color="#1B1E23"
                                onClick={() => handleDelete(`${service._id}`)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ),
                };
              })
          : [],
      },
    ],
  };

  return (
    <div>
      <div className="px-2 flex mt-1 justify-center">
        <label className="pb-2 items-right text-right">
          <span className={`text-[15px] font-semibold`}>
            {" "}
            Services Dashboard<span className="text-gray-800"></span>
          </span>{" "}
        </label>
      </div>
      <div className="justify-center flex bg-[#9db9be]">
        <Board
          data={data}
          style={{ backgroundColor: "rgb(210,210,210)" }}
          draggable
        ></Board>
      </div>
      <br />
    </div>
  );
};

export default Dashboard;
