import axios from "axios";
import { server } from "../../server";

// service request message
export const messageCreate = (newForm) => async (dispatch) => {
  try {
    dispatch({
      type: "messageCreateRequest",
    });
    const config = { headers: { "Content-Type": "application/json" } };
    const { data } = await axios.post(
      `${server}/message/service-req`, 
      newForm,
      config
    );
    dispatch({
      type: "messageCreateSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "messageCreateFail",
      payload: error.response.data.message,
    });
  }
};

// get all messages of a service
export const getAllMessagesService = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "getAllMessagesServiceRequest",
    });
    const { data } = await axios.get(
      `${server}/message/get-all-messages-service/${id}`
    );
    dispatch({
      type: "getAllMessagesServiceSuccess",
      payload: data.messagesService,
    });
  } catch (error) {
    dispatch({
      type: "getAllMessagesServiceFailed",
      payload: error.response.data.message,
    });
  }
};

// get all messages of a host
export const getAllMessagesHost = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "getAllMessagesHostRequest",
    });
    const { data } = await axios.get(
      `${server}/message/get-all-messages-host/${id}`
    );
    dispatch({
      type: "getAllMessagesHostSuccess",
      payload: data.messagesHost,
    });
  } catch (error) {
    dispatch({
      type: "getAllMessagesHostFailed",
      payload: error.response.data.message,
    });
  }
};