import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import styles from "../../styles/styles";
import GeneralCard from "../Route/GeneralCard/PlaceCard";
import { backend_url } from "../../server";
import { getAllTeamsHost } from "../../redux/actions/team";
import { getAllPlacesHost } from "../../redux/actions/place";
import { AiOutlineBarChart } from "react-icons/ai";

const HostProfileData = () => {
  const { placesHost } = useSelector((state) => state.places);
  const { teamsHost } = useSelector((state) => state.teams);
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllPlacesHost(id));
    dispatch(getAllTeamsHost(id));
  }, [dispatch]);

  const [active, setActive] = useState(1);

  return (
    <div className="w-full">
      <div className="flex w-full items-center justify-between">
        <div className="w-full flex">
          <div className="flex items-center" onClick={() => setActive(1)}>
            <h5
              className={`font-[600] text-[20px] ${
                active === 1 ? "text-red-500" : "text-[#333]"
              } cursor-pointer pr-[20px]`}
            >
              Places
            </h5>
          </div>
          <div className="flex items-center" onClick={() => setActive(2)}>
            <h5
              className={`font-[600] text-[20px] ${
                active === 2 ? "text-red-500" : "text-[#333]"
              } cursor-pointer pr-[20px]`}
            >
              Teams
            </h5>
          </div>
        </div>
        <div>
          <div>
            <Link to="/dashboard">
              <div className={`${styles.button} !rounded-[4px] h-[42px]`}>
                <span className="text-[#fff]">Go Dashboard</span>
              </div>
            </Link>
          </div>
        </div>
      </div>

      <br />
      {active === 1 && (
        <div className="w-full">
          <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-3 lg:gap-[25px] xl:grid-cols-4 xl:gap-[20px] mb-12 border-0 bg-blue-100">
            {placesHost &&
              placesHost.map((i, index) => (
                <div className="w-full h-[250px] bg-white rounded-lg shadow-sm p-3 relative cursor-pointer">
                  <div className="flex justify-end"></div>
                  <Link to={`/host/place/${placesHost?._id}`}>
                    <img
                      src={
                        i.avatar
                          ? `${backend_url}/${i.avatar}`
                          : `${backend_url}/Checknclean.png`
                      }
                      alt=""
                      className="w-full h-[170px] object-contain rounded-lg shadow-md "
                    />
                  </Link>
                  <div className="flex justify-between py-1">
                    <Link to={`/host/place/${i?._id}`}>
                      <h5 className={`${styles.host_name}`}>{i.name}</h5>
                    </Link>
                    <Link to={`/host/place/metrics/${i?._id}`}>
                      <div className="flex items-center justify-end">
                        <span className="font-[400] text-[17px] text-[#68d284]">
                          <AiOutlineBarChart className="text-xl" size={50} />
                        </span>
                      </div>
                    </Link>
                  </div>
                </div>
              ))}
          </div>
          {placesHost && placesHost.length === 0 && (
            <h5 className="w-full text-center py-5 text-[18px]">
              No Places around here!
            </h5>
          )}
        </div>
      )}

      {active === 2 && (
        <div className="w-full">
          <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-3 lg:gap-[25px] xl:grid-cols-4 xl:gap-[20px] mb-12 border-0 bg-red-100">
            {teamsHost &&
              teamsHost.map((i, index) => (
                <div className="w-full h-[250px] bg-white rounded-lg shadow-sm p-3 relative cursor-pointer">
                  <div className="flex justify-end"></div>
                  <Link to={`/dashboard-teams}`}>
                    <img
                      src={
                        i.avatar
                          ? `${backend_url}/${i.avatar}`
                          : `${backend_url}/Checknclean.png`
                      }
                      alt=""
                      className="w-full h-[170px] object-contain rounded-lg shadow-md "
                    />
                  </Link>
                  <div className="flex justify-between py-1">
                    <Link to={`/dashboard-teams`}>
                      <h5 className={`${styles.host_name}`}>{i.name}</h5>
                    </Link>
                    <Link to={`/host/teams/metrics/${i?._id}`}>
                      <div className="flex items-center justify-end">
                        <span className="font-[400] text-[17px] text-[#68d284]">
                          <AiOutlineBarChart className="text-xl" size={50} />
                        </span>
                      </div>
                    </Link>
                  </div>
                </div>
              ))}
          </div>
          {teamsHost && teamsHost.length === 0 && (
            <h5 className="w-full text-center py-5 text-[18px]">
              No Teams around here!
            </h5>
          )}
        </div>
      )}
    </div>
  );
};

export default HostProfileData;
