import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Chat from "./Chat/ServiceChat/Chat";
import { getAllMessagesService } from "../../redux/actions/message";
import { getService } from "../../redux/actions/service";
import { useNavigate, useParams } from "react-router-dom";
import "../../styles/App.css"

const TeamMessages = ({id}) => {
  const { messagesService } = useSelector((state) => state.messages);
  const { host } = useSelector((state) => state.host);
  const { service, success, error } = useSelector((state) => state.services);
  const dispatch = useDispatch();
  const hostId = host._id;
  let teamMessages = null;
  const [teams] = useState([]);
  let lastMessage = null;
  let [selectedTeam, setSelectedTeam] = useState([]);
  const [team, setTeam] = useState("");
  const processedTeam = new Set();

   const serviceId = id;

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getAllMessagesService(serviceId));
        dispatch(getService(id));
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    if (messagesService) {
        messagesService.forEach((message) => {
        if (message.status === 3 || message.status === 4) {
          if (!lastMessage || message.createdAt > lastMessage.createdAt) {
            lastMessage = message;
          }
        }
      });
    }
    const intervalId = setInterval(() => {
        fetchData();
      }, 60000); // 60k ms
      return () => clearInterval(intervalId);
  }, [dispatch, hostId]);

  return (

      <div className="ml-4">
        {!messagesService ? (
          <div className="Sapp__containerLoader">
              <div className="Sloader"></div>
          </div>
        ) : (
          <>
            <Chat 
            messages={messagesService} 
            />
          </>
        )}
      </div>

  );
};

export default TeamMessages;
