import React from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSelector = () => {
    const { i18n } = useTranslation();

  const changeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <div>
      <select style={{ backgroundColor: 'rgb(246, 246, 245)' }}  value={i18n.language} onChange={changeLanguage}>
        <option value="DE">DE</option>
        <option value="EN">EN</option>
        <option value="ES">ES</option>
        <option value="FR">FR</option>
        <option value="IT">IT</option>
        <option value="PT">PT</option>
      </select>
    </div>
  );
};

export default LanguageSelector;