import React from "react";
import { Link } from "react-router-dom";
import styles from "../../../styles/styles";
import { useTranslation } from "react-i18next";

const Hero = () => {
  const { t } = useTranslation();
  return (
    <div
      className={`relative min-h-[70vh] 800px:min-h-[80vh] w-full bg-no-repeat ${styles.normalFlex}`}
      style={{
        backgroundImage: "url(https://i.imgur.com/PRc2TZ0.jpg)",
      }}
    >
      <div className={`${styles.section} w-[90%] 800px:w-[60%]`}>
        <h1
          className={`w-full text-[25px] text-center 800px:text-[60px] text-white capitalize whitespace-no-wrap`}
        >
          <div></div>
          {t("Welcome-hero-1")}
          <div className="flex items-center justify-center mt-6">
            <h1 className={`{mt-6 font-extrabold text-cyan-500 flex }`}>
              ChecK '
              <img
                src="https://images2.imgbox.com/11/c7/UtCrlGjj_o.png"
                alt="Logo"
                className="h-[80px] mx-2"
              />
              ' CleaN
            </h1>
          </div>
          {t("Welcome-hero-2")}
          <br />
          <br />
        </h1>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
          {t("Welcome-hero-3")} <br />
        </p>
        <Link to="/host-login" className="inline-block">
          <div className={`${styles.button} mt-5`}>
            <span className="text-[#fff] text-center font-[Poppins] p-3 text-[18px]">
            {t("Welcome-hero-4")}
            </span>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Hero;
