import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  place: null,
  places: null,
  placesHost: null,
  isLoading: false,
  success: false,
  error: null,
};

export const placeReducer = createReducer(initialState, {
  placeCreateRequest: (state) => {
    state.isLoading = true;
    state.success = false;
  },
  placeCreateSuccess: (state, action) => {
    state.isLoading = false;
    state.place = action.payload;
    state.success = true;
  },
  placeCreateFail: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.success = false;
  },

  //get a Place
  getPlaceRequest: (state) => {
    state.isLoading = true;
  },
  getPlaceSuccess: (state, action) => {
    state.isLoading = false;
    state.place = action.payload;
  },
  getPlaceFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  // get all places of host
  getAllPlacesHostRequest: (state) => {
    state.isLoading = true;
  },
  getAllPlacesHostSuccess: (state, action) => {
    state.isLoading = false;
    state.placesHost = action.payload;
   },
  getAllPlacesHostFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  // delete place of a host
  deletePlaceRequest: (state) => {
    state.isLoading = true;
  },
  deletePlaceSuccess: (state, action) => {
    state.isLoading = false;
    state.message = action.payload;
  },
  deletePlaceFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  clearErrors: (state) => {
    state.error = null;
  },
});
